<template>
  <div id="orders" class="orders">
    <header class="orders__header">
      <ao-tabs
        :staticTabs="tabs"
        :dynamicTabs="cardTabs"
        :currentTab="reactiveData.currentTabView"
        @onSelect="selectTab"
        @onCloseTab="tabCloseInit"
      />
    </header>
    <main class="orders__main">
      <keep-alive v-if="currentComponent">
        <component :is="currentComponent" />
      </keep-alive>
    </main>

    <close-tab-modal v-model="closeTabModalVisible" @onTabCloseConfirm="tabCloseConfirm" />
  </div>
</template>

<script>
const {
  COMPONENT_NAMES,
  storageKeys,
  registerTab,
} = require('../../constants/orders');
const {
  clearCardStatesCache,
  saveToStorageDynamicTabs,
  getFromStorageDynamicTabs,
} = require('../../utils/useStorage');
const { sortTabsByCounter, isTabExist, removeTab } = require('../../utils/cardTabsHelpers');

// async components
/* eslint-disable import/no-dynamic-require, global-require */
const AoTabs = (resolve) => {
  require(['../../components/ao-tabs.vue'], resolve);
};
const CloseTabModal = (resolve) => {
  require(['../../components/custom-modals/ao-close-tab-modal.vue'], resolve);
};
const OrdersRegister = (resolve) => {
  require(['./orders-register.vue'], resolve);
};
const OrderCard = (resolve) => {
  require(['./order-card.vue'], resolve);
};
/* eslint-enable import/no-dynamic-require, global-require */

const localeComponents = {
  AoTabs,
  CloseTabModal,
  OrdersRegister,
  OrderCard,
};

module.exports = {
  components: { ...localeComponents },

  provide() {
    return {
      reactiveProvidedOrderData: this.reactiveData,

      ordersProvidable: {
        entityStorageKey: storageKeys.ordersEntity,
        openRegister: this.openRegister,
        duplicate: this.duplicateOrderCard,
        openExist: this.openExistOrderCard,
      },
    };
  },

  data() {
    return {
      tabs: [registerTab],
      cardTabs: [],
      reactiveData: {
        currentTabView: null,
      },
      tabForClose: null,
      closeTabModalVisible: false,
    };
  },

  computed: {
    currentTabComponentName() {
      return this.reactiveData.currentTabView?.componentName || null;
    },
    currentTabId() {
      return this.reactiveData.currentTabView?.id ?? null;
    },
    currentComponent() {
      return localeComponents[this.currentTabComponentName] || null;
    },
    orderTabIds() {
      return this.cardTabs.filter((tab) => tab.componentName === COMPONENT_NAMES.order)
        .map(({ id }) => id);
    },
  },

  watch: {
    cardTabs(newTabs) {
      saveToStorageDynamicTabs({ key: storageKeys.orderTabs, tabs: newTabs });

      clearCardStatesCache({
        entity: storageKeys.ordersEntity,
        tabIds: this.orderTabIds,
      });
    },
  },

  mounted() {
    this.cardTabs = getFromStorageDynamicTabs(storageKeys.orderTabs);
  },

  methods: {
    // tabs select, add, remove
    selectTab(selectedTab) {
      this.reactiveData.currentTabView = selectedTab;
    },
    selectOrAddTab(tab) {
      if (isTabExist(tab, this.cardTabs)) {
        this.selectTab(tab);
      } else {
        this.cardTabs.push(tab);
        this.selectTab(tab);
      }

      this.cardTabs = sortTabsByCounter(this.cardTabs);
    },
    cardTabRemove(tab) {
      this.cardTabs = removeTab(tab, this.cardTabs);
      this.resetCurrentTab(tab);
    },
    resetCurrentTab({ id, componentName }) {
      if (this.currentTabId === id && this.currentTabComponentName === componentName) {
        switch (this.currentTabComponentName) {
          case COMPONENT_NAMES.order:
            this.openRegister();
            break;

          default:
            this.openRegister();
            break;
        }
      }
    },
    tabCloseInit(tab) {
      this.closeTabModalVisible = true;
      this.tabForClose = tab;
    },
    tabCloseConfirm() {
      this.cardTabRemove(this.tabForClose);
      this.tabForClose = null;
      this.closeTabModalVisible = false;
    },

    // open static component tab
    openRegister() {
      this.selectTab(registerTab);
    },

    // open dynamic component tab
    openOrderCard({ id, title, isNew }) {
      const newTab = {
        id,
        isNew,
        title: title || `Документ заказа - ${id}`,
        componentName: COMPONENT_NAMES.order,
      };

      this.selectOrAddTab(newTab);
    },
    openExistOrderCard(id) {
      this.openOrderCard({ id });
    },
  },
};
</script>

<style  scoped>
.orders {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: 100%;
  overflow: auto;
  padding: 12px;
}

.orders__main {
  flex: 1 0 auto;
}

.category-remove-modal>>>.custom-modal {
  max-width: 420px;
}
</style>
