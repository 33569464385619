var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "plan-table" },
    [
      _c(
        "div",
        { staticClass: "plan-table__buttons-row" },
        [
          _c(
            "ao-button",
            {
              staticClass: "plan-table__setting-button",
              attrs: {
                type: "secondary",
                size: "small",
                minified: "",
                rounded: "",
                disabled: _vm.idDownloadButtonDisabled
              },
              on: { click: _vm.createReport }
            },
            [_c("download-icon")],
            1
          ),
          _vm._v(" "),
          _c(
            "ao-button",
            {
              ref: "visibilitySetButton",
              staticClass: "plan-table__setting-button",
              attrs: {
                type: "secondary",
                size: "small",
                minified: "",
                rounded: "",
                disabled: _vm.isLoading
              },
              on: { click: _vm.toggleColumnVisibilitySetting }
            },
            [_c("settings-icon")],
            1
          ),
          _vm._v(" "),
          _vm.showColumnVisibilitySettings
            ? _c("ao-column-visible-popup", {
                attrs: {
                  xPosition: "left",
                  scrollableAreaSelector: _vm.scrollableContainer,
                  parent: _vm.$refs.visibilitySetButton.$el,
                  tableColumns: _vm.tableColumnsForSettings,
                  checkedColumnIds: _vm.visibleColumnIds
                },
                on: { onChangeColumnVisible: _vm.columnCheckedChange },
                model: {
                  value: _vm.showColumnVisibilitySettings,
                  callback: function($$v) {
                    _vm.showColumnVisibilitySettings = $$v
                  },
                  expression: "showColumnVisibilitySettings"
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "ao-button",
            {
              ref: "configButton",
              staticClass: "plan-table__setting-button",
              class: { active: !_vm.isConfigEmpty },
              attrs: {
                type: "secondary",
                size: "small",
                minified: "",
                rounded: "",
                disabled: _vm.isLoading
              },
              on: {
                click: function($event) {
                  _vm.showConfigReset = !_vm.showConfigReset
                }
              }
            },
            [_c("sliders-icon")],
            1
          ),
          _vm._v(" "),
          _vm.showConfigReset
            ? _c("ao-config-control-popup", {
                attrs: {
                  scrollableAreaSelector: _vm.scrollableContainer,
                  endpoint: _vm.configEndpoint,
                  isConfigEmpty: _vm.isConfigEmpty,
                  tableConfigParams: _vm.configParams,
                  savedConfigId: _vm.savedConfigId,
                  parent: _vm.$refs.configButton.$el
                },
                on: {
                  onReset: _vm.resetTableColumnConfig,
                  onSelectConfig: _vm.savedConfigSelect
                },
                model: {
                  value: _vm.showConfigReset,
                  callback: function($$v) {
                    _vm.showConfigReset = $$v
                  },
                  expression: "showConfigReset"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("ao-table", {
        attrs: {
          scrollableContainerSelector: _vm.scrollableContainer,
          endpoint: _vm.endpoint,
          tableColumns: _vm.visibleColumns,
          currentTableConfig: _vm.configParams,
          parentName: _vm.parentName,
          columnsSizeStorageKey: _vm.columnsSizeStorageKey,
          modelName: _vm.modelName,
          needToResetConfig: _vm.needToResetConfig,
          additionalPayload: _vm.additionalPayload
        },
        on: { onConfigChange: _vm.configChangedHandler }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }