<template>
  <div class="pim-filter">
    <feather-icon name="filter"/>
    <span v-for="conditions, i in filter" class="pim-filter-conditions">
      <template v-for="values, id in conditions">
        <template v-if="activeControl == i+id">
          <my-popup
            :key="activeControl"
            placement="bottom-start"
            portal="popup"
            @clickoutside="
              cleanupConditions()
              activeControl = null"
          >
            <template v-slot:anchor>
              <span style="display: inline-block;" class="ui-front"/>
            </template>
            <div class="pim-filter-popup">
              <gp-select
                ref="field"
                v-model="field"
                :options="visibleFields"
                :autofocus="!field"
                placeholder="Field"
                recentOptionsKey="recentFields"
              />
              <select v-model="operator" class="form-control form-control-sm">
                <option v-for="operator, value in operators" :value="value">
                  {{operator}}
                </option>
              </select>
              <div class="pim-filter-values">
                <feather-icon v-if="loading" name="clock"/>
                <input
                  v-if="field && ['decimal', 'numeric'].includes(field.field_type)"
                  class="form-control form-control-sm"
                  type="number"
                />
                <input
                  v-else-if="field && field.field_type == 'date'"
                  class="form-control form-control-sm"
                  type="date"
                />
                <input
                  v-else-if="field && field.field_type == 'datetime'"
                  class="form-control form-control-sm"
                  type="datetime-local"
                />
                <textarea
                  ref="values"
                  v-else
                  class="form-control form-control-sm"
                  @focus="autocomplete.autocomplete('search')"
                >{{values.join("\n")}}
                                </textarea>
                <template v-if="operator == 'between'">
                  <input
                    v-if="field && field.field_type == 'date'"
                    class="form-control form-control-sm"
                    type="date"
                  />
                  <input
                    v-else-if="field && field.field_type == 'datetime'"
                    class="form-control form-control-sm"
                    type="datetime-local"
                  />
                </template>
              </div>
              <div ref="autocomplete"/>
              <div class="pim-filter-popup-notes" v-if="field && ['alphanumeric', 'textarea', 'dropdown', 'multidropdown'].includes(field.field_type)">
                <l10n value="Use multiple lines to filter by multiple values"/>
              </div>
              <div class="pim-filter-popup-actions">
                <button
                  class="btn btn-primary btn-xs"
                  :disabled="field == null"
                  @click="
                    changeCondition(i, id)
                    cleanupConditions()
                    activeControl = null
                    $event.stopPropagation()
                  ">
                  <l10n value="OK"/>
                </button>
                <button
                  class="btn btn-secondary btn-xs"
                  @click="
                    cleanupConditions()
                    activeControl = null
                    $event.stopPropagation()">
                  <l10n value="Cancel"/>
                </button>
                <button
                  class="btn btn-danger btn-xs"
                  @click="
                    deleteCondition(i, id)
                    cleanupConditions()
                    activeControl = null">
                  <l10n value="Delete"/>
                </button>
              </div>
            </div>
          </my-popup>
        </template><a
          href="javascript:void(0)"
          @click="
            field = getFieldByCalc(id)
            activeControl = i+id
          "
        >{{getFieldByCalc(id).name}}:
          {{values.length < 10 ?
            values.join(", ") :
            values.slice(0,8).join(", ") + " " +
            l10n("and {more} more...")
              .replace("{more}",
                       new Number(values.length-8)
                         .toLocaleString())}}</a>
        <l10n class="operator" value="AND"/>
        <span> </span>
      </template>
      <a
        class="new"
        href="javascript:void(0)"
        @click="
          $event.stopPropagation()
          $set(conditions,'',[])
          field = null
          activeControl = i
        ">
        <feather-icon name="plus"/>
      </a>
      <l10n class="operator" value="OR" v-if="filter.length > 0"/>
      <span> </span>
    </span>
    <span class="pim-filter-conditions">
      <a
        class="new"
        href="javascript:void(0)"
        @click="
          $event.stopPropagation()
          activeControl = filter.length
          filter.push({'':[]})
        ">
        <feather-icon name="plus"/>
      </a>
    </span>
  </div>
</template>
<script>
let utils = require('../my-utils');
let FuzzySearch = require('fuzzy-search').default;
module.exports = {
  mixins: [
    require('./pim-helper.js'),
  ],
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    entryType: { type: String, default: 'item' },
    value: { type: Array },
  },
  data() {
    return {
      l10n: utils.l10n,
      filter: this.value || [],
      requestId: null,
      loading: false,
      operator: 'eq',
      field: null,
      activeControl: null,
      possibleValues: {},
      autocomplete: null,
      threshold: 100,
    };
  },
  beforeDestroy() {
    if (this.autocomplete) {
      this.autocomplete.autocomplete('destroy');
    }
  },
  computed: {
    operators() {
      let operators = {};
      switch (this.field?.field_type) {
        case 'alphanumeric':
        case 'textarea':
        case 'dropdown':
        case 'multidropdown':
          operators['in'] = 'one of';
          operators['out'] = 'not one of';
          operators['includes'] = 'includes';
          operators['not_includes'] = 'does not include';
          operators['starts_with'] = 'starts with';
          operators['ends_with'] = 'ends with';
          break;
        case 'decimal':
        case 'numeric':
        case 'date':
        case 'datetime':
          operators['eq'] = 'equal to';
          operators['ne'] = 'not equal to';
          operators['lt'] = 'less than';
          operators['gt'] = 'greater than';
          operators['le'] = 'less or equal to';
          operators['ge'] = 'greater or equal to';
          operators['between'] = 'between';
          break;
      }
      return operators;
    },
    visibleFields() {
      let groups = this.fieldGroups(this.entryType);
      return _(this.fields)
        .filter(field => groups.includes(field.group_name))
        .sortBy(field => field.name)
        .value();
    },
  },
  methods: {
    getPossibleValues(field) {
      if (!field) {
        return Promise.resolve([]);
      }

      let possibleValues = this.possibleValues;
      let values = possibleValues[field.id];

      let stream = undefined;
      let source = undefined;
      let filter0 = undefined;
      let filter1 = `sku_master_field_id == '${field.id}'`;
      let filter2 = undefined;
      let filter3 = undefined;

      let fieldType = field.level || 'item';

      switch (field.field_type) {
        case 'numeric':
        case 'decimal':
          return Promise.resolve([]);

        case 'dropdown':
        case 'multidropdown':
          switch (fieldType) {
            case 'item':
              stream = 'pim_flex_field_multi_dropdown_values';
              break;
            case 'variation':
              stream = 'variation_flex_field_dropdown_values';
              break;
            case 'sku':
              stream = 'sku_flex_field_dropdown_values';
              break;
          }
          dims = ['lookup_option.label_name'];
          sort = [1];
          filter2 = '!is_deleted';
          filter3 = 'dim1 != \'\'';
          break;

        default:
          switch (fieldType) {
            case 'category':
              stream = 'metadata_flex_field_datas';
              break;
            case 'item':
              stream = 'item_attribute_data';
              break;
            case 'variation':
              stream = 'variation_flex_field_datas';
              break;
            case 'sku':
              stream = 'sku_flex_field_datas';
              break;
          }
          if (field.field_type == 'datetime') {
            dims = [`${field.field_type}_data.date`];
          } else {
            dims = [`${field.field_type}_data`];
          }
          sort = [1];
          filter2 = '!is_deleted';
      }

      if (!values) {
        let requestId = utils.randomId();
        this.loading = true;
        this.requestId = requestId;
        values = utils.query({
          id: requestId,
          cores: this.cores,
          name: 'pim-filter',
          stream,
          source,
          filter0,
          filter1,
          filter2,
          filter3,
          filter3,
          dims,
          sort,
        }).then((rows) => {
          if (requestId == this.requestId) {
            this.loading = false;
            this.requestId = null;
          }
          let fuzzySearch = new FuzzySearch(
            rows,
            ['0'],
            { caseSensitive: false, sort: true });
          window.fuzzySearch = fuzzySearch;
          return fuzzySearch;
        })
          .catch(() => {
            if (requestId == this.requestId) {
              this.loading = false;
              this.requestId = null;
            }
            return new FuzzySearch([]);
          });
        this.$set(possibleValues, field.id, values);
      }
      return values;
    },
    getFieldByCalc(id) {
      return this.fields.find((field) => field.id == id) || { id, name: id };
    },
    getFieldByName(name) {
      return this.fields.find((field) => field.name == name) || { name, id: name };
    },
    changeCondition(i, id) {
      this.filter.splice(
        i,
        1,
        _(this.filter[i])
          .toPairs()
          .map((condition) => {
            if (condition[0] == id) {
              return [
                this.$refs.field[0].value.id,
                _(this.$refs.values[0].value)
                  .split('\n')
                  .map(_.trim)
                  .filter()
                  .uniq()
                  .value()];
            } else {
              return condition;
            }
          })
          .fromPairs()
          .value());
    },
    deleteCondition(i, id) {
      this.$delete(this.filter[i], id);
    },
    cleanupConditions() {
      for (let i=0; i<this.filter.length; ++i) {
        let conditions = this.filter[i];
        this.$delete(conditions, '');
        if (_.isEmpty(conditions)) {
          this.filter.splice(i,1);
          break;
        }
      }
    },
    boradcastFilter() {
      let filter2 =
                _(this.filter)
                  .map((condition) =>
                    _(condition)
                      .toPairs()
                      .filter(([key, value]) => key && value)
                      .map(([key, value]) =>
                        value.length == 1
                          ? `(${this.formulas[key] || key}) == ${utils.quote(value[0])}`
                          : `(${this.formulas[key] || key}) in ${utils.quote(value)}`)
                      .join(' && '))
                  .filter()
                  .join(' || ');

      let filters = {
        stream: this.stream,
        groups: this.groups,
        filter0: '',
        filter1: '',
        filter2,
        filter3: '',
      };

      utils.bridge.trigger('filtersChanged', this._uid, filters);
    },
  },
  mounted() {
    if (this.autofilter) {
      this.boradcastFilter();
    }
  },
  watch: {
    operators() {
      if (!this.operators[this.operator]) {
        this.operator = _.keys(this.operators[0]);
      }
    },
    filter0() {
      this.possibleValues = {};
    },
    filter1() {
      this.possibleValues = {};
    },
    filter2() {
      this.possibleValues = {};
    },
    value() {
      this.filter = this.value || [];
    },
    field() {
      this.threshold = 100;
      if (this.field) {
        $(this.$refs.values).focus();
        if (this.autocomplete) {
          Vue.nextTick(() => {
            this.autocomplete.autocomplete('search', '');
          });
        }
      }
    },
    filter() {
      this.$emit('input', this.filter);
      this.$emit('change', this.filter);

      if (this.autofilter) {
        this.boradcastFilter();
      }
    },
    activeControl() {
      Vue.nextTick(() => {
        let self = this;
        // $("*[autofocus]").focus()
        // $("*[autofocus]").select()
        if (this.autocomplete) {
          this.autocomplete.autocomplete('destroy');
        }
        this.autocomplete = $(this.$refs.values).autocomplete({
          minLength: 0,
          appendTo: this.$refs.autocomplete,
          source: function(request, response) {
            let searchTerm = _.last(request.term.split('\n'));
            // let field = self.$refs.field[0].value
            let field = self.field;

            if (field) {
              self.getPossibleValues(field)
                .then((fuzzySearch) => {
                  let values = fuzzySearch.search(searchTerm);
                  if (values.length > self.threshold) {
                    let more = utils.l10n('and {more} more...').replace('{more}', new Number(values.length-self.threshold).toLocaleString());
                    self.autocompleteMore = more;
                    values = values.slice(0, self.threshold);
                    values.push([more]);
                  }
                  response(values.map((row) => row[0]));
                })
                .catch(() => {
                  response([]);
                });
            } else {
              response([]);
            }
          },
          focus: () => false,
          select: (event, ui) => {
            let terms = $(this.$refs.values).val().split('\n');
            terms.pop();
            terms.push(ui.item.value);
            let value = terms.join('\n');
            if (value == this.autocompleteMore) {
              this.threshold *= 10;
              _.defer(() => {
                this.autocomplete.autocomplete('search', '');
                let e = jQuery.Event('keydown');
                e.which = 40;
                $(this.$refs.values).trigger('focus').trigger(e);
              });
            } else {
              $(this.$refs.values).val(value);
            }
            return false;
          },
        });
      });
    },
  },
};
</script>
<style>
.pim-filters-list {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 20px;
}
.pim-filters-list > li {
    border-top: 1px solid var(--dark);
    padding: 0;
    margin: 0;
}
.my-dark-theme .pim-filters-list > li {
    border-top: 1px solid var(--light);
}
.pim-filters-list > li label {
    background-color: var(--light);
    padding-top: 0;
    padding: 1px 4px;
    display: block;
}
.my-dark-theme .pim-filters-list > li label {
    background-color: var(--dark);
}
.pim-filters-list > li p {
    line-height: 1.3;
    font-size: 0.9em;
}
.pim-filter-conditions {
    display: inline;
}
.pim-filter-popup {
    padding: 8px 8px;
    border-radius: 4px;
    display: inline-block;
    background-color: rgb(191,223,294);
}
*[data-popper-placement] {
    z-index: 100!important;
}
.pim-filter-values + div {
    margin-bottom: 10px;
    position: relative;
}
.pim-filter-popup .btn.btn-xs {
    padding: 0 8px;
    font-size: 0.9em;
}
.pim-filters .new svg {
    /*padding: 1px;*/
    color: #1489FF80;
    border: none;
    /*border-radius: 4px;*/
    margin-top: -2px;
    margin-bottom: -6px;
    vertical-align: top;
}
.pim-filters .operator {
    opacity: 0.8;
    font-style: italic;
}
.pim-filter-popup-actions {
    display: flex;
    margin-right: -6px;
}
.pim-filter-popup-actions > * {
    flex-grow: 1;
    flex-basis: 1px;
    margin-right: 6px;
}
.ui-autocomplete {
    list-style: none;
    padding: 0;
    margin: 0;
    position: absolute!important;
    left: inherit!importanti;
    top: inherit!important;
    max-height: 200px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    /*margin-left: 8px;*/
    /*margin-top: -8px;*/
    /*width: 100px!important;*/
}
.ui-autocomplete .ui-menu-item-wrapper {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    padding: 0 8px;
    font-size: 1em;
}
.ui-autocomplete .ui-menu-item-wrapper.ui-state-active {
    /*width: 100px!important;*/
    border: none!important;
    outline: none!important;
    background-color: #3498db30!important;
    color: inherit!important;
}
.pim-filter-popup {
    box-shadow: 0 0 10px 10px #00000010;
    border: 1px solid var(--cyan);
}
.my-dark-theme .pim-filter-popup {
    background-color: rgb(35,53,74);
    border: 1px solid black;
}
.pim-filter .feather-icon-trash {
    visibility: hidden
}
.pim-filter .feather-icon-trash svg {
    width: 18px;
    height: 18px;
}
ul.pim-filters-list > li:hover .feather-icon-trash {
    visibility: visible;
}
.pim-filters-actions {
    display: flex;
    margin-right: -10px;
}
.pim-filters-actions > * {
    margin-right: 10px;
    flex-grow: 1;
}
.pim-filter-delete {
    float: right;
    margin-right: 4px;
}
.pim-filters .feather-icon-filter svg {
    width: 16px;
    height: 16px;
    opacity: 0.8;
}
.pim-filters-list > li label {
    padding: 4px 8px;
}
.pim-filter-delete {
    margin-top: 2px;
}
.pim-filters-list li label {
    cursor: move
}
.pim-filters-list li {
    padding-bottom: 4px;
}
.pim-filters-list li p {
    padding: 0 8px;
    padding-bottom: 10px;
    margin: 0;
}
.pim-filters ul {
    margin-left: -20px;
    margin-right: -20px;
}
.pim-filters ul li label {
    padding: 4px 20px;
}
.pim-filters ul li p {
    padding: 20px;
    padding-top: 0px;
    padding-bottom: 10px;
}
.pim-filter-values {
    position: relative;
}
.pim-filter .feather-icon svg,
.pim-filter-popup .feather-icon svg {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
    margin-bottom: -2px;
}
.pim-filter .feather-icon-filter svg {
    margin-right: 4px;
}
.pim-filter-popup .feather-icon-clock {
    position: absolute;
    top: 2px;
    right: 4px;
    color: var(--dark);
}
.pim-filter-popup .feather-icon-clock svg {
    width: 16px;
    height: 16px;
}
/*.pim-filter-conditions .my-popper > div {
    z-index: 5!important;
}
*/
.pim-filter-conditions .my-popup .ui-autocomplete li {
    font-size: 13px;
}
.pim-filter-values {
    margin-bottom: 0!important;
}
.pim-filter-popup .btn.btn-xs {
    padding: 2px 8px;
}
.pim-filter .operator {
    font-style: italic;
}
.pim-filter .operator {
    font-style: italic;
}
.pim-filter-values textarea.form-control {
    height: 100px;
}
.pim-filter-popup-notes {
    font-size: 0.9em;
    white-space: initial;
    opacity: 0.6;
    line-height: 1.3em;
    padding: 0 6px;
}
.pim-filter {
    font-size: 14px;
}
.pim-filter textarea,
.ui-autocomplete {
    font-size: 13px;
}
.ui-widget {
    font-family: inherit;
}
.pim-filter-popup {
    display: flex;
    flex-direction: column;
    padding: 10px;
}
.pim-filter-popup > * {
    margin-bottom: 10px;
}
.pim-filter-popup > *:last-child {
    margin-bottom: 0;
}
.pim-filter-values {
    display: flex;
    flex-direction: row;
    margin-right: -10px;
}
.pim-filter-values > * {
    margin-right: 10px;
    flex-basis: 100%;
    flex-shrink: 1;
}
</style>
