const { DOMAIN } = require('./endpoint');
const {
  getIdNameField,
  getLeafType,
  getLeafName,
  getLeafCode,
} = require('../utils/tableColumnNameGetters');

const ENTITY = 'contractors';

// contractors
const COMPONENT_NAMES = {
  catalog: 'ContractorsCatalog',
  contractor: 'ContractorCard',
  category: 'ContractorCategoryCard',
};
const contractorTabsStorageKey = 'contractor-tabs';
const contractorEntityStorageKey = 'contractor-cards';
const contractorCategoriesEntityStorageKey = 'contractor-categories';
const newContractor = 'new-contractor-card';
const newCategory = 'new-contractor-category';
const catalogTab = { id: 'contractor-catalog', title: 'Справочник контрагентов', componentName: COMPONENT_NAMES.catalog };

// storage keys
const storageKeys = {
  parentName: 'contractors',
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID контрагента', getter: getIdNameField },
  { id: 'type', title: 'Тип контрагента', getter: getLeafType },
  { id: 'code', title: 'Код контрагента', getter: getLeafCode },
  { id: 'name', title: 'Наименование контрагента', getter: getLeafName },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/contractors/categories/hierarchy/`,
  leafsField: 'contractors',
};

// endpoints
const ENDPOINTS = {
  CONTRACTORS: `${DOMAIN}/contractors/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/contractors/categories/hierarchy/`,
  CATEGORIES: `${DOMAIN}/contractors/categories/`,
  FEATURES: `${DOMAIN}/contractors/features/`,
  FEATURE_NAMES: `${DOMAIN}/contractors/feature_names/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  ENTITY,
  contractorTabsStorageKey,
  contractorEntityStorageKey,
  contractorCategoriesEntityStorageKey,
  newContractor,
  newCategory,
  catalogTab,
  storageKeys,
  hierarchyTableProps,
};
