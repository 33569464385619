<template>
  <div class="pim-fields">
    <h2>Fields Setup</h2>
    <table>
      <thead>
        <tr>
          <th></th>
          <th>Field Name</th>
          <th><span>Show</span></th>
          <th><span>Derived</span></th>
          <th><span>Overridable</span></th>
          <th><span>Searchable</span></th>
          <th><span>Lockes</span></th>
          <th><span>Storefront</span></th>
          <th><span>Locale</span></th>
        </tr>
      </thead>
      <template v-for="fields, group in groups">
        <tbody>
          <tr>
            <td @click="toggleGroup(group)">
              <feather-icon :name="activeGroup == group ? 'book-open' : 'book'"/>
            </td>
            <th @click="toggleGroup(group)" colspan="8">
              {{group}}
            </th>
          </tr>
          <template v-if="group == activeGroup">
            <template v-for="field in fields">
              <tr :key="field.id">
                <td @click="toggleField(field)">
                  <feather-icon :name="activeFields[field.id] ? 'chevron-down' : 'chevron-right'"/>
                </td>
                <td @click="toggleField(field)">
                  {{field.description || field.field_name}}
                  <span v-if="isEdited(field)" class="edited">
                    [edited]
                    <a href="javascript:void(0)"
                       @click="
                         $delete(override, field.id)
                         $event.stopPropagation()">
                      <feather-icon name="x"/>
                    </a>
                  </span>
                </td>
                <td>
                  <gp-check
                    :checked="get(field, 'is_readonly')"
                    :disabled="
                      get(field, 'is_derived') ||
                        get(field, 'is_searchable') ||
                        get(field, 'is_locked') ||
                        get(field, 'has_storefronts') ||
                        get(field, 'has_locales')
                    "
                    @change="set(field, 'is_readonly', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :checked="get(field, 'is_derived')"
                    @change="set(field, 'is_derived', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :disabled="!get(field, 'is_derived')"
                    :checked="get(field, 'overridable')"
                    @change="set(field, 'overridable', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :checked="get(field, 'is_searchable')"
                    @change="set(field, 'is_searchable', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :checked="get(field, 'is_locked')"
                    @change="set(field, 'is_locked', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :disabled="get(field, 'has_storefronts')"
                    :checked="get(field, 'has_locales')"
                    @change="set(field, 'has_locales', $event)"
                  />
                </td>
                <td>
                  <gp-check
                    :disabled="get(field, 'has_locales')"
                    :checked="get(field, 'has_storefronts')"
                    @change="set(field, 'has_storefronts', $event)"
                  />
                </td>
              </tr>
              <tr v-if="activeFields[field.id]" :key="`${field.id}-active`">
                <td></td>
                <td colspan="8">
                  <pim-field
                    :fields="fields"
                    :locales="locales"
                    :storefronts="storefronts"
                    :template="templatesIndex[field.id]"
                    :field="thaw(field)"
                    @change="$set(override, field.id, $event)"
                    :darkTheme="darkTheme"
                  />
                </td>
              </tr>
            </template>
          </template>
        </tbody>
      </template>
    </table>
  </div>
</template>
<script>
module.exports = {
  props: {
    fields: { type: Array, default: () => [] },
    locales: { type: Array, default: () => [] },
    storefronts: { type: Array, default: () => [] },
    templates: { type: Array, default: () => [] },
    darkTheme: { type: Boolean },
  },
  data() {
    return {
      override: {},
      activeGroup: null,
      activeFields: {},
    };
  },
  computed: {
    groups() {
      return _(this.fields)
        .groupBy('group_name')
        .toPairs()
        .sortBy(0)
        .fromPairs()
        .value();
    },
    templatesIndex() {
      return _(this.templates)
        .map(template => [template.sku_master_field_id, template])
        .fromPairs()
        .value();
    },
  },
  methods: {
    get(field, prop) {
      return (this.override[field.id] || field)[prop];
    },
    set(field, prop, value) {
      this.$set(this.thaw(field), prop, value);
    },
    thaw(field) {
      let editable = this.override[field.id];
      if (!editable) {
        editable = _.clone(field);
        this.$set(this.override, field.id, editable);
      }
      return editable;
    },
    isEdited(field) {
      let editable = this.override[field.id];
      return editable && !_.isEqual(editable, field);
    },
    toggleGroup(group) {
      this.activeGroup = this.activeGroup == group ? null : group;
    },
    toggleField(field) {
      if (this.activeFields[field.id]) {
        this.$delete(this.activeFields, field.id);
      } else {
        this.$set(this.activeFields, field.id, true);
      }
    },
  },
};
</script>
