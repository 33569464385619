<template>
  <div class="ao-table__container">
    <table ref="table" class="ao-table">
      <thead class="ao-table__head">
        <tr ref="headRow" class="ao-table__row head-row">
          <th
            v-for="column in columns"
            :id="column.id"
            :key="column.id"
            class="ao-table__head-cell"
          >
            <div class="ao-table__cell-container ao-table__cell-container_head">
              <button
                type="button"
                :ref="getColButtonRefLink(column.id)"
                class="head-cell__button"
                @click="column.config && openColumnPopup(column)"
              >
                {{ column.title }}
              </button>
              <i class="head-cell__configured-column-mark" v-if="isColumnConfigured(column.id)" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody ref="tBody" class="ao-table__body">
        <tr
          v-for="(row, rowIndex) in rows"
          :key="`${row.nodeType}_${rowIndex}_${row.id}`"
          class="ao-table__row body-row"
          @click="handleRowClick(row)"
        >
          <td
            v-for="column in columns"
            :key="column.title"
            class="ao-table__cell"
          >
            <div class="ao-table__cell-container">
              <span class="ao-table__cell-text">
                {{ getValueOfCell(column.field, row) }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <ao-pagination
      v-model="currentPage"
      :totalItems="totalItems"
      :itemsOnPage="itemsPerPage"
      :isLoading="isLoading"
      @onChangeItemsPerPage="setItemsPerPage"
    />

    <ao-table-config-popup
      v-if="columnPopupIsVisible"
      v-model="columnPopupIsVisible"
      :parent="columnPopupParent"
      :config="columnConfig"
      :columnId="configuredColumnId"
      :configPreset="getColumnParams(configuredColumnId)"
      :modelName="modelName"
      scrollableAreaSelector=".ao-table"
      @onApply="applyColumnConfig"
      @onReset="resetColumnConfig"
    />
  </div>
</template>

<script>
const { $ } = require('../../global');
const { getSortingFilteringRequestBody } = require('../utils/formatters');
const { getColumnsSizesFromStorage, setColumnsSizesToStorage } = require('../utils/useStorage');
const AoTableConfigPopup = require('./custom-popups/ao-table-config-popup.vue').default;
const AoPagination = require('./ao-pagination.vue').default;

module.exports = {
  components: {
    AoTableConfigPopup,
    AoPagination,
  },

  props: {
    tableColumns: {
      type: Array,
      required: false,
      default: () => [],
    },
    endpoint: {
      type: String,
      required: false,
      default: null,
    },
    isSortableColumns: {
      type: Boolean,
      required: false,
      default: true,
    },
    isResizableColumns: {
      type: Boolean,
      required: false,
      default: true,
    },
    parentName: {
      type: String,
      required: false,
      default: null,
    },
    scrollableContainerSelector: {
      type: String,
      required: false,
      default: null,
    },
    needToResetConfig: {
      type: Boolean,
      required: false,
      default: false,
    },
    showPagination: {
      type: Boolean,
      required: false,
      default: true,
    },
    currentTableConfig: {
      type: Object,
      required: false,
      default: () => {},
    },
    additionalPayload: {
      type: Object,
      required: false,
      default: () => ({
        filtering: {},
        sorting: {},
      }),
    },
    modelName: {
      type: String,
      required: false,
      default: null,
    },
    columnsSizeStorageKey: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      columns: [],
      sortedColumnIdsPosition: [],
      rows: [],
      isLoading: false,
      columnPopupParent: null,
      columnPopupIsVisible: false,
      columnConfig: null,
      configuredColumnId: null,
      tableParams: {},
      currentPage: 1,
      itemsPerPage: 25,
      totalItems: 0,
    };
  },

  computed: {
    columnIdsStorageKey() {
      return this.parentName ? `${this.parentName}-column-ids-sorted` : null;
    },
    requestPayload() {
      return {
        ...(getSortingFilteringRequestBody(this.tableParams, this.additionalPayload)),
        limit: this.itemsPerPage,
        offset: (this.currentPage - 1) * this.itemsPerPage,
      };
    },
  },

  watch: {
    tableColumns(newColumns) {
      this.updateVisibleColumnsBySortedPosition(newColumns);

      if (this.isResizableColumns) {
        this.$nextTick(() => {
          this.setColumnsWidthBySettings();
        });
      }
    },
    sortedColumnIdsPosition(newIds) {
      if (this.columnIdsStorageKey) {
        localStorage.setItem(this.columnIdsStorageKey, JSON.stringify(newIds));
      }
    },
    columnPopupIsVisible(newValue) {
      if (!newValue) {
        this.columnConfig = null;
      }
    },
    needToResetConfig(newValue) {
      if (newValue) {
        this.resetTableConfig();
      }
    },
    currentTableConfig(newValue) {
      this.tableParams = newValue;
    },
    tableParams() {
      this.currentPage = 1;
    },
    async requestPayload(newValue) {
      this.rows = await this.getConfiguredData(newValue);
    },
    rows() {
      if (this.isResizableColumns) {
        this.$nextTick(() => {
          this.setColumnsWidthBySettings();
        });
      }
    },
  },

  async created() {
    this.rows = await this.getConfiguredData(this.requestPayload);
  },

  mounted() {
    if (this.isSortableColumns) {
      this.makeColumnsSortable();
    }

    if (this.isResizableColumns) {
      this.$nextTick(() => {
        this.makeColumnsResizable();
      });
    }

    this.updateVisibleColumnsBySortedPosition(this.tableColumns);
  },

  beforeDestroy() {
    if (this.scrollableContainerSelector) {
      $(this.scrollableContainerSelector).off('scroll', this.stopSort);
    }
  },

  methods: {
    // eslint-disable-next-line consistent-return
    async getConfiguredData(payload) {
      if (!this.endpoint) {
        return;
      }

      try {
        this.isLoading = true;

        const options = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
          body: JSON.stringify(payload),
        };

        const response = await fetch(this.endpoint, options);

        if (response.ok) {
          const data = await response.json();
          const { count, results } = data;
          this.totalItems = count;

          if (this.$refs.table) {
            this.$refs.table.scrollTop = 0;
          }

          return results;
        }

        const errors = await response.json();
        Object.values(errors).forEach((msg) => this.createNotification(msg[0], 'error'));
      } catch (error) {
        this.createNotification(error.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
    getValueOfCell(handler, row) {
      const value = typeof handler === 'function' ? handler(row) : row[handler];

      if (value) {
        return value;
      }

      return row.nodeType === 'node' ? '' : '-';
    },
    handleRowClick(row) {
      if (!row.id) {
        return;
      }

      this.$emit('onOpenItem', row.id);
    },

    // drag and drop sorting column
    makeColumnsSortable() {
      $(this.$refs.headRow)
        .sortable({
          items: 'th',
          axis: 'x',
          cursor: 'grabbing',
          helper: 'clone',
          start: (e, ui) => {
            const helperEl = ui.helper[0];
            const placeholderEl = ui.placeholder[0];
            placeholderEl.style.width = `${helperEl.offsetWidth}px`;
            $(helperEl).addClass('head-cell__draggable-copy');
            $(placeholderEl).addClass('head-cell__placeholder');
          },
          beforeStop: (e, ui) => {
            const helperEl = ui.helper[0];
            $(helperEl).removeClass('head-cell__draggable-copy');
            const sortedIds = $(this.$refs.headRow).sortable('toArray');
            this.sortedColumnIdsPosition = sortedIds;
            this.columns = this.getColumnsByIds(sortedIds, this.tableColumns);
          },
        });

      if (this.scrollableContainerSelector) {
        $(this.scrollableContainerSelector).on('scroll', this.stopSort);
      }
    },
    stopSort() {
      $(this.$refs.headRow).sortable('cancel');
    },
    getColumnsByIds(ids, source) {
      return ids.map((id) => source.find((item) => item.id === id)).filter((col) => col);
    },
    updateVisibleColumnsBySortedPosition(visibleColumns) {
      if (this.columnIdsStorageKey) {
        const stringifyIds = localStorage.getItem(this.columnIdsStorageKey);
        const sortedIds = stringifyIds ? JSON.parse(stringifyIds) : [];

        // eslint-disable-next-line no-restricted-syntax
        for (const column of visibleColumns) {
          if (!sortedIds.includes(column.id)) {
            sortedIds.push(column.id);
          }
        }

        this.columns = stringifyIds
          ? this.getColumnsByIds(sortedIds, visibleColumns)
          : visibleColumns;
      } else {
        this.columns = visibleColumns;
      }
    },

    // resize columns and head-row
    makeColumnsResizable() {
      const headRowElements = $('.ao-table__cell-container_head');

      headRowElements.each((_, el) => {
        $(el).resizable({
          handles: 'e',
          minWidth: 140,
          maxWidth: 600,
          stop: (e, ui) => {
            const { width } = ui.size;
            const parentEl = ui.element[0].parentElement;
            const colId = $(parentEl).attr('id');
            const index = this.getIndexOfColumnById(colId);
            this.setBodyColumnWidth({ index, width });
            this.saveTableColumnSizesById({ colId, width });
          },
        });
      });
    },
    saveTableColumnSizesById({ colId, width }) {
      if (!this.columnsSizeStorageKey) {
        return;
      }

      const settings = getColumnsSizesFromStorage(this.columnsSizeStorageKey);
      settings[colId] = width;
      setColumnsSizesToStorage(this.columnsSizeStorageKey, settings);
    },
    getIndexOfColumnById(id) {
      return $(this.$refs.headRow).children().toArray().findIndex((child) => $(child).attr('id') === id);
    },
    setBodyColumnWidth({ index, width }) {
      const bodyRows = $(this.$refs.tBody).find('.body-row');

      bodyRows.each((_, row) => {
        const td = $(row).children()[index];
        const cellContainer = $(td).children('.ao-table__cell-container')[0];
        cellContainer.style.width = `${width}px`;
      });
    },
    setColumnsWidthBySettings() {
      const settings = getColumnsSizesFromStorage(this.columnsSizeStorageKey);

      const headCells = $(this.$refs.headRow).find('.ao-table__cell-container_head');
      const bodyRows = $(this.$refs.tBody).find('.body-row');

      Object.entries(settings).forEach(([colId, width]) => {
        const index = this.getIndexOfColumnById(colId);
        const headCell = headCells[index];

        if (headCell) {
          headCell.style.width = `${width}px`;
        }

        bodyRows.each((_, row) => {
          const td = $(row).children()[index];
          const bodyCell = $(td).children('.ao-table__cell-container')[0];

          if (bodyCell) {
            bodyCell.style.width = `${width}px`;
          }
        });
      });
    },

    // column config
    getColButtonRefLink(id) {
      return `${id}_col-btn`;
    },
    openColumnPopup({ id, config }) {
      const refLink = this.getColButtonRefLink(id);

      if (this.$refs[refLink].length) {
        const [el] = this.$refs[refLink];
        this.columnPopupParent = el;

        if (this.columnPopupIsVisible) {
          const unwatch = this.$watch('columnPopupIsVisible', (newValue) => {
            this.columnPopupIsVisible = !newValue;
            this.columnConfig = config;
            this.configuredColumnId = id;
            unwatch();
          });
        } else {
          this.columnPopupIsVisible = true;
          this.columnConfig = config;
          this.configuredColumnId = id;
        }
      }
    },

    // table columns sort and filter
    applyColumnConfig(payload) {
      const {
        id, sortBy, filter, uniqueValues,
      } = payload;

      const columnParams = {
        ...(sortBy ? { sortBy } : {}),
        ...(filter.rule ? { filter } : {}),
        ...(uniqueValues ? { uniqueValues } : {}),
      };

      const newParams = {
        ...this.tableParams,
        [id]: columnParams,
      };

      this.tableParams = newParams;
      this.$emit('onConfigChange', newParams);
    },
    resetColumnConfig(id) {
      const newTableParams = { ...this.tableParams };
      delete newTableParams[id];
      this.tableParams = newTableParams;
      this.$emit('onConfigChange', newTableParams);
    },
    resetTableConfig() {
      this.tableParams = {};
      this.$emit('onConfigChange', {});
    },
    isColumnConfigured(id) {
      return !!this.tableParams[id];
    },
    getColumnParams(id) {
      return this.tableParams[id] || null;
    },

    // pagination
    setItemsPerPage(value) {
      this.currentPage = 1;
      this.itemsPerPage = value;
    },
  },
};
</script>

<style scoped>
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #E9E9E9;
  border-radius: 10px;
  background-clip: content-box;
  border: 2px solid transparent;
  min-height: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #5C606F;
}

.ao-table {
  display: inline-block;
  overflow-y: auto;
  position: relative;
  max-height: 70vh;
  border-collapse: collapse;
}

.ao-table__container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
}

.ao-table__head {
  position: sticky;
  top: 0;
  display: inline-block;
  z-index: 1;
  overflow: hidden;
}

.ao-table__row {
  cursor: pointer;
  position: relative;
  z-index: 0;
}

.head-row {
  height: 56px;
}

.ao-table__row:not(.head-row):hover .ao-table__cell {
  background-color: #CCE5FF;
  border-color: #CCE5FF;
}

.ao-table__head-cell {
  height: inherit;
  background-color: #F7F7F7;
  border: 1px solid #E9E9E9;
  cursor: grab;
  padding: 0;
}

.head-cell__draggable-copy {
  background-color: #D5D5D5;
  border-color: transparent;
}

.ao-table >>> .head-cell__placeholder {
  display: block;
  visibility: initial !important;
  height: inherit;
  background-color: #BCD8F5;
}

.ao-table >>> .ui-resizable-e {
  cursor: ew-resize;
  right: 0;
}

.head-cell__button {
  display: flex;
  align-items: center;
  height: 100%;
  outline: none;
  border: none;
  padding: 0;
  background-color: transparent;
  color: #3385FF;
  font-weight: 400;
  font-size: 12px;
  text-wrap: balance;
  cursor: pointer;
}

.head-cell__button:hover {
  text-decoration: underline;
}

.head-cell__configured-column-mark {
  width: 6px;
  height: 6px;
  flex-shrink: 0;
  border-radius: 50%;
  background-color: #3385FF;
}

.ao-table__cell {
  padding: 0;
  border: 1px solid transparent;
  border-bottom: 1px solid #E9E9E9;
}

.ao-table__cell:first-of-type {
  border-left: 1px solid #E9E9E9;
}

.ao-table__cell:last-of-type {
  border-right: 1px solid #E9E9E9;
}

.ao-table__cell-container {
  width: 140px;
  overflow: hidden;
  padding: 8px;
}

.ao-table__cell-container_head {
  display: flex;
  gap: 4px;
  min-height: 100%;
  align-items: center
}

.ao-table__cell-text {
  text-overflow: ellipsis;
  text-wrap: balance;
  overflow-x: hidden;
}
</style>
