<template>
  <my-popup
    v-if="imageOverLink"
    key="image-over"
    :anchor="imageOverAnchor"
    :portal="portal"
    class-name="pim-tree-image-over"
    placement="top start">
    <div class="popover" ref="imageOverPopup" :style="{opacity:imageOverOpacity}">
      <div class="popover-body">
        <img :src="imageOverLink">
      </div>
    </div>
  </my-popup>
</template>
<script>
module.exports = {
  props: {
    portal: { type: String, default: 'hover' },
  },
  data() {
    return {
      imageOverAnchor: null,
      imageOverOpacity: 0,
      imageOverLink: null,
      imageOverShowTimer: null,
      imageOverHideTimer: null,
      imageOverSkipTarget: null,
    };
  },
  mounted() {
    window.addEventListener('mousemove', this.handleMouseMove);
    // this.$parent.$el.addEventListener("mouseleave", this.handleMouseLeave)
  },
  beforeDestroy() {
    window.removeEventListener('mousemove', this.handleMouseMove);
    // this.$parent.$el.removeEventListener("mouseleave", this.handleMouseLeave)
  },
  watch: {
    imageOverOpacity(opacity) {

    },
    imageOverAnchor(anchor) {

    },
    imageOverShowTimer(timer) {

    },
    imageOverHideTimer(timer) {

    },
  },
  methods: {
    dismiss() {
      this.imageOverSkipTarget = this.imageOverAnchor;
      clearTimeout(this.imageOverHideTimer);
      clearTimeout(this.imageOverShowTimer);
      this.imageOverAnchor = null;
      this.imageOverOpacity = 0;
      this.imageOverLink = null;
      this.imageOverHideTimer = null;
      this.imageOverShowTimer = null;
    },
    handleMouseMove(e) {
      if (this.imageOverSkipTarget && e.target != this.imageOverSkipTarget) {
        this.imageOverSkipTarget = null;
      }
      if (e.target.tagName == 'IMG' && e.target.src && e.target.offsetWidth < 100) {
        this.imageOverSkipTarget = null;
        if (this.imageOverLink != e.target.src) {
          this.imageOverLink = e.target.src;
          this.imageOverAnchor = e.target;
        }
        if (this.imageOverHideTimer) {
          clearTimeout(this.imageOverHideTimer);
          // clearTimeout(this.imageOverShowTimer)
          this.imageOverHideTimer = null;
          // this.imageOverShowTimer = null
          this.imageOverOpacity = 1;
        }
        if (!this.imageOverShowTimer) {
          this.imageOverShowTimer = setTimeout(() => {
            this.imageOverOpacity = 1;
            this.imageOverShowTimer = null;
          }, 500);
        }
      } else {
        if (this.imageOverAnchor && !this.imageOverHideTimer) {
          this.imageOverOpacity = 0;
          this.imageOverHideTimer = setTimeout(() => {
            this.imageOverLink = null;
            this.imageOverHideTimer = null;
            this.imageOverAnchor = null;
            this.imageOverOpacity = 0;
            clearTimeout(this.imageOverShowTimer);
            this.imageOverShowTimer = null;
          }, 300);
        }
      }
    },
    handleMouseLeave(e) {
      clearTimeout(this.imageOverHideTimer);
      clearTimeout(this.imageOverShowTimer);
      this.imageOverAnchor = null;
      this.imageOverOpacity = 0;
      this.imageOverLink = null;
      this.imageOverHideTimer = null;
      this.imageOverShowTimer = null;
    },
  },
};
</script>
<style>
.pim-tree-image-over {
    z-index: 1060;
    pointer-events: none;
}
.pim-tree-image-over .popover {
    transition: opacity 0.3s;
    max-width: initial;
    transform: translate(25px, 0);
}
.pim-tree-image-over .popover-body {
    padding: 10px;
}
.pim-tree-image-over img {
    max-width: 300px;
    max-height: 300px;
    object-fit: contain;
}
</style>
