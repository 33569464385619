const throttle = (fn, timeout = 200) => {
  let timer = null;

  return function perform(...args) {
    if (timer) {
      return;
    }

    timer = setTimeout(() => {
      fn(...args);
      clearTimeout(timer);
      timer = null;
    }, timeout);
  };
};

module.exports = { throttle };
