<template>
  <div class="gp-upload">
    <div
      class="drop-zone"
      ref="dropZone"
      @dragover="$event.preventDefault(); $refs.dropZone.classList.add('highlight')"
      @dragenter="$event.preventDefault(); $refs.dropZone.classList.add('highlight')"
      @dragleave="$event.preventDefault(); $refs.dropZone.classList.remove('highlight')"
      @drop="$event.preventDefault(); $refs.dropZone.classList.remove('highlight'); uploadFiles($event.dataTransfer.files)"
    >
      <l10n value="Darg & drop new files here or click the link below"/>
      <a href="javascript:void(0)" @click="triggerUpload">
        <l10n value="Upload files"/>
      </a>
      <ul>
        <li v-for="status, name in statuses">
          <feather-icon :name="status"/>
          {{name}}
          <ul v-if="paths[name]">
            <li v-for="family, stream in paths[name]">
              <l10n :value="stream"/>
              -
              <l10n :value="{
                loadedPaths: 'loaded',
                skippedPaths: 'skipped',
                pendingPaths: 'pending',
                excludedPaths: 'excluded',
              }[family] || family"/>
              <a
                v-if="family === 'loadedPaths'"
                href="javascript:void(0)"
                @click="browseStream(stream, [{'__file__': [name]}])">
                <l10n value="browse"/>
              </a>
              <gp-loaded-path-info
                v-if="family === 'loadedPaths'"
                :stream="stream"
                :paths="[name]"/>
            </li>
          </ul>
        </li>
      </ul>
      <form style="display: none" ref="form">
        <input type="file" ref="input" multiple @change="uploadFiles($event.target.files); $refs.form.reset()"/>
      </form>
    </div>
    <my-dialog
      v-if="browsingStream"
      :large="true"
      :scrollable="true"
      :title="browsingStream"
      @close="
        browsingStream = null
        browsingFilter = null"
    >
      <gp-stream
        :stream="browsingStream"
        :filter="browsingFilter"/>
    </my-dialog>
  </div>
</template>
<script>
let utils = require('../my-utils');
module.exports = {
  props: {
    streams: { type: Array, default: () => [] },
  },
  data() {
    return {
      data: null,
      statuses: {},
      updateId: null,
      destroyed: false,
      browsingStream: null,
      browsingFilter: null,
    };
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  computed: {
    masks() {
      let masks = [];
      if (this.data) {
        _(this.data.dataset.streams)
          .toPairs()
          .forEach(([stream, { spouts }]) =>
            _.forEach(spouts, ({ searchMask }) => {
              if (searchMask) {
                try {
                  masks.push({
                    mask: new RegExp(searchMask),
                    stream,
                  });
                } catch (ex) {
                  console.warn(searchMask, ex);
                }
              }
            }));
      }
      return masks;
    },
    paths() {
      let paths = {};
      let families = ['loadedPaths', 'skippedPaths', 'excludedPaths'];
      if (this.data) {
        _(this.data.dataset.streams)
          .toPairs()
          .forEach(([stream, { spouts }]) =>
            _.forEach(spouts, (spout) =>
              _.forEach(families, (family) => {
                for (let path of spout[family] || []) {
                  let streams = paths[path];
                  if (!streams) {
                    paths[path] = streams = {};
                  }
                  streams[stream] = family;
                }
              })));
      }
      for (let path of Object.keys(this.statuses)) {
        let streams = paths[path];
        if (!streams) {
          paths[path] = streams = {};
        }
        for (let { mask, stream } of this.masks) {
          if (path.match(mask) && !streams[stream]) {
            streams[stream] = 'pendingPaths';
          }
        }
      }
      return paths;
    },
  },
  methods: {
    browseStream(stream, filter) {
      this.browsingStream = stream;
      this.browsingFilter = filter;
    },
    update(adhoc) {
      let updateId = utils.randomId();
      this.updateId = updateId;
      let query = `
                query {
                    dataset {
                        streams {
                            ${this.streams.map((stream) => `
                                ${stream} {
                                    size
                                    spouts {
                                    ... on DelimitedFile {
                                        searchMask
                                        loadedPaths
                                        pendingPaths
                                        skippedPaths
                                        excludedPaths
                                    }
                                }
                            }`)}
                        }
                    }
                }`;

      utils.fetchWithAjaxOpts({
        url: '/graphql?__updateGpUploadOnMounted__',
        method: 'POST',
        data: JSON.stringify({ query }),
        dataType: 'json',
        contentType: 'application/json',
      })
        .then(({ data }) => {
          if (this.updateId === updateId) {
            this.data = data;
          }
        })
        .finally(() => {
          if (!adhoc && !this.destroyed) {
            setTimeout(this.update, 1000);
          }
        });
    },
    triggerUpload() {
      $(this.$refs.input).trigger('click');
    },
    async uploadFiles(files) {
      for (let file of files) {
        this.$set(this.statuses, file.name, 'clock');
      }
      for (let file of files) {
        let formData = new FormData();
        formData.append('file', file);
        try {
          await fetch('/upload', {
            method: 'POST',
            body: formData,
          });
          this.$set(this.statuses, file.name, 'check');
        } catch (ex) {
          console.warn(ex);
          this.$set(this.statuses, file.name, 'alert-circle');
        }
      }
    },
  },
};
</script>
<style>
.my-dark-theme .drop-zone {
    background-color: var(--dark);
    border-color: var(--light);
}
.gp-upload .drop-zone {
    display: flex;
    flex-direction: column;
}
.gp-upload .drop-zone > * {
    margin-top: 10px;
}
.gp-upload .drop-zone > *:first-child {
    margin-top: 0px;
}
.gp-upload ul {
    list-style: none;
    padding: 0;
    margin: auto;
    text-align: left;
    font-size: 0.9em;
    max-width: 100%;
}
.gp-upload ul:empty {
    display: none;
}
.gp-upload .feather-icon svg {
    width: 18px;
    height: 18px;
    margin-right: 4px;
    margin-top: 1px;
    vertical-align: top;
}
.gp-upload .feather-icon-clock {
    color: var(--pink);
}
.gp-upload .feather-icon-check {
    color: var(--green);
}
.gp-upload .feather-icon-alert-circle {
    color: var(--red);
}
.gp-upload .gp-loaded-path-info pre {
    max-height: 300px;
}
</style>
