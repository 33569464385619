<template>
  <tr
    tabindex="0"
    :data-row="i"
    :class="$options.methods.rowClass($props)"
    :style="$options.methods.rowStyle($props)"
    v-html="$options.methods.staticCells($props)"/>
</template>
<script>
let utils = require('./my-utils');
let { PlainTableCellStatic } = require('./plain-table-cell-static.js');

module.exports = {
  props: {
    i: { type: Number, default: -1 },
    row: { type: Array },
    rows: { type: Array },
    meta: { type: Object },
    primary: { type: Boolean },
    selected: { type: Boolean },
    subTotal: { type: Boolean },
    sections: { type: Array },
  },
  methods: {
    rowClass(props) {
      let classes = {
        primary: props.primary,
        selected: props.selected,
        'sub-total': props.subTotal,
        leaf: props.row.level === props.meta.dims.length - 1,
      };
      for (let section of props.sections) {
        for (let column of section.columns) {
          if (column.rowClass) {
            let class_ = column.rowClass(
                        	props.row[column.i],
                        	props.row,
                        	column,
                        	props.meta);
            if (class_) {
              classes[class_] = true;
            }
          }
        }
      }
      return classes;
    },
    rowStyle(props) {
      let style = {};
      for (let section of props.sections) {
        for (let column of section.columns) {
          if (column.rowStyle) {
            _.assign(style,
              column.rowStyle(
                props.row[column.i],
                props.row,
                column,
                props.meta));
          }
        }
      }
      if (props.row.level !== undefined) {
        style['--level'] = `${props.row.level}`;
      }
      return style;
    },
    staticCells(props) {
      if (props.row.__cache.staticCells) {
        return props.row.__cache.staticCells;
      }
      let t0 = performance.now();
      let staticCells = [];
      let cellProps = {
        row: props.row,
        meta: props.meta,
      };
      for (let section of props.sections) {
        cellProps.section = section;
        for (let column of section.visibleColumns) {
          cellProps.column = column;
          staticCells.push(
            new PlainTableCellStatic(cellProps).render());
        }
      }
      staticCells = staticCells.join('');
      props.row.__cache.staticCells = staticCells;
      return staticCells;
    },
  },
};
</script>
<style>
</style>
