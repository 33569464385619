<template>
  <button
    type="button"
    class="ao-button"
    :class="[buttonClasses, { invalid, 'ao-button_rounded': rounded, 'ao-button_minified': minified }]"
    :disabled="disabled"
    @click="handleClick"
  >
    <slot>Button</slot>
  </button>
</template>

<script>
module.exports = {
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String, // "primary" | "secondary" | "text"
      required: false,
      default: 'primary',
    },
    size: {
      type: String, // "large" | "small"
      required: false,
      default: 'small',
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    minified: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    buttonClasses() {
      return `ao-button_${this.size} ao-button_${this.type}`;
    },
  },
  methods: {
    handleClick() {
      if (!this.disabled) {
        this.$emit('click');
      }
    },
  },
};
</script>

<style scoped>
.ao-button {
  border: none;
  outline: none;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: 0.1s all ease;
}

.ao-button_minified {
  width: auto;
}

.ao-button_rounded {
  border-radius: 50%;
}

.ao-button_small {
  padding: 9px 12px;
  font-size: 12px;
  line-height: 14px;
}

.ao-button_large {
  padding: 14px 16px;
  font-size: 14px;
  line-height: 18px;
}

.ao-button_primary {
  background-color: #5295d5;
  color: #ffffff;
}

.ao-button:disabled {
  background-color: #f7f7f7;
  color: #d4d4d4;
  cursor: not-allowed;
}

.ao-button_primary:not(:disabled, .invalid):hover {
  background-color: #3c80c1;
}

.ao-button_primary:not(:disabled, .invalid):active {
  box-shadow: 0 0 0 2px #5295d5;
}

.ao-button_secondary {
  background-color: #f7f7f7;
  color: #000000;
}

.ao-button_secondary:not(:disabled, .invalid):hover {
  background-color: #e9e9e9;
}

.ao-button_secondary:not(:disabled, .invalid):active {
  box-shadow: 0 0 0 2px #f7f7f7;
}

.ao-button:not(:disabled).invalid {
  background-color: #fae6eb;
  color: #f8c2c4;
}

.ao-button:not(:disabled).invalid:hover {
  background-color: #f8c2c4;
  color: #ffffff;
}

.ao-button:not(:disabled).invalid:active {
  box-shadow: 0 0 0 2px #fae6eb;
}

.ao-button_text {
  position: relative;
  padding: 0;
  border: none;
  outline: none;
  font-size: 12px;
  font-weight: 500;
  color: #5295D5;
  background: transparent;
  cursor: pointer;
}

.ao-button_text:not(:active):after,
.ao-button_text:disabled:after {
  content: '';
  position: absolute;
  bottom: -2px;
  left: 0;
  display: inline-block;
  height: 1px;
  width: 100%;
  border-radius: 4px;
  background-color: #5295D5;
}

.ao-button_text:disabled {
  color: #9B9B9B;
  background-color: transparent;
  cursor: not-allowed;
}
</style>
