<template>
  <div class="accordions-container" ref="container">
    <my-accordion
      v-for="(dataset, i) in [{}, ...datasets]"
      :key="dataset.label"
      :openedByDefault="dataset === datasets[0]"
      :ref="`accordion-${i}`"
      @accordionOpened="handleAccordionOpened(`accordion-${i}`)"
    >
      <template v-slot:title>
        <span class="accordion-title">
          <l10n :value="i === 0 ? 'Общие настройки' : dataset.label" />
        </span>
      </template>
      <template v-slot:content>
        <common-settings v-if="i === 0" />
        <dataset-settings
          v-else
          :index="i - 1"
          :datasets="datasets"
        />
      </template>
    </my-accordion>
  </div>
</template>

<script>
const DatasetSettings = require('./dataset-settings.vue').default;
const CommonSettings = require('./common-settings.vue').default;
const MyAccordion = require('../../../my-accordion.vue').default;

module.exports = {
  components: {
    DatasetSettings,
    CommonSettings,
    MyAccordion,
  },

  props: {
    datasets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  methods: {
    handleDiscard() {
      this.$emit('onVisibleChange', false);
    },
    handleConfirm() {
      this.$emit('onVisibleChange', false);
    },
    handleAccordionOpened(accordionRef) {
      Object.keys(this.$refs).forEach((ref) => {
        if (ref.startsWith('accordion') && ref !== accordionRef) {
          this.$refs[ref][0].closeAccordion();
        }
      });
    },
  },
};
</script>

<style scoped>
    .accordions-container {
      width: 100%;
    }
    .accordion-title {
      margin-left: 0.75rem;
      font-size: 14px;
      font-weight: 500;
    }
</style>
