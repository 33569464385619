<script>
const utils = require('../../../my-utils');
const wrapper = require('./wrapper.vue').default;
const createKnob = require('./createKnob');

module.exports = {
  props: {
    rule: {
      type: Object,
      default: () => ({
        text: 'rule text',
      }),
    },

    locale: {
      type: String,
      default: 'en-US',
    },

    currency: {
      type: String,
      default: 'USD',
    },
  },

  render(createElement) {
    const ruleText = utils.l10n(this.rule.text);
    const result = [...ruleText.matchAll(/{([^}\.\[]+)[^}]*?}/g)].reduce((acc, matchItem) => {
      const [match, variableData] = matchItem;
      const [variableName, variableMode = 'default'] = variableData.split(':');
      const s = acc.pop();
      const idx = s.indexOf(match);
      const variable = _.cloneDeep(this.rule.variables?.[variableName] || {});
      variable.mode = variableMode;
      let element = `<${variableName}>`;

      try {
        const {
          component,
          inputToValue,
          valueToInput,
          display,
          ...extraParams
        } = createKnob(variable, variableName, variableMode, this.locale, this.currency);

        if (component) {
          const ref = utils.randomId();
          const closePopup = () => {
            this.$refs[`wrapper-${ref}`].isPopupVisible = false;
          };
          const updateValue = () => {
            const newValue = inputToValue(this.$refs[`input-${ref}`].model);
            this.$emit('update-value', { variableName, newValue });
          };
          const submit = () => {
            closePopup(); updateValue();
          };
          const cancel = () => {
            closePopup();
          };

          element = createElement(wrapper, {
            ref: `wrapper-${ref}`,
            props: { display: display(variable.value) || '&lt;empty&gt;' },
            on: { submit, cancel },
          }, [
            createElement(component, {
              ref: `input-${ref}`,
              slot: 'input',
              props: {
                value: valueToInput(variable.value),
                ...extraParams,
              },
              on: { submit, cancel },
            }),
          ]);
        }
      } catch (e) {
        console.warn(e.message);
      }

      return [...acc, s.slice(0, idx), element, s.slice(idx + match.length)];
    }, [ruleText]);

    return createElement('p', {
      class: 'gp-rule-text',
    }, result);
  },
};
</script>
