const { DOMAIN } = require('./endpoint');
const { getListOfDates } = require('../utils/dates');
const {
  getProductName,
  getProductCategoryName,
  getWarehouseName,
  getWarehouseCategoryName,
  getAuthorName,
  getCalendarName,
  getStartAt,
  getEndAt,
  getCreatedAt,
  getIdNameField,
  getLeafType,
  getLeafPromo,
  getLeafDescription,
  getLeafDuration,
  getLeafHasPromo,
} = require('../utils/tableColumnNameGetters');

// ao-calendars
const COMPONENT_NAMES = {
  catalog: 'CalendarsCatalog',
  register: 'CalendarsRegister',
  calendar: 'CalendarCard',
  rules: 'CalendarRulesCard',
  schedule: 'ScheduleCard',
};
const calendarTabsStorageKey = 'calendar-tabs';
const calendarEntityStorageKey = 'calendar-cards';
const calendarRulesEntityStorageKey = 'calendar-rules-cards';
const calendarSchedulesEntityStorageKey = 'calendar-schedules-cards';
const newCalendar = 'new-calendar-card';
const newCalendarRule = 'new-calendar-rule';
const newSchedule = 'new-schedule';
const catalogTab = { id: 'calendars-catalog', title: 'Справочник календарей', componentName: COMPONENT_NAMES.catalog };
const registerTab = { id: 'calendars-register', title: 'Реестр привязок календарей', componentName: COMPONENT_NAMES.register };

// storage keys
const storageKeys = {
  parentName: 'calendars',
  register: {
    visibleColumnsKey: 'calendars-register-visible-cols',
    columnsSizeKey: 'calendars-register-columns-size',
  },
};

// calendars-catalog
const catalogColumns = [
  { id: 'id', title: 'ID календаря', getter: getIdNameField },
  { id: 'type', title: 'Тип календаря', getter: getLeafType },
  { id: 'description', title: 'Описание календаря', getter: getLeafDescription },
  { id: 'duration', title: 'Продолжительность в часах', getter: getLeafDuration },
  { id: 'hasPromo', title: 'Признак промо', getter: getLeafHasPromo },
  { id: 'promo', title: 'Промо', getter: getLeafPromo },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/calendars/categories/hierarchy/`,
  leafsField: 'calendars',
};

// calendars-register
const registerColumns = [
  {
    id: 'id',
    title: 'ID связи',
    field: 'id',
    config: {
      sorting: true,
      filterType: 'numeric',
    },
  },
  {
    id: 'product_name',
    title: 'Товар',
    field: getProductName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'product_category_name',
    title: 'Категория товара',
    field: getProductCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_name',
    title: 'Узел',
    field: getWarehouseName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'warehouse_category_name',
    title: 'Категория узла',
    field: getWarehouseCategoryName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'start_at',
    title: 'Дата действия с',
    field: getStartAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'end_at',
    title: 'Дата действия до',
    field: getEndAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'description',
    title: 'Описание',
    field: 'description',
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'created_at',
    title: 'Дата создания',
    field: getCreatedAt,
    config: {
      sorting: true,
      filterType: 'dates',
    },
  },
  {
    id: 'author',
    title: 'Автор',
    field: getAuthorName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
  {
    id: 'calendar_name',
    title: 'Календарь',
    field: getCalendarName,
    config: {
      sorting: true,
      filterType: 'text',
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  endpoint: `${DOMAIN}/calendars/connections/filtered/`,
  configEndpoint: `${DOMAIN}/calendars/connections/filtered/config/`,
};

// endpoints
const ENDPOINTS = {
  CALENDARS: `${DOMAIN}/calendars/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/calendars/categories/hierarchy/`,
  CONNECTIONS: `${DOMAIN}/calendars/connections/`,
  SCHEDULE: `${DOMAIN}/calendars/schedule/`,
  SCHEDULE_PREVIEW: `${DOMAIN}/calendars/schedule/preview/`,
  SCHEDULE_TYPES_END: 'types',
  REPETITION_TYPES_END: 'repetition_types',
};

// schedule card
const LISTS_OF_DATES = {
  MONTH: getListOfDates(31),
  YEAR: getListOfDates(366),
};

const WEEK_DAYS = [
  { id: 1, name: 'Понедельник', shortName: 'Пн' },
  { id: 2, name: 'Вторник', shortName: 'Вт' },
  { id: 3, name: 'Среда', shortName: 'Ср' },
  { id: 4, name: 'Четверг', shortName: 'Чт' },
  { id: 5, name: 'Пятница', shortName: 'Пт' },
  { id: 6, name: 'Суббота', shortName: 'Сб' },
  { id: 7, name: 'Воскресенье', shortName: 'Вс' },
];

const REPETITION_TYPE_NAMES = {
  ONCE: 'ONCE',
  DAILY: 'DAILY',
  WEEKLY: 'WEEKLY',
  MONTHLY: 'MONTHLY',
  YEARLY: 'YEARLY',
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  calendarTabsStorageKey,
  calendarEntityStorageKey,
  calendarRulesEntityStorageKey,
  calendarSchedulesEntityStorageKey,
  newCalendar,
  newCalendarRule,
  newSchedule,
  catalogTab,
  registerTab,
  storageKeys,
  hierarchyTableProps,
  registerTableProps,
  LISTS_OF_DATES,
  WEEK_DAYS,
  REPETITION_TYPE_NAMES,
};
