<template>
  <span class="inline-select">
    <span>
      <l10n :value="text" />
    </span>
    <select @input="handleInput">
      <option
        v-for="(text, name) in options"
        :value="name"
        :selected="value === name">
        <l10n :value="text" />
      </option>
    </select>
  </span>
</template>

<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: String,
    options: { type: Object },
  },
  computed: {
    text() {
      return this.options[this.value];
    },
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
  },
};
</script>

<style scoped>
.my-light-theme .inline-select {
    background-color: #fff;
}
.my-dark-theme .inline-select {
    background-color: #222;
}
.inline-select {
    list-style: none;
    margin: 0;
    padding: 4px 0;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
}
.inline-select li a {
    color: #212529;
    display: block;
    line-height: 24px;
    white-space: nowrap;
    padding: 0;
    padding-left: 6px;
    padding-right: 20px;
}
.inline-select li a > span:first-child {
    display: inline-block;
    width: 16px;
}
.inline-select span {
    color: var(--cyan);
}
.inline-select:hover span {
    text-decoration: underline;
}
.inline-select select {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
</style>
