<template>
  <label class="ao-radio">
    <input
      type="radio"
      class="ao-radio__input"
      :id="id"
      :disabled="disabled"
      :value="value"
      :checked="checked"
      :name="name"
      @change="onChange"
    />
    <span class="ao-radio__checkmark" :class="{ disabled }" />
  </label>
</template>

<script>
module.exports = {
  name: 'ao-radio',

  model: {
    prop: 'checkedValue',
    event: 'onUpdate',
  },

  props: {
    checkedValue: {
      type: [String, Number],
      required: false,
      default: null,
    },
    id: {
      type: [String, Number],
      required: false,
      default: null,
    },
    value: {
      type: [String, Number],
      required: false,
      default: null,
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    checked: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    onChange(event) {
      this.$emit('onUpdate', event.target.value);
    },
  },
};
</script>

<style scoped>
.ao-radio {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.ao-radio__input {
  display: none;
}

.ao-radio__checkmark {
  display: inline-block;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 50%;
  border: 1px solid #AFAFAF;
}

.ao-radio__input:checked + .ao-radio__checkmark.disabled {
  border-width: 5px;
}

.ao-radio__checkmark.disabled {
  border-color: #E9E9E9;
  cursor: not-allowed;
}

.ao-radio__checkmark:not(.disabled):hover {
  border-color: #9B9B9B;
}

.ao-radio__input:checked + .ao-radio__checkmark:not(.disabled) {
  border: 5px solid #5295D5;
}

.ao-radio__input:checked + .ao-radio__checkmark:not(.disabled):hover {
  border-color: #3C80C1;
}
</style>
