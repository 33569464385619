var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ao-popup",
    {
      staticClass: "column-config-popup",
      attrs: {
        closable: "",
        parent: _vm.parent,
        scrollableAreaSelector: _vm.scrollableAreaSelector
      },
      on: { onClose: _vm.hideElement },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "footer__button-row" },
                [
                  _c(
                    "ao-button",
                    {
                      attrs: { type: "secondary" },
                      on: { click: _vm.resetChanges }
                    },
                    [_c("l10n", { attrs: { value: "Сбросить" } })],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "ao-button",
                    {
                      attrs: { disabled: _vm.isConfigNotChanged },
                      on: { click: _vm.confirmChanges }
                    },
                    [_c("l10n", { attrs: { value: "Применить" } })],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      [
        _c("div", { staticClass: "column-config" }, [
          _vm.isSortable
            ? _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "column-config__button sorted-button",
                    class: { active: this.sortBy === "asc" },
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.changeSorting("asc")
                      }
                    }
                  },
                  [
                    _c("arrow-icon"),
                    _vm._v(" "),
                    _c("l10n", {
                      attrs: { value: "Сортировка по возрастанию" }
                    })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "column-config__button sorted-button desc",
                    class: { active: this.sortBy === "desc" },
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.changeSorting("desc")
                      }
                    }
                  },
                  [
                    _c("arrow-icon"),
                    _vm._v(" "),
                    _c("l10n", { attrs: { value: "Сортировка по убыванию" } })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isFilterable
            ? _c(
                "div",
                { staticClass: "column-config__filter-wrapper" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "column-config__button column-config__filter-button",
                      class: {
                        opened: _vm.showFilter.byCondition,
                        invalid: !_vm.isFilterValid && _vm.tryToSave
                      },
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          _vm.showFilter.byCondition = !_vm.showFilter
                            .byCondition
                        }
                      }
                    },
                    [
                      _c("button-arrow-icon"),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Фильтр по условию" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "slide" } }, [
                    _vm.showFilter.byCondition
                      ? _c("div", { staticClass: "column-config__filter" }, [
                          _c(
                            "div",
                            { staticClass: "column-config__filter-row" },
                            [
                              _c("my-multiselect", {
                                attrs: {
                                  options: _vm.filterRules,
                                  closeOnSelect: "",
                                  label: "name",
                                  trackBy: "id",
                                  placeholder: "Выберите условие"
                                },
                                model: {
                                  value: _vm.filterRule,
                                  callback: function($$v) {
                                    _vm.filterRule = $$v
                                  },
                                  expression: "filterRule"
                                }
                              })
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !_vm.isFilterValueBoolean
                            ? _c(
                                "div",
                                { staticClass: "column-config__filter-row" },
                                [
                                  _vm.isNumericFilter
                                    ? _c("ao-input", {
                                        attrs: {
                                          invalid:
                                            _vm.tryToSave &&
                                            !_vm.isFilterValueValid,
                                          disabled: !_vm.filterRule,
                                          onlyInteger: !_vm.isNotInteger,
                                          maxLength: _vm.filterValueMaxLength,
                                          fractionalMaxLength:
                                            _vm.numericFractionalMaxLength,
                                          integerMaxLength:
                                            _vm.numericIntegerMaxLength,
                                          type: "number",
                                          placeholder: "Введите значение",
                                          size: "small"
                                        },
                                        model: {
                                          value: _vm.filterValue,
                                          callback: function($$v) {
                                            _vm.filterValue = $$v
                                          },
                                          expression: "filterValue"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isDatesFilter
                                    ? _c("ao-input", {
                                        attrs: {
                                          invalid:
                                            _vm.tryToSave &&
                                            !_vm.isFilterValueValid,
                                          disabled: !_vm.filterRule,
                                          type: "date",
                                          placeholder: "Введите значение",
                                          size: "small"
                                        },
                                        model: {
                                          value: _vm.filterValue,
                                          callback: function($$v) {
                                            _vm.filterValue = $$v
                                          },
                                          expression: "filterValue"
                                        }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isTextFilter
                                    ? _c("ao-input", {
                                        attrs: {
                                          maxLength: 100,
                                          invalid:
                                            _vm.tryToSave &&
                                            !_vm.isFilterValueValid,
                                          disabled: !_vm.filterRule,
                                          placeholder: "Введите значение",
                                          size: "small"
                                        },
                                        model: {
                                          value: _vm.filterValue,
                                          callback: function($$v) {
                                            _vm.filterValue = $$v
                                          },
                                          expression: "filterValue"
                                        }
                                      })
                                    : _vm._e()
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.useFilterByUniqueValue
            ? _c(
                "div",
                { staticClass: "column-config__filter-wrapper" },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "column-config__button column-config__filter-button",
                      class: { opened: _vm.showFilter.byUniqueValue },
                      attrs: { type: "button" },
                      on: { click: _vm.toggleFilterByUniqueValues }
                    },
                    [
                      _c("button-arrow-icon"),
                      _vm._v(" "),
                      _c("l10n", { attrs: { value: "Фильтр по значению" } })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("transition", { attrs: { name: "slide" } }, [
                    _vm.showFilter.byUniqueValue
                      ? _c(
                          "div",
                          {
                            staticClass: "column-config__filter unique-filter"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "column-config__filter-row" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "unique-filter__input-wrapper"
                                  },
                                  [
                                    _c("ao-input", {
                                      staticClass: "unique-filter__input",
                                      attrs: {
                                        placeholder: "Введите значение"
                                      },
                                      model: {
                                        value: _vm.searchUniqueValue,
                                        callback: function($$v) {
                                          _vm.searchUniqueValue = $$v
                                        },
                                        expression: "searchUniqueValue"
                                      }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "i",
                                      {
                                        staticClass:
                                          "unique-filter__icon-wrapper"
                                      },
                                      [_c("search-icon")],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "column-config__filter-row" },
                              [
                                _vm.uniqueValuesAfterSearch.length > 0
                                  ? _c(
                                      "ul",
                                      { staticClass: "unique-filter__list" },
                                      [
                                        _c(
                                          "li",
                                          {
                                            staticClass:
                                              "unique-filter__list-item"
                                          },
                                          [
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "unique-filter__item-button",
                                                attrs: { type: "button" },
                                                on: {
                                                  click: function($event) {
                                                    $event.preventDefault()
                                                    return _vm.checkAllUniqueValues.apply(
                                                      null,
                                                      arguments
                                                    )
                                                  }
                                                }
                                              },
                                              [
                                                _c("ao-checkbox", {
                                                  attrs: {
                                                    checked:
                                                      _vm.isAllUniqueValueChecked
                                                  }
                                                }),
                                                _vm._v(" "),
                                                _c("l10n", {
                                                  attrs: {
                                                    value: "Выбрать все"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.uniqueValuesAfterSearch,
                                          function(uniqueValue) {
                                            return _c(
                                              "li",
                                              {
                                                key: uniqueValue,
                                                staticClass:
                                                  "unique-filter__list-item"
                                              },
                                              [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "unique-filter__item-button",
                                                    attrs: { type: "button" },
                                                    on: {
                                                      click: function($event) {
                                                        $event.preventDefault()
                                                        return _vm.toggleUniqueValue(
                                                          uniqueValue
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [
                                                    _c("ao-checkbox", {
                                                      attrs: {
                                                        checked: _vm.isUniqueValueChecked(
                                                          uniqueValue
                                                        )
                                                      }
                                                    }),
                                                    _vm._v(" "),
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(uniqueValue)
                                                      )
                                                    ])
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        )
                                      ],
                                      2
                                    )
                                  : _vm._e()
                              ]
                            )
                          ]
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            : _vm._e()
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }