<template>
  <my-dialog
    :title="getModalTitle"
    v-if="show"
    @close="closeForm"
  >
    <div class="inputblock" v-if="shopDonor">
      <label
        class="inputblock__label"
        for="shop-donor"
      >
        Магазин-донор
      </label>
      <input
        id="shop-donor"
        v-model="shopDonor"
        class="inputblock__input input"
        disabled
      />
    </div>

    <div class="inputblock" v-if="shopAcceptor">
      <label
        class="inputblock__label"
        for="shop-acceptor"
      >
        Магазин-акцептор
      </label>
      <input
        id="shop-acceptor"
        v-model="shopAcceptor"
        class="inputblock__input input"
        disabled
      />
    </div>

    <div class="inputblock inputblock_select">
      <label class="inputblock__label" for="shop">{{ getLabelForMultiselect }}</label>
      <my-multiselect
        id="shop"
        v-model="shop"
        :options="shops"
        :loading="isLoading"
        :searchable="true"
        :maxHeight="190"
        @open="getShops"
        placeholder="Выберите магазин"
      />
    </div>
    <div class="buttons-block">
      <button
        type="button"
        class="button button_primary"
        :disabled="hasEmptyField && tryToSendForm"
        :class="{ button_disabled: hasEmptyField && tryToSendForm }"
        @click="onCreateShop"
      >
        <l10n value="Save" />
      </button>
      <button
        type="button"
        class="button button_secondary"
        @click="closeForm"
      >
        <l10n value="Cancel" />
      </button>
    </div>
  </my-dialog>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
  },
  props: {
    isAddCannibalisationShopModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    stream: {
      type: String,
      required: true,
    },
    username: {
      type: String,
      required: true,
      default: '',
    },
    shopDonor: {
      type: String,
      required: false,
      default: '',
    },
    shopAcceptor: {
      type: String,
      required: false,
      default: '',
    },
    targetYear: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      show: false,
      shop: null,
      shops: [],
      isLoading: false,
      tryToSendForm: false,
    };
  },

  computed: {
    getModalTitle() {
      if (this.shopDonor) {
        return 'Привязать магазин-каннибал';
      }
      if (this.shopAcceptor) {
        return 'Привязать магазин-донор';
      }
      return 'Добавить новый магазин';
    },
    getLabelForMultiselect() {
      if (this.shopDonor) {
        return 'Магазин-каннибал';
      }
      if (this.shopAcceptor) {
        return 'Магазин-донор';
      }
      return 'Наименование магазина';
    },
    hasEmptyField() {
      return !this.shop;
    },
  },

  watch: {
    isAddCannibalisationShopModalVisible(newValue) {
      this.show = newValue;
    },
  },

  methods: {
    async getShops() {
      this.isLoading = true;

      graphQl.makeGQRequest(`
      query {
        dataset {
        source(name: "SP_store_attributes") {
                        
        report(
          name: "gp-table",
          cores: 64,
          cache: true,
                    
          filter2: "(store == SP_store_sales_with_dwh_filtering.store)",
                    
          dims: "store",
          sort: []
          )
          {
            rows
          }
            }
            }
      }`, '/graphql?__getShops__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const shops = [];

        rows.forEach((row) => shops.push(row[0]));

        this.shops = shops;
        this.isLoading = false;
      }).catch(() => {
        this.createNotification('Error while fetching shops', 'error');
        this.shops = [];
        this.isLoading = false;
      });
    },

    onCreateShop() {
      if (this.hasEmptyField) {
        this.tryToSendForm = true;
        this.createNotification('Form has an empty fields', 'error');
        return;
      }

      let { stream } = this;
      const record = [];
      const records = [];
      const createTime = Date.now();

      if (this.shopDonor) {
        record.push(this.shopDonor);
        stream = 'SP_cannibal_stores_override';
      } else if (this.shopAcceptor) {
        record.push(this.shopAcceptor);
        stream = 'SP_donor_for_acceptor_stores_override';
      }

      record.push(this.targetYear);
      record.push(this.username);
      record.push(createTime);
      record.push(this.shop);
      records.push(record);

      const recordsJSON = JSON.stringify(records);

      graphQl.makeGQRequest(`
        mutation {
          appendRecords(
            stream: "${stream}",
            format: "json",
            records: ${utils.quote(recordsJSON)})
        }`, '/graphql?__addShop__').then((result) => {
        if (result.data) {
          this.createNotification('Added succesfully', 'success');
          this.cleanForm();
          this.$emit('on-cannibalisation-shop-successful-added');
        } else if (result.errors) {
          result.errors.forEach((error) => {
            this.createNotification(error.message, 'error');
          });
        }
      }).catch(() => {
        this.createNotification('Error while creating shop', 'error');
      });
    },

    closeForm() {
      this.cleanForm();
      this.$emit('add-cannibalisation-shop-modal-close');
    },

    cleanForm() {
      this.shop = null;
    },
  },
};
</script>

<style scoped>
.input {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}

.inputblock {
  margin-bottom: 16px;
}

.inputblock_select {
  height: 200px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}
.buttons-block {
  display: flex;
  justify-content: space-around;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 46px;
  border: none;
  border-radius: 8px;
  transition: background-color .3s ease-in-out;
}
.button_primary {
  background-color: #5295D5;
  color: #ffffff;
}
.button_secondary {
  background-color: #F7F7F7;
  color: #000000;
}
.button_primary:not(.button_disabled):hover {
  background-color: #3C80C1;
  transition: background-color .3s ease-in-out;
}
.button_secondary:hover {
  background-color: #E9E9E9;
  transition: background-color .3s ease-in-out;
}
.button_disabled {
  background-color: #F7F7F7;
  color: #D4D4D4;
  cursor: default;
}
</style>
