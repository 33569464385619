<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div
    ref="popup"
    class="ao-popup"
    :style="popupPosition"
    @click.stop
  >
    <header class="ao-popup__header" v-if="$slots.header">
      <slot name="header" />
    </header>
    <main class="ao-popup__main">
      <slot />
    </main>
    <footer class="ao-popup__footer" v-if="$slots.footer">
      <slot name="footer" />
    </footer>
  </div>
</template>

<script>
const { throttle } = require('../utils/decorators');

module.exports = {
  props: {
    parent: {
      type: [Object, Element],
      required: false,
      default: () => null,
    },
    closable: {
      type: Boolean,
      required: false,
      default: false,
    },
    xPosition: {
      type: String, // 'left' | 'right'
      required: false,
      default: 'right',
    },
    yPosition: {
      type: String, // 'top' | 'bottom'
      required: false,
      default: 'bottom',
    },
    scrollableAreaSelector: {
      type: String,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      parentEl: null,
      parentParams: {},
      popupParams: {},
      position: {
        x: this.xPosition,
        y: this.yPosition,
      },
      resizeObserver: null,
      elements: null,
    };
  },

  computed: {
    popupPosition() {
      const coordinates = {};

      coordinates.left = this.position.x === 'right'
        ? `${this.parentParams.left}px`
        : `${this.parentParams.left - (this.popupParams.width - this.parentParams.width)}px`;

      coordinates.top = this.position.y === 'bottom'
        ? `${this.parentParams.bottom + 14}px`
        : `${this.parentParams.top - this.popupParams.height - 14}px`;

      return coordinates;
    },
    throttledRecalculateParentParams() {
      return throttle(this.recalculateParentParams, 10);
    },
    throttledRecalculatePopoverParams() {
      return throttle(this.recalculatePopoverParams, 10);
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.popupParams = this.getOffset(this.$refs.popup);
    });

    if (this.parent) {
      this.parentParams = this.getOffset(this.parent);
    }

    if (this.closable) {
      setTimeout(() => {
        window.addEventListener('click', this.selfClose);
      });
    }

    window.addEventListener('resize', this.throttledRecalculateParentParams);
    window.addEventListener('resize', this.throttledRecalculatePopoverParams);

    if (this.scrollableAreaSelector) {
      /* eslint-disable no-undef */
      $(this.scrollableAreaSelector).on('scroll', this.throttledRecalculateParentParams);
      $(this.scrollableAreaSelector).on('scroll', this.throttledRecalculatePopoverParams);
    /* eslint-enable no-undef */
    } else {
      window.addEventListener('scroll', this.throttledRecalculateParentParams);
      window.addEventListener('scroll', this.throttledRecalculatePopoverParams);
    }

    this.resizeObserver = new ResizeObserver((entries) => {
      entries.forEach(() => {
        this.throttledRecalculatePopoverParams();
      });
    });
    this.resizeObserver.observe(document.querySelector('.ao-popup'));
  },

  destroyed() {
    window.removeEventListener('resize', this.throttledRecalculateParentParams);
    window.removeEventListener('resize', this.throttledRecalculatePopoverParams);
    window.removeEventListener('click', this.selfClose);

    if (this.scrollableAreaSelector) {
      /* eslint-disable no-undef */
      $(this.scrollableAreaSelector).off('scroll', this.throttledRecalculateParentParams);
      $(this.scrollableAreaSelector).off('scroll', this.throttledRecalculatePopoverParams);
      /* eslint-enable no-undef */
    } else {
      window.removeEventListener('scroll', this.throttledRecalculateParentParams);
      window.removeEventListener('scroll', this.throttledRecalculatePopoverParams);
    }

    this.resizeObserver.disconnect();
    this.resizeObserver = null;
  },

  watch: {
    parent(newValue) {
      this.parentEl = newValue;
    },
    parentEl(newValue) {
      this.parentParams = this.getOffset(newValue);
    },
    popupParams() {
      this.dynamicChangeVerticalPosition();
      this.dynamicChangeHorizontalPosition();
    },
  },

  methods: {
    getOffset(el) {
      const rect = el.getBoundingClientRect();

      return {
        left: rect.left,
        right: rect.right,
        top: rect.top,
        bottom: rect.bottom,
        height: rect.height,
        width: rect.width,
      };
    },
    recalculateParentParams() {
      this.parentParams = this.getOffset(this.parent);
    },
    recalculatePopoverParams() {
      this.popupParams = this.getOffset(this.$refs.popup);
    },
    selfClose() {
      this.$emit('onClose');
    },
    dynamicChangeVerticalPosition() {
      const height = window.innerHeight;
      const hypotheticallyTop = this.parentParams.top - this.popupParams.height - 5;
      const hypotheticallyBottom = this.parentParams.bottom + this.popupParams.height + 5;

      switch (this.yPosition) {
        case 'top':
          this.position.y = hypotheticallyTop < 0 ? 'bottom' : 'top';
          break;

        case 'bottom':
          this.position.y = hypotheticallyBottom > height ? 'top' : 'bottom';
          break;

        default:
          break;
      }
    },
    dynamicChangeHorizontalPosition() {
      const width = window.innerWidth;
      const hypotheticallyRight = this.parentParams.left + this.popupParams.width + 5;
      const hypotheticallyLeft = this.parentParams.right - this.popupParams.width - 5;

      switch (this.xPosition) {
        case 'left':
          this.position.x = hypotheticallyLeft < 0 ? 'right' : 'left';
          break;

        case 'right':
          this.position.x = hypotheticallyRight > width ? 'left' : 'right';
          break;

        default:
          break;
      }
    },
  },
};
</script>

<style scoped>
.ao-popup {
  background-color: #ffffff;
  position: fixed;
  z-index: 999;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 10px #A0A0AA33;
}

.ao-popup__header, .ao-popup__footer {
  padding: 12px;
  padding-left: 16px;
  padding-top: 16px;
}

.ao-popup__main {
  padding: 0 16px;
}
</style>
