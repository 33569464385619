<template>
  <div class="chart-container">
    <canvas
      id="my-chart"
      style="width:100%; height:100%; max-height:100%; max-width:100%;"
      :style="`background-color: ${this.data.canvas.backgroundColor}; border-radius: ${this.data.canvas.borderRadius}px`"
    />
  </div>
</template>

<script>
const Chart = require('chart.js');

module.exports = {
  name: 'MyChart',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  data() {
    return {
      chart: {},
      options: {
        responsive: true,
        responsiveAnimationDuration: 50,
        maintainAspectRatio: true,
        onResize: this.onResize,
        tooltips: {
          mode: 'x',
          position: 'nearest',
        },
        scales: this.data.scales,
        legend: this.data.legend,
        title: this.data.title,
        canvas: this.data.canvas,
        layout: this.data.layout,
      },
    };
  },

  mounted() {
    const ctx = document.getElementById('my-chart');
    const chartData = {
      type: 'bar',
      data: this.data,
      options: this.options,
    };
    // eslint-disable-next-line no-new
    this.chart = new Chart(ctx, chartData);
  },

  methods: {
    onResize() {
      console.log('resized');
      this.chart.render();
    },
    update() {
      this.chart.update();
    },
    updateScales() {
      this.chart.options.scales = this.data.scales;
      this.chart.update();
    },
    updateLegend() {
      this.chart.options.legend = this.data.legend;
      this.chart.update();
    },
    updateTitle() {
      this.chart.options.title = this.data.title;
      this.chart.update();
    },
    updateLayout() {
      this.chart.options.layout = this.data.layout;
      this.chart.update();
    },
    updateCanvas() {
      this.chart.options.canvas = this.data.canvas;
      this.chart.update();
    },
  },
};
</script>

<style scoped>
.chart-container {
  position: relative;
  height: 48vh;
  width: 95vw;
}
</style>
