<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <autoorder-modal
    class="config-delete-modal"
    modalTitle="Удалить сохранённый фильтр?"
    :closable="false"
    :modalVisible="isVisible"
    @onClose="handleDiscard"
  >
    <template #main>
      <p class="config-delete-modal__text">
        {{ configName }} будет удалён. Удалённый сохранённый фильтр нельзя будет восстановить.
      </p>
    </template>

    <template #footer>
      <div class="config-delete-modal__buttons-row">
        <ao-button
          type="secondary"
          :disabled="isLoading"
          @click="handleDiscard"
        >
          <l10n value="Отменить" />
        </ao-button>
        <ao-button
          :disabled="isLoading"
          @click="deleteConfig"
        >
          <l10n value="Удалить" />
        </ao-button>
      </div>
    </template>
  </autoorder-modal>
</template>

<script>
const AutoorderModal = require('../autoorder-modal.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const AoInput = require('../shared/ao-input.vue').default;

module.exports = {
  components: {
    AutoorderModal,
    AoButton,
    AoInput,
  },

  model: {
    prop: 'isVisible',
    event: 'onVisibleChange',
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    config: {
      type: Object,
      required: false,
      default: () => {},
    },
    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tryToSend: false,
      isLoading: false,
      filterName: '',
    };
  },

  computed: {
    configName() {
      return this.config?.name ?? 'Сохраненный конфиг';
    },
    configId() {
      return this.config?.id ?? null;
    },
  },

  methods: {
    handleDiscard() {
      this.$emit('onVisibleChange', false);
    },
    async deleteConfig() {
      if (!this.configId) {
        this.createNotification('Не выбран id для удаления', 'error');
        return;
      }

      try {
        this.isLoading = true;
        const url = `${this.endpoint}${this.configId}/`;
        const options = {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json;charset=utf-8',
          },
        };

        const response = await fetch(url, options);

        if (response.ok) {
          this.$emit('onConfigSuccessfulDeleted', this.configId);
          this.createNotification(`Фильтр id - ${this.configId} успешно удалён`, 'success');
          this.$emit('onVisibleChange', false);
        } else {
          this.createNotification('Произошла ошибка при удалении, попробуйте позже', 'error');
        }
      } catch (error) {
        this.createNotification(error.message, 'error');
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
  .config-delete-modal >>> .custom-modal {
    min-width: 420px;
    width: 30%;
  }

  .config-delete-modal__text{
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    overflow: hidden;
    text-wrap: wrap;
    text-overflow: ellipsis;
  }

  .config-delete-modal__buttons-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
