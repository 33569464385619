<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="gp-config">

    <template v-if="!config">
      Loading...
    </template>

    <template v-else>
      <div class="gp-config-actions">
        <button
          type="button"
          class="btn btn-sm btn-primary"
          :disabled="!somethingEdited"
          @click="submitChanges"
        >
          <l10n value="Save changes" />
        </button>

        <button
          type="button"
          class="btn btn-sm btn-secondary"
          :disabled="!somethingEdited"
          @click="discardChanges"
        >
          <l10n value="Discard changes" />
        </button>

        <button
          type="button"
          class="btn btn-sm btn-secondary"
          :disabled="somethingDeleted"
          @click="purgeDeleted"
        >
          <l10n value="Purge deleted" />
        </button>

        <my-search v-model="searchString" />
      </div>

      <h2>
        <l10n value="Settings" />
      </h2>
      <div class="form-group form-group-config-settings">
        <label for="devConfigLocale">
          <l10n value="Locale" />
          <input id="devConfigLocale" v-model="config.locale" class="form-control" />
        </label>
      </div>
      <div class="form-group form-group-config-settings">
        <label for="devConfigCurrency">
          <l10n value="Currency" />
          <input id="devConfigCurrency" v-model="config.currency" class="form-control" />
        </label>
      </div>

      <h2>
        <l10n value="Attributes" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="name" /></th>
            <th><l10n value="calc" /></th>
            <th><l10n value="aggregation" /></th>
            <th><l10n value="title" /></th>
            <th><l10n value="format" /></th>
            <th><l10n value="style" /></th>
            <th><l10n value="columnClass" /></th>
            <th><l10n value="rowClass" /></th>
            <th><l10n value="editable" /></th>
            <th><l10n value="keys" /></th>
            <th><l10n value="stream" /></th>
            <th><l10n value="position" /></th>
            <th><l10n value="actionable" /></th>
            <th><l10n value="actionicon" /></th>
            <th><l10n value="actionlink" /></th>
            <th><l10n value="action" /></th>
            <th><l10n value="options" /></th>
            <th><l10n value="description" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="attribute, i in config.attributes" v-if="isVisible(attribute)">
            <td :class="{ changed: attribute.id !== attribute._.id }">
              <span>{{attribute.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.id" />
            </td>
            <td :class="{ changed: attribute.name !== attribute._.name }">
              <span>{{attribute.name}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.name" />
            </td>
            <td
              :class="{
                changed: attribute.calc !== attribute._.calc,
                unknown: !isFormulaKnown(attribute.calc),
              }">
              <span>{{attribute.calc}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.calc" />
            </td>
            <td :class="{ changed: attribute.aggregation !== attribute._.aggregation }">
              <span>{{attribute.aggregation}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.aggregation" />
            </td>
            <td :class="{ changed: attribute.title !== attribute._.title }">
              <span>{{attribute.title}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.title" />
            </td>
            <td :class="{ changed: attribute.format !== attribute._.format }">
              <span>{{attribute.format}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.format" />
            </td>
            <td :class="{ changed: attribute.style !== attribute._.style }">
              <span>{{attribute.style}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.style" />
            </td>
            <td :class="{ changed: attribute.columnClass !== attribute._.columnClass }">
              <span>{{attribute.columnClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.columnClass" />
            </td>
            <td :class="{ changed: attribute.rowClass !== attribute._.rowClass }">
              <span>{{attribute.rowClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.rowClass" />
            </td>
            <td :class="{ changed: attribute.editable !== attribute._.editable }">
              <span>{{attribute.editable}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.editable" />
            </td>
            <td :class="{ changed: attribute.keys !== attribute._.keys }">
              <span>{{attribute.keys}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.keys" />
            </td>
            <td :class="{ changed: attribute.stream !== attribute._.stream }">
              <span>{{attribute.stream}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.stream" />
            </td>
            <td :class="{ changed: attribute.position !== attribute._.position }">
              <span>{{attribute.position}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.position" />
            </td>
            <td :class="{ changed: attribute.actionable !== attribute._.actionable }">
              <span>{{attribute.actionable}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.actionable" />
            </td>
            <td :class="{ changed: attribute.actionicon !== attribute._.actionicon }">
              <span>{{attribute.actionicon}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.actionicon" />
            </td>
            <td :class="{ changed: attribute.actionlink !== attribute._.actionlink }">
              <span>{{attribute.actionlink}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.actionlink" />
            </td>
            <td :class="{ changed: attribute.action !== attribute._.action }">
              <span>{{attribute.action}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.action" />
            </td>
            <td :class="{ changed: attribute.options !== attribute._.options }">
              <span>{{attribute.options}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.options" />
            </td>
            <td :class="{ changed: attribute.description !== attribute._.description }">
              <span>{{attribute.description}}</span>
              <textarea @keydown="handleKeyDown" v-model="attribute.description" />
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="$set(attribute, 'deleted', true)">
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('attributes')"
      >
        <l10n value="Add row" />
      </button>

      <h2>
        <l10n value="Metrics" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="name" /></th>
            <th><l10n value="formula" /></th>
            <th><l10n value="timeframe" /></th>
            <th><l10n value="title" /></th>
            <th><l10n value="format" /></th>
            <th><l10n value="style" /></th>
            <th><l10n value="columnClass" /></th>
            <th><l10n value="rowClass" /></th>
            <th><l10n value="editable" /></th>
            <th><l10n value="keys" /></th>
            <th><l10n value="stream" /></th>
            <th><l10n value="editValue" /></th>
            <th><l10n value="position" /></th>
            <th><l10n value="actionable" /></th>
            <th><l10n value="actionicon" /></th>
            <th><l10n value="actionlink" /></th>
            <th><l10n value="action" /></th>
            <th><l10n value="options" /></th>
            <th><l10n value="override" /></th>
            <th><l10n value="description" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="metric, i in config.metrics" v-if="isVisible(metric)">
            <td :class="{ changed: metric.id !== metric._.id }">
              <span>{{metric.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.id" />
            </td>
            <td :class="{ changed: metric.name !== metric._.name }">
              <span>{{metric.name}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.name" />
            </td>
            <td
              :class="{
                changed: metric.formula !== metric._.formula,
                unknown: !isFormulaKnown(metric.formula),
              }"
            >
              <span>{{metric.formula}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.formula" />
            </td>
            <td :class="{ changed: metric.timeframe !== metric._.timeframe }">
              <span>{{metric.timeframe}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.timeframe" />
            </td>
            <td :class="{ changed: metric.title !== metric._.title }">
              <span>{{metric.title}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.title" />
            </td>
            <td :class="{ changed: metric.format !== metric._.format }">
              <span>{{metric.format}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.format" />
            </td>
            <td :class="{ changed: metric.style !== metric._.style }">
              <span>{{metric.style}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.style" />
            </td>
            <td :class="{ changed: metric.columnClass !== metric._.columnClass }">
              <span>{{metric.columnClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.columnClass" />
            </td>
            <td :class="{ changed: metric.rowClass !== metric._.rowClass }">
              <span>{{metric.rowClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.rowClass" />
            </td>
            <td :class="{ changed: metric.editable !== metric._.editable }">
              <span>{{metric.editable}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.editable" />
            </td>
            <td :class="{ changed: metric.keys !== metric._.keys }">
              <span>{{metric.keys}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.keys" />
            </td>
            <td :class="{ changed: metric.stream !== metric._.stream }">
              <span>{{metric.stream}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.stream" />
            </td>
            <td :class="{ changed: metric.editValue !== metric._.editValue }">
              <span>{{metric.editValue}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.editValue" />
            </td>
            <td :class="{ changed: metric.position !== metric._.position }">
              <span>{{metric.position}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.position" />
            </td>
            <td :class="{ changed: metric.actionable !== metric._.actionable }">
              <span>{{metric.actionable}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.actionable" />
            </td>
            <td :class="{ changed: metric.actionicon !== metric._.actionicon }">
              <span>{{metric.actionicon}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.actionicon" />
            </td>
            <td :class="{ changed: metric.actionlink !== metric._.actionlink }">
              <span>{{metric.actionlink}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.actionlink" />
            </td>
            <td :class="{ changed: metric.action !== metric._.action }">
              <span>{{metric.action}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.action" />
            </td>
            <td :class="{ changed: metric.options !== metric._.options }">
              <span>{{metric.options}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.options" />
            </td>
            <td :class="{ changed: metric.override !== metric._.override }">
              <span>{{metric.override}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.override" />
            </td>
            <td :class="{ changed: metric.description !== metric._.description }">
              <span>{{metric.description}}</span>
              <textarea @keydown="handleKeyDown" v-model="metric.description" />
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="$set(metric, 'deleted', true)"
              >
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('metrics')"
      >
        <l10n value="Add row" />
      </button>

      <h2>
        <l10n value="Calc columns" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="name" /></th>
            <th><l10n value="calc" /></th>
            <th><l10n value="aggregation" /></th>
            <th><l10n value="title" /></th>
            <th><l10n value="format" /></th>
            <th><l10n value="style" /></th>
            <th><l10n value="columnClass" /></th>
            <th><l10n value="rowClass" /></th>
            <th><l10n value="actionable" /></th>
            <th><l10n value="actionicon" /></th>
            <th><l10n value="actionlink" /></th>
            <th><l10n value="action" /></th>
            <th><l10n value="options" /></th>
            <th><l10n value="override" /></th>
            <th><l10n value="description" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="calc_column, i in config.calc_columns" v-if="isVisible(calc_column)">
            <td :class="{ changed: calc_column.id !== calc_column._.id }">
              <span>{{calc_column.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.id" />
            </td>
            <td :class="{ changed: calc_column.name !== calc_column._.name }">
              <span>{{calc_column.name}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.name" />
            </td>
            <td :class="{ changed: calc_column.calc !== calc_column._.calc }">
              <span>{{calc_column.calc}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.calc" />
            </td>
            <td :class="{ changed: calc_column.aggregation !== calc_column._.aggregation }">
              <span>{{calc_column.aggregation}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.aggregation" />
            </td>
            <td :class="{ changed: calc_column.title !== calc_column._.title }">
              <span>{{calc_column.title}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.title" />
            </td>
            <td :class="{ changed: calc_column.format !== calc_column._.format }">
              <span>{{calc_column.format}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.format" />
            </td>
            <td :class="{ changed: calc_column.style !== calc_column._.style }">
              <span>{{calc_column.style}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.style" />
            </td>
            <td :class="{ changed: calc_column.columnClass !== calc_column._.columnClass }">
              <span>{{calc_column.columnClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.columnClass" />
            </td>
            <td :class="{ changed: calc_column.rowClass !== calc_column._.rowClass }">
              <span>{{calc_column.rowClass}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.rowClass" />
            </td>
            <td :class="{ changed: calc_column.actionable !== calc_column._.actionable }">
              <span>{{calc_column.actionable}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.actionable" />
            </td>
            <td :class="{ changed: calc_column.actionicon !== calc_column._.actionicon }">
              <span>{{calc_column.actionicon}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.actionicon" />
            </td>
            <td :class="{ changed: calc_column.actionlink !== calc_column._.actionlink }">
              <span>{{calc_column.actionlink}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.actionlink" />
            </td>
            <td :class="{ changed: calc_column.action !== calc_column._.action }"><span>{{calc_column.action}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.action" />
            </td>
            <td :class="{ changed: calc_column.options !== calc_column._.options }"><span>{{calc_column.options}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.options" />
            </td>
            <td :class="{ changed: calc_column.override !== calc_column._.override }">
              <span>{{calc_column.override}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.override" />
            </td>
            <td :class="{ changed: calc_column.description !== calc_column._.description }">
              <span>{{calc_column.description}}</span>
              <textarea @keydown="handleKeyDown" v-model="calc_column.description" />
            </td>
            <td>
              <a href="javascript:void(0)" @click="$set(calc_column, 'deleted', true)">
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('calc_columns')"
      >
        <l10n value="Add row" />
      </button>
      <h2>
        <l10n value="Formulas" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="calc" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="formula, i in config.formulas" v-if="isVisible(formula)">
            <td :class="{ changed: formula.id !== formula._.id }">
              <span>{{formula.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="formula.id" />
            </td>
            <td
              :class="{
                changed: formula.calc !== formula._.calc,
                unknown: !isFormulaKnown(formula.calc),
              }"
            >
              <span>{{formula.calc}}</span>
              <textarea @keydown="handleKeyDown" v-model="formula.calc" />
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="$set(formula, 'deleted', true)"
              >
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('formulas')"
      >
        <l10n value="Add row" />
      </button>

      <h2>
        <l10n value="Timeframes" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="name" /></th>
            <th><l10n value="group" /></th>
            <th><l10n value="calc" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="timeframe, i in config.timeframes" v-if="isVisible(timeframe)">
            <td :class="{ changed: timeframe.id !== timeframe._.id }">
              <span>{{timeframe.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="timeframe.id" />
            </td>
            <td :class="{ changed: timeframe.name !== timeframe._.name }">
              <span>{{timeframe.name}}</span>
              <textarea @keydown="handleKeyDown" v-model="timeframe.name" />
            </td>
            <td :class="{ changed: timeframe.group !== timeframe._.group }">
              <span>{{timeframe.group}}</span>
              <textarea @keydown="handleKeyDown" v-model="timeframe.group" />
            </td>
            <td :class="{ changed: timeframe.calc !== timeframe._.calc }">
              <span>{{timeframe.calc}}</span>
              <textarea @keydown="handleKeyDown" v-model="timeframe.calc" />
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="$set(timeframe, 'deleted', true)"
              >
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('timeframes')"
      >
        <l10n value="Add row" />
      </button>

      <h2>
        <l10n value="Formats" />
      </h2>
      <table class="table table-sm table-hover table-striped">
        <thead>
          <tr>
            <th><l10n value="id" /></th>
            <th><l10n value="calc" /></th>
            <th />
          </tr>
        </thead>
        <tbody>
          <tr v-for="format, i in config.formats" v-if="isVisible(format)">
            <td :class="{ changed: format.id !== format._.id }">
              <span>{{format.id}}</span>
              <textarea @keydown="handleKeyDown" v-model="format.id" />
            </td>
            <td :class="{ changed: format.calc !== format._.calc }">
              <span>{{format.calc}}</span>
              <textarea @keydown="handleKeyDown" v-model="format.calc" />
            </td>
            <td>
              <a
                href="javascript:void(0)"
                @click="$set(format, 'deleted', true)"
              >
                <l10n value="delete" />
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <button
        type="button"
        class="btn btn-sm btn-secondary"
        @click="addRow('formats')">
        <l10n value="Add row" />
      </button>
    </template>
  </div>
</template>
<script>
const utils = require('../my-utils');

module.exports = {
  data() {
    return {
      l10n: utils.l10n,
      config: null,
      searchString: '',
      destroyed: false,
    };
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    this.destroyed = true;
  },
  computed: {
    searchRegex() {
      if (this.searchString !== '') {
        try {
          return new RegExp(this.searchString, 'i');
        } catch (ex) {
          return null;
        }
      }
      return null;
    },
    somethingEdited() {
      const changed = (xs) => _.some(xs, (x) => !_.isEqual(_.omit(x, '_'), x._));
      return (
        this.config.locale !== this.config._?.locale
        || this.config.currency !== this.config._?.currency
        || changed(this.config.attributes)
        || changed(this.config.metrics)
        || changed(this.config.calc_columns)
        || changed(this.config.formulas)
        || changed(this.config.timeframes)
        || changed(this.config.formats));
    },
    somethingDeleted() {
      return !this.config.attributes.find((x) => x.deleted)
        && !this.config.metrics.find((x) => x.deleted)
        && !this.config.formulas.find((x) => x.deleted)
        && !this.config.formats.find((x) => x.deleted)
        && !this.config.timeframes.find((x) => x.deleted);
    },
    knownFormulas() {
      return new Set(_(this.config.formulas)
        .filter((formula) => !formula.deleted)
        .map((formula) => formula.id)
        .concat([
          'abs',
          'min',
          'max',
          'avg',
          'sum',
          'mix',
          'any',
          'all',
          'first',
          'last',
          'if',
          'else',
          'date',
          'time',
          'datetime',
          'isnull',
          'true',
          'false',
          'forecast',
          'season',
          'prefix',
          'cast',
          'string',
        ])
        .concat([
          'in_date_range',
          'at_end_date',
          'at_start_date',
          'at_date_after_end',
          'at_date_before_start',
          'at_reference_date',
          'start_date',
          'end_date',
          'date_before_start',
          'date_after_end',
          'reference_date',
        ])
        .concat([
          'class',
          'condition',
          'cost',
          'date',
          'discount1',
          'discount2',
          'intransit_units',
          'inventory_units',
          'item',
          'markdown',
          'price',
          'promo_price',
          'promo_type',
          'region',
          'return_cost',
          'return_units',
          'return_value',
          'sales_cost',
          'sales_units',
          'sales_value',
          'store',
          'wasted_units',
          'wasted_value',
          'zone_price',
          'vat',
        ])
        .value());
    },
  },
  methods: {
    isFormulaKnown(formula) {
      return formula && _.every(
        [...formula.matchAll(/[a-zA-Z_][.a-zA-Z_0-9]*/g)],
        ([symbol]) => symbol.indexOf('.') !== -1
        || this.knownFormulas.has(symbol),
      );
    },
    isVisible(entry) {
      if (entry.deleted) {
        return false;
      }
      return !this.searchString
      || _.isEmpty(entry._)
      || _(entry)
        .values()
        .some(
          (value) => _.isString(value)
                  && (this.searchRegex
                    ? value.match(this.searchRegex)
                    : value.indexOf(this.searchString) !== -1),
        ) || _(entry._)
        .values()
        .some(
          (value) => _.isString(value)
          && (this.searchRegex
            ? value.match(this.searchRegex)
            : value.indexOf(this.searchString) !== -1),
        );
    },
    handleKeyDown(e) {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault();
        e.stopPropagation();
        e.target.blur();
      }
    },
    addRow(family) {
      if (!this.config[family]) {
        Vue.set(this.config, family, []);
      }
      if (family === 'attributes') {
        this.config[family].push({ id: utils.randomId(), name: '', calc: '', _: {} });
      } else if (family === 'metrics') {
        this.config[family].push({ id: utils.randomId(), name: '', formula: '', _: {} });
      } else if (family === 'calc_columns') {
        this.config[family].push({ id: utils.randomId(), name: '', _: {} });
      } else {
        this.config[family].push({ id: '', calc: '', _: {} });
      }
    },
    async submitChanges() {
      const arrayToObject = (array) => {
        if (!array) {
          return {};
        }

        const object = {};
        for (let entry of array) {
          if (entry.id && entry.name) {
            object[entry.id] = { ...entry };
          }
        }
        return object;
      };

      const dropOriginals = (entries) => {
        if (entries) {
          entries?.forEach((entry) => {
            delete entry._;
          });
        }
      };

      const config = _.cloneDeep(this.config);

      dropOriginals(config.attributes);
      dropOriginals(config.metrics);
      dropOriginals(config.calc_columns);
      dropOriginals(config.formulas);
      dropOriginals(config.formats);
      dropOriginals(config.timeframes);

      config.attributes = arrayToObject(config.attributes);
      config.metrics = arrayToObject(config.metrics);
      config.calc_columns = arrayToObject(config.calc_columns);
      config.formats = config.formats.reduce((acc, x) => {
        acc[x.id] = x.calc; return acc;
      }, {});
      config.formulas = config.formulas.reduce((acc, x) => {
        acc[x.id] = x.calc; return acc;
      }, {});
      config.timeframes = arrayToObject(config.timeframes);

      let fixFlag = (entry, field) => {
        if (entry[field] === '') {
          delete entry[field];
        } else if (entry[field] === 'true') {
          entry[field] = true;
        } else if (entry[field] === 'false') {
          entry[field] = false;
        }
      };

      const dropEmptyValues = (entry) => {
        for (let key of Object.keys(entry)) {
          if (entry[key] === '') {
            delete entry[key];
          }
        }
      };

      for (let [_, attribute] of Object.entries(config.attributes)) {
        fixFlag(attribute, 'editable');
        fixFlag(attribute, 'actionable');

        if (attribute.editable && !attribute.stream) {
          delete attribute.editable;
        }

        if (!attribute.editable && attribute.stream) {
          attribute.editable = true;
        }

        if (attribute.actionable && !attribute.actionlink && !attribute.actionicon) {
          delete attribute.actionable;
        }

        if (!attribute.actionable && (attribute.actionlink || attribute.actionicon)) {
          attribute.actionable = true;
        }

        dropEmptyValues(attribute);
        if (attribute.keys) {
          attribute.keys = attribute.keys.split(/[\s,]+/g);
        }
        if (attribute.position) {
          attribute.position = parseInt(attribute.position);
        }
      }

      for (let [_, metric] of Object.entries(config.metrics)) {
        fixFlag(metric, 'editable');
        fixFlag(metric, 'actionable');

        if (metric.editable && !metric.stream) {
          delete metric.editable;
        }

        if (!metric.editable && metric.stream) {
          metric.editable = true;
        }

        if (metric.actionable && !metric.actionlink && !metric.actionicon) {
          delete metric.actionable;
        }

        if (!metric.actionable && (metric.actionlink || metric.actionicon)) {
          metric.actionable = true;
        }

        dropEmptyValues(metric);
        if (metric.keys) {
          metric.keys = metric.keys.split(/[\s,]+/g);
        }
        if (metric.position) {
          metric.position = parseInt(metric.position);
        }
      }

      for (let [_, calc_column] of Object.entries(config.calc_columns)) {
        dropEmptyValues(calc_column);
      }

      await Promise
        .resolve($.ajax({
          url: '/storage/_/config',
          method: 'PUT',
          data: JSON.stringify(config),
          contentType: 'application/json',
        }));

      await this.update();
    },
    discardChanges() {
      this.update();
    },
    purgeDeleted() {
      for (let type of ['attributes', 'metrics', 'formulas', 'formats', 'timeframes']) {
        this.$set(this.config, type, this.config[type].filter(x => !x.deleted));
      }
    },
    formatKeys(keys) {
      return keys ? keys.join(', ') : null;
    },
    update() {
      return Promise
        .resolve($.ajax({ url: '/storage/_/config' }))
        .then((config) => {
          const objectToArray = (object) => {
            if (!object) {
              return [];
            }
            let array = [];
            for (let id of Object.keys(object)) {
              let entry = object[id];
              if (_.isString(entry)) {
                entry = { id, calc: entry };
              } else {
                entry.id = id;
              }
              array.push(entry);
            }
            return array;
          };

          config.attributes = objectToArray(config.attributes);
          config.metrics = objectToArray(config.metrics);
          config.calc_columns = objectToArray(config.calc_columns);
          config.formats = objectToArray(config.formats);
          config.formulas = objectToArray(config.formulas);
          config.timeframes = objectToArray(config.timeframes);

          for (let attribute of config.attributes) {
            if (attribute.keys) {
              attribute.keys = attribute.keys.join(', ');
            }
            if (attribute.position) {
              attribute.position = `${attribute.position}`;
            }
          }
          for (let metric of config.metrics) {
            if (metric.keys) {
              metric.keys = metric.keys.join(', ');
            }
            if (metric.position) {
              metric.position = `${metric.position}`;
            }
          }

          let saveOriginals = (entries) => {
            if (entries) {
              for (let entry of entries) {
                entry._ = _.clone(entry);
              }
            }
          };
          saveOriginals(config.attributes);
          saveOriginals(config.metrics);
          saveOriginals(config.calc_columns);
          saveOriginals(config.formulas);
          saveOriginals(config.formats);
          saveOriginals(config.timeframes);
          config._ = _.pick(config, ['locale', 'currency']);
          this.config = config;
        });
    },
  },
};
</script>
<style>
.gp-config-actions {
    top: 0;
    position: sticky;
    background-color: white;
    padding: 10px 20px;
    margin: -10px -20px;
    z-index: 4;
}
.gp-config-actions button + button {
    margin-left: 10px;
}
.gp-config h2 {
    z-index: 3;
    position: absolute;
    color: white;
    margin: 10px;
    font-size: 2em;
}
.gp-config .table {
    width: auto;
    font-size: 0.9em;
}
.gp-config .table thead th {
    position: sticky;
    top: 0;
    padding-top: 50px;
    z-index: 2;
    color: white;
    background-color: var(--cyan);
    font-weight: normal;
}
.gp-config .table .changed span {
    font-weight: bold;
}
.gp-config .table .changed:after {
    content: "";
    position: absolute;
    width: 2px;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--orange);
}
.gp-config .table thead th:after {
    content: "";
    background-color: var(--cyan);
    bottom: 0;
    left: 0;
    right: 0;
    height: 2px;
    position: absolute;
}
.gp-config .table td {
    position: relative;
    line-height: 1.4;
}
.gp-config .table td {
    padding: 4px 4px;
    max-width: 300px;
    white-space: pre-wrap;
}
.gp-config .table td span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    min-width: 50px;
    max-width: 400px;
}
.gp-config .table td textarea {
    border-color: transparent;
    background-color: transparent;
    position: absolute;
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    padding: 4px 4px;
    width: calc(100% + 2px);
    background-color: white;
    z-index: 1;
    opacity: 0;
    resize: none;
}
.gp-config .table td textarea:focus {
    opacity: 1;
}
.my-dark-theme .gp-config-actions {
    background-color: #222;
}
.gp-config-actions {
    display: flex;
}
.gp-config-actions > * {
    margin-left: 0!important;
    margin-right: 10px!important;
}
.gp-config-actions input {
    width: auto;
    height: 29px;
    line-height: 27px;
}
.gp-config .table td textarea {
    width: 100%;
    height: 100%;
    padding: 4px 4px;
    border: none;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
.gp-config .table + .btn {
    margin-top: -0px;
    margin-bottom: 20px;
}
.gp-config h2 {
    margin-bottom: 10px!important;
}
.gp-config-actions + h2 {
    position: initial;
    width: 20%;
    background: var(--cyan);
    margin: 10px 0;
    padding: 10px 10px;
}
.alert .feather-icon svg {
    margin: -1px 0;
    margin-right: 5px;
    display: inline-block;
    vertical-align: top;
}
.gp-config .unknown {
    color: var(--red);
}
.gp-config input[type="search"] {
    flex-grow: 1;
}
.form-group-config-settings {
  width: 20%;
}
.form-group-config-settings label {
  width: 100%;
}
</style>
