<template>
  <div class="gp-rules-schedule">
    <template v-if="isConfigSchedulesVisible">
      <gp-data
        id="gp-rules-categories"
        v-model="categoriesReport"
        :stream="stream"
        :source="source"
        :groups="['gp-rules-categories', 'reference-date']"
        :filter0="filter0"
        :filter1="filter1"
        :filter2="filter2"
        filter3="dim1 != ''"
        :dims="['classif.category']"
        :initial-sort="[1]"
        :instant="false"
      />

      <ul class="gp-runs-schedule">
        <li
          v-for="scheduledRun, scheduledRunId in configSchedule"
          :key="scheduledRunId"
          :class="{ expanded: expandedScheduledRuns[scheduledRunId] }"
        >
          <span
            class="gp-rules-schedule__btn"
            @click="toggleExpandedSchedule(scheduledRunId)"
          >
            <feather-icon :name="expandedScheduledRuns[scheduledRunId] ? 'chevron-down' : 'chevron-right'" />

            <l10n
              value="Run optimization on {days} at {time} for {categories}."
              :days="scheduleDaysText(scheduledRun.days, scheduledRun.dows)"
              :time="scheduleTimeText(scheduledRun.time)"
              :categories="scheduleCategoriesText(scheduledRun.categories)"
            />

            <l10n
              v-if="scheduledRun.combineCategories"
              value="Optimize all categories at once."
            />

            <l10n
              v-if="scheduledRun.autoAccept"
              value="Automatically accept recommendations if price change is within {left} to {right} range."
              :left="autoAcceptLeftText(scheduledRun.autoAcceptBoundsLeft)"
              :right="autoAcceptRightText(scheduledRun.autoAcceptBoundsRight)"
            />

            <l10n
              v-if="scheduledRun.autoAcceptExcludeStrictViolations"
              value="Do not accept if strict rules are violated"
            />
          </span>

          <template v-if="expandedScheduledRuns[scheduledRunId]">
            <div class="form-group">
              <label>
                <l10n value="Run at:" />
              </label>

              <input
                v-model="scheduledRun.time"
                type="time"
                class="form-control form-control-sm"
              >
            </div>

            <div class="form-group">
              <label>
                <l10n value="Run on:" />
              </label>
              <div
                v-if="!scheduledRun.days.length"
                class="btn-group-toggle"
              >
                <label
                  v-for="dow in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                  :key="dow"
                  :class="{
                    'btn btn-sm btn-secondary': true,
                    active: scheduledRun.dows.indexOf(dow) !== -1,
                  }"
                >
                  <input
                    type="checkbox"
                    :checked="scheduledRun.dows.indexOf(dow) !== -1"
                    @change="$event.target.checked
                      ? scheduledRun.dows.push(dow)
                      : scheduledRun.dows.splice(scheduledRun.dows.indexOf(dow), 1)
                    "
                  >

                  <l10n :value="dow" />
                </label>
              </div>

              <v-calendar
                v-if="!scheduledRun.dows.length"
                :locale="locale"
                :is-dark="darkTheme"
                is-expanded
                trim-weeks
                title-position="left"
                :attributes="calendarAttributes(scheduledRun.days)"
                @dayclick="scheduledRun.days.indexOf($event.id) >= 0
                  ? scheduledRun.days.splice(scheduledRun.days.indexOf($event.id), 1)
                  : scheduledRun.days.push($event.id)
                "
              />
            </div>
            <div class="form-group">
              <label>
                <l10n value="Run for:" />
              </label>

              <gp-pills
                v-model="scheduledRun.categories"
                :options="knownCategories"
                :placeholder="pillsPlaceholder"
                recent-options-key="recentCategories"
              />

              <gp-filter
                v-model="scheduledRun.filter"
                :stream="stream"
                :source="source"
                :attributes="attributes"
                :formulas="formulas"
                :groups="['gp-rules-filter', 'reference-date']"
                :vars="vars"
              />
            </div>

            <div class="form-group">
              <gp-check v-model="scheduledRun.combineCategories">
                <l10n value="Optimize all categories at once" />
              </gp-check>

              <gp-check v-model="scheduledRun.autoAccept">
                <l10n value="Automatically accept recommendations" />
              </gp-check>

              <div
                v-if="scheduledRun.autoAccept"
                class="gp-rules-auto-accept-bounds"
              >
                <l10n value="if price change is within" />

                <input
                  v-model="scheduledRun.autoAcceptBoundsLeft"
                  class="form-control form-control-sm"
                  type="number"
                  step="0.1"
                >

                <span>%</span>

                <input
                  v-model="scheduledRun.autoAcceptBoundsRight"
                  class="form-control form-control-sm"
                  type="number"
                  step="0.1"
                >

                <span>%</span>
              </div>
            </div>

            <div class="form-group">
              <a
                href="javascript:void(0)"
                @click="deleteScheduledRun(scheduledRunId)"
              >
                <l10n value="Delete this scheduled optimization run" />
              </a>
            </div>
          </template>
        </li>
      </ul>
    </template>

    <div class="form-group">
      <span
        class="gp-rules-schedule__btn"
        @click="addScheduledRun"
      >
        <feather-icon name="calendar" />
        <l10n value="Schedule optimization run" />
      </span>
    </div>
  </div>
</template>

<script>
const utils = require('../../my-utils');

module.exports = {
  mixins: [
    utils.extraFilters,
    utils.configHelpers,
    utils.referenceDateHelper,
  ],

  props: {
    // ???
    stream: { type: String, default: 'default' },
    source: { type: Object },
    filter0: { type: String },
    filter1: { type: String },
    filter2: { type: String },
    vars: { type: Object, default: () => ({}) },

    configSchedule: {
      type: Object,
      default: () => ({}),
    },

    defaultScheduledRun: {
      type: Object,
      default: () => ({
        days: [],
        dows: [],
        time: null,
        categories: [],
      }),
    },
  },

  data() {
    return {
      expandedScheduledRuns: {},
      categoriesReport: null,
      darkTheme: localStorage.darkTheme === 'true',
    };
  },

  computed: {
    isConfigSchedulesVisible() {
      return Object.keys(this.configSchedule).length;
    },

    pillsPlaceholder() {
      return utils.l10n('Categories');
    },

    knownCategories() {
      return _(this.categoriesReport?.rows)
        .groupBy('0')
        .toPairs()
        .map(([name, rows]) => ({
          name,
          classes: _.map(rows, '1'),
        }))
        .value();
    },
  },

  mounted() {
    utils.bridge.bind('themeChanged', this.onThemeChange);
  },

  methods: {
    onThemeChange() {
      this.darkTheme = localStorage.darkTheme === 'true';
    },

    addScheduledRun() {
      const newSchedule = _.cloneDeep(this.defaultScheduledRun) || {};
      const newScheduleId = utils.randomId();

      this.$emit('set-schedule', {
        ...this.configSchedule,
        [newScheduleId]: newSchedule,
      });

      this.expandedScheduledRuns[newScheduleId] = true;
    },

    deleteScheduledRun(id) {
      const newSchedule = { ...this.configSchedule };
      delete newSchedule[id];
      this.$emit('set-schedule', newSchedule);
    },

    toggleExpandedSchedule(scheduledRunId) {
      this.expandedScheduledRuns = {
        ...this.expandedScheduledRuns,
        [scheduledRunId]: !this.expandedScheduledRuns[scheduledRunId],
      };
    },

    scheduleDaysText(days, dows) {
      const daysText = days?.map((day) => utils.parseDate(day).toLocaleDateString()).join(', ');
      const dowsText = dows?.map(utils.l10n).join(', ');

      return daysText || dowsText || utils.l10n('[no days selected]');
    },

    scheduleTimeText(time) {
      return time || utils.l10n('[no time selected]');
    },

    scheduleCategoriesText(categories) {
      return categories?.map((category) => category.name).join(', ') || utils.l10n('[no categories selected]');
    },

    autoAcceptLeftText(boundsLeft) {
      return boundsLeft ? `${boundsLeft}%` : utils.l10n('[no min limit selected]');
    },

    autoAcceptRightText(boundsRight) {
      return boundsRight ? `${boundsRight}%` : utils.l10n('[no max limit selected]');
    },

    calendarAttributes(days) {
      return days.map((day) => ({
        key: day,
        highlight: true,
        dates: utils.parseDate(day),
      }));
    },
  },
};
</script>

<style scoped>
.gp-rules-schedule__btn {
  cursor: pointer;
  color: var(--cyan);
}

.gp-rules-schedule__btn:hover {
  text-decoration: underline;
}

.gp-runs-schedule {
    list-style: none;
    padding-left: 25px;
}
.gp-runs-schedule > li {
    position: relative;
}
.gp-runs-schedule > li.expanded:before {
    content: "";
    position: absolute;
    left: -20px;
    width: 2px;
    top: 20px;
    bottom: 0;
    background: var(--cyan);
}
.gp-runs-schedule > li > a .feather-icon svg {
    position: absolute;
    margin-top: 2px;
    margin-left: -29px;
    width: 20px;
    height: 20px;
}
.gp-runs-schedule .btn-group-toggle + .vc-container {
    margin-top: 15px;
}
.gp-runs-schedule .vc-container {
    display: block;
    margin: auto;
}
</style>
