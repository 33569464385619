const downloadResource = (resourceLink, fileName) => {
  const link = document.createElement('a');
  link.href = resourceLink;
  link.download = fileName || '';
  link.target = '_blank';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

module.exports = {
  downloadResource,
};
