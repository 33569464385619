<template>
  <vue-multiselect
    ref="multiselect"
    v-model="localValue"
    :closeOnSelect="closeOnSelect"
    :clearOnSelect="clearOnSelect"
    :customLabel="customLabel"
    :disabled="disabled"
    :deselectLabel="deselectLabel"
    :hideSelected="hideSelected"
    :options="options"
    :limit="limit"
    :limitText="limitText"
    :loading="loading"
    :label="label"
    :multiple="multiple"
    :placeholder="placeholder"
    :searchable="searchable"
    :selectLabel="selectLabel"
    :selectedLabel="selectedLabel"
    :showLabels="showLabels"
    :trackBy="trackBy"
    :maxHeight="maxHeight"
    :openDirection="openDirection"
    :optionHeight="optionHeight"
    @open="onOpen"
    @close="onClose"
    @select="onSelect"
  >
    <template v-slot:beforeList>
      <slot name="beforeList" />
    </template>
    <template v-slot:option="{ option }">
      <slot name="option" v-bind:option="option" />
    </template>
    <template v-slot:afterList>
      <slot name="afterList" />
    </template>
  </vue-multiselect>
</template>

<script>
// Vue-multiselect docs - https://vue-multiselect.js.org/
const VueMultiselect = require('vue-multiselect').default;

module.exports = {
  components: {
    'vue-multiselect': VueMultiselect,
  },

  // https://ru.vuejs.org/v2/guide/components-custom-events.html
  model: {
    prop: 'selectedOption',
    event: 'change',
  },

  props: {
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    selectedOption: {
      type: [String, Object, Array],
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: 'Выберите опцию',
    },
    multiple: {
      type: Boolean,
      required: false,
      default: false,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearOnSelect: {
      type: Boolean,
      required: false,
      default: true,
    },
    customLabel: {
      type: Function,
      required: false,
      default: (v) => v?.name || v,
    },
    hideSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
    showLabels: {
      type: Boolean,
      required: false,
      default: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    label: {
      type: String,
      required: false,
      default: '',
    },
    deselectLabel: {
      type: String,
      required: false,
      default: '',
    },
    selectLabel: {
      type: String,
      required: false,
      default: '',
    },
    selectedLabel: {
      type: String,
      required: false,
      default: '',
    },
    limit: {
      type: Number,
      required: false,
      default: 20,
    },
    limitText: {
      type: Function,
      required: false,
      default: (count) => `еще ${count}`,
    },
    trackBy: {
      type: String,
      required: false,
      default: '',
    },
    maxHeight: {
      type: Number,
      required: false,
      default: 300,
    },
    openDirection: {
      type: String,
      required: false,
      default: '',
    },
    forceOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
    optionHeight: {
      type: Number,
      required: false,
      default: 40,
    },
  },

  data() {
    return {
      localValue: null,
    };
  },

  mounted() {
    if (this.selectedOption) {
      this.localValue = this.selectedOption;
    }
  },

  watch: {
    selectedOption(newValue) {
      this.localValue = newValue;
    },
    localValue(newValue) {
      this.$emit('change', newValue);
    },
    forceOpened(newValue) {
      if (newValue) {
        this.$refs.multiselect.activate();
      }
    },
  },

  methods: {
    onSelect(option) {
      this.$emit('select', option);
    },
    onOpen() {
      this.$emit('open');
    },
    onClose() {
      this.$emit('close');
    },
  },
};
</script>

<!-- Add Multiselect CSS. Can be added as a static asset or inside a component -->
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style scoped>
.multiselect>>>.multiselect__tags {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 8px;
  background-color: #F7F7F7;
  padding-top: 12px;
  transition: background-color 0.15s ease-in-out;
}

.multiselect>>>.multiselect__spinner {
  background-color: inherit;
  border-radius: 8px;
}

.multiselect>>>.multiselect__tags:hover {
  background-color: #E9E9E9;
  cursor: pointer;
}

.multiselect--disabled {
  background: none;
  user-select: none;
}

.multiselect--disabled>>>.multiselect__select {
  background: none;
}

.multiselect.invalid>>>.multiselect__tags {
  border: none;
  outline: 0;
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: #FAE6EB;
}

.multiselect>>>.multiselect__single {
  padding-left: 4px;
  font-weight: 500;
  font-size: 14px;
  background-color: inherit;
  margin: 0;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  overflow: hidden;
}

.multiselect>>>.multiselect__placeholder {
  padding-top: 0;
  padding-left: 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background-color: inherit;
  color: #D4D4D4;
  margin: 0;
}

.multiselect>>>.multiselect__input {
  background: inherit;
  line-height: inherit;
}

.multiselect>>>.multiselect__tag {
  background-color: #3385FF;
}

.multiselect>>>.multiselect__tag-icon:hover {
  background-color: #5295D5;
}

.multiselect>>>.multiselect__option--highlight,
.multiselect>>>.multiselect__option--highlight:after {
  background-color: #3385FF;
}

.multiselect>>>.multiselect__option--selected:hover {
  background-color: #F8C2C4;
}

.multiselect>>>.multiselect__content-wrapper {
  overflow-x: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

</style>
