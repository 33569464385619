<template>
  <div class="my-fancytree">
    <my-errors v-if="errors" :errors="errors"/>
    <div ref="tree"/>
  </div>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  props: {
    stream: { type: String, default: 'default' },
    caption: { type: String },
    rootKey: { type: [Number, String], default: 0 },
    keyColumn: { type: String, default: 'id' },
    groupColumn: { type: String, default: 'parent_id' },
    labelColumn: { type: String, default: 'name' },
    isGroupColumn: { type: String, default: 'true' },
    filter1: { type: String },
    filter2: { type: String },
    filter3: { type: String },
  },
  data: function() {
    return {
      mounted: false,
      fancytree: null,
      errors: null,
      source: [],
      activeNode: null,
    };
  },
  watch: {
    activeNode() {
      this.$emit('change', this.activeNode);
    },
  },
  async mounted() {
    document.addEventListener('mousedown', this.handleMouseDown);
    this.mounted = true;
    this.source = await this.loadLevel(this.rootKey);
    if (!this.mounted) {
      return;
    }
    let { createTree } = await import('./fancytree.js');
    if (!this.mounted) {
      return;
    }
    let _svg = (name) => feather.icons[name]?.toSvg();
    $(this.$refs.tree).fancytree({
      extensions: ['hotkeys', 'glyph'],
      source: [],
      hotkeys: {
        keyup: {
          'return': () => this.$emit('submit', this.activeNode),
          'esc': () => this.$emit('cancel'),
        },
      },
      glyph: {
        preset: 'awesome4',
        map: {
          checkbox: _svg('square'),
          checkboxSelected: _svg('check-square'),
          checkboxUnknown: _svg('check-square'),
          radio: _svg('none'),
          radioSelected: _svg('none'),
          radioUnknown: _svg('none'),
          dragHelper: _svg('none'),
          dropMarker: _svg('none'),
          error: _svg('alert-circle'),
          expanderClosed: _svg('none'),
          expanderLazy: _svg('none'),
          expanderOpen: _svg('none'),
          loading: _svg('clock'),
          nodata: _svg('slash'),
          noExpander: _svg('none'),
          doc: _svg('none'),
          docOpen: _svg('none'),
          folder: _svg('folder'),
          folderOpen: _svg('folder'),
        },
      },
      activate: (event, data) => {
        this.$emit('activate', data.node);
        this.activeNode = data.node;
      },
      deactivate: (event, data) => {
        this.$emit('deactivate', data.node);
        this.activeNode = null;
      },
      select: (event, data) => {
        this.$emit('select', data.node);
      },
      lazyLoad: (event, data) => {
        data.result = this.loadLevel(data.node.key);
      },
    });
    this.tree = $(this.$refs.tree).fancytree('getTree');
    this.tree.reload(this.source);
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.handleMouseDown);
    this.mounted = false;
    if (this.tree) {
      $(this.$refs.tree).fancytree('destroy');
    }
  },
  methods: {
    handleMouseDown(e) {
      let node = e.target;
      let base = this.$refs.tree;
      while (node && node !== base) {
        node = node.parentNode;
      }
      if (!node) {
        this.$emit('clickoutside');
      }
    },
    async loadLevel(rootKey) {
      let stream = this.stream;
      let filter1 = this.filter1;
      let filter2 = this.filter2;
      let filter3 = this.filter3;
      if (filter1) {
        filter1 = `(${filter1}) && ${this.groupColumn} == ${utils.quote(rootKey)}`;
      } else {
        filter1 = `${this.groupColumn} == ${utils.quote(rootKey)}`;
      }

      let query = `
                query {
                  dataset {
                    streams {
                      ${stream} {
                        report(
                          dims: "${this.keyColumn}",
                          vals: "${this.labelColumn}, ${this.isGroupColumn}",
                          ${filter1 ? `filter1: ${utils.quote(filter1)},` : ''}
                          ${filter2 ? `filter2: ${utils.quote(filter2)},` : ''}
                          ${filter3 ? `filter2: ${utils.quote(filter3)},` : ''}
                          sort: [2])
                        {
                          rows
                        }
                      }
                    }
                  }
                }`;

      let { data, errors } = await (await fetch('/graphql?__loadLevel__', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ query }),
      })).json();

      if (errors) {
        this.errors = errors;
        console.warn('query failed', errors);
        console.warn(query);
        throw this.errors[0].message;
      }

      let rows = data.dataset.streams[stream].report.rows;
      return rows.map((row) => {
        return {
          row: row,
          key: row[0],
          title: row[1],
          lazy: row[2],
          folder: row[2],
        };
      });
    },
  },
};
</script>
