<template>
  <div class="foamtree">
    <my-errors :errors="errors" @retry="requestData"/>

    <my-caption :caption="caption"/>

    <my-progress
      :key="reportId"
      :progress="progress"
      :processRows="processRows"
      :processTime="processTime"
      :meta="meta"
      :exportToFile="exportToFile"
      :assembly="assembly"/>

    <div class="my-filters">
      <a
        v-for="filter in filters"
        :class="filterClass(filter)"
        href="javascript:void(0)"
        @click="removeFilter(filter)"
      >
        {{filter.name}}
      </a>
      <span class="text-muted badge" v-if="filters.length == 0" v-show="wasFiltered">
        no filters applied
      </span>
    </div>

    <my-download :file="file"/>

    <my-tooltip
      ref="tooltip"
      :meta="meta"
      :keepOnly="keepOnly"
      :exclude="exclude"/>

    <div :style="style" ref="base" class="my-foamtree">
      <div ref="chart"></div>
    </div>
  </div>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  mixins: [
    require('./data.js'),
    require('./foamtree.js'),
  ],
  props: {
    width: { type: [String, Number], default: '100%' },
    height: { type: [String, Number], default: 300 },
    caption: { type: String },
    _take: { type: Number, default: null },
  },
  data: function() {
    return {
      isMounted: false,
      foamtree: null,
      resizeSensor: null,
      filters: [],
      wasFiltered: false,
      isIntersecting: true,
      isVisible: true,
    };
  },
  computed: {
    sort() {
      return _.map(this.dims, (d,i) => i+1);
    },
    take() {
      return this._take;
    },
    style() {
      return {
        width: _.isNumber(this.width) ? `${this.width}px` : this.width,
        height: _.isNumber(this.height) ? `${this.height}px` : this.height,

      };
    },
    dataObject() {
      if (this.report != null) {
        let loop = (rows, dimension) => {
          let groups = [];
          let group = undefined;
          let previousId = undefined;
          _.forEach(rows, (row, i) => {
            let weight = row[this.dims.length];
            let value = row.slice(this.dims.length);
            let id = row[dimension];
            if (id !== previousId) {
              let key = row.slice(0, this.dims.length);
              for (let j=dimension+1; j<key.length; ++j) {
                key[j] = null;
              }
              previousId = id;
              group = {
                id,
                key,
                value,
                label: `${id}`,
                start: i,
                weight,
                dimension,
              };
              groups.push(group);
            } else {
              group.weight += weight;
              for (let j=0; j<value.length; ++j) {
                group.value[j] += value[j];
              }
            }
            group.end = i+1;
          });
          for (group of groups) {
            group.rows = rows.slice(group.start, group.end);
            if (dimension < this.dims.length-1) {
              group.groups = loop(group.rows, dimension+1);
              if (group.groups.length == 1 && group.groups[0].label === '') {
                group.groups = [];
              }
            }
          }
          return groups;
        };
        let groups = loop(this.report.rows, 0);
        while (groups.length == 1 && groups[0].groups && groups[0].groups.length > 0) {
          groups = groups[0].groups;
        }
        return { groups };
      } else {
        return null;
      }
    },
  },
  watch: {
    filters() {
      this.wasFiltered = true;

      let groups = _.groupBy(this.filters, 'type');

      let makeFilter = (filters, negate) => {
        let groups = this.groups;
        let stream = this.stream;
        let filter1 = '';
        let filter2 =
                    _(filters)
                      .map(({ path }) =>
                        path.map(({ dimension, value }) =>
                          `(${dimension.name} == ${utils.quote(value)})`)
                          .join(' && '))
                      .join(' || ');
        let filter3 = '';
        if (filter2 !== '' && negate) {
          filter2 = `!(${filter2})`;
        }
        return { groups, stream, filter1, filter2, filter3 };
      };

      utils.bridge.trigger('filtersChanged', this._uid, makeFilter(groups.chart));

      let { filter2: keepOnly } = makeFilter(groups.keepOnly);
      let { filter2: exclude } = makeFilter(groups.exclude, true);

      let stream = this.stream;
      let filter1 = '';
      let filter2 = '';
      let filter3 = '';
      if (keepOnly !== '' && exclude !== '') {
        filter2 = `${keepOnly} && ${exclude}`;
      } else if (keepOnly !== '') {
        filter2 = keepOnly;
      } else if (exclude !== '') {
        filter2 = exclude;
      }

      utils.bridge.trigger('filtersChanged', -this._uid, { stream, groups: this.groups, filter1, filter2, filter3 });
    },
    dataObject(newValue) {
      if (this.foamtree != null) {
        this.foamtree.set('dataObject', newValue);
        // let oldValue = this.foamtree.get("dataObject")
        // if (newValue != null && oldValue != null) {
        //     let makeIds = (groups) =>
        //         _(groups)
        //             .map(({id}, i) => [id, i])
        //             .fromPairs()
        //             .value()

        //     let oldIds = makeIds(oldValue.groups)
        //     let newIds = makeIds(newValue.groups)

        //     if (_.isEqual(oldIds, newIds)) {
        //     // if (_(newIds).keys().every((key) => oldIds[key] !== undefined)) {
        //         _(oldIds)
        //             .toPairs()
        //             .forEach(([id, i]) => {
        //                 let weight = 0
        //                 if (newIds[id] !== undefined) {
        //                     weight = newValue.groups[newIds[id]].weight
        //                 }
        //                 oldValue.groups[i].weight = weight
        //             })
        //         this.foamtree.update()
        //         return
        //     }
        // }
        // this.foamtree.set("dataObject", newValue)
      }
    },
    layout() {
      if (this.foamtree) {
        let dataObject = this.foamtree.get('dataObject');
        dataObject = _.clone(dataObject);
        this.foamtree.set('dataObject', dataObject);
      }
    },
    foamtree() {
      this.foamtree.set({
        onGroupSelectionChanged: ({ groups }) => {
          let filters = this.filters;
          filters = filters.filter(({ type }) => type !== 'chart');
          for (let { key } of groups) {
            let filter = this.makeFilter('chart', key);
            if (!_.some(filters, ({ name }) => name === filter.name)) {
              filters.push(filter);
            }
          }
          this.filters = filters;
        },
        onGroupDoubleClick: (e) => {
          e.preventDefault();
          let group = e.secondary ? e.bottommostOpenGroup : e.topmostClosedGroup;
          let toZoom;
          if (group) {
            this.foamtree.open({
              groups: group,
              open: !e.secondary,
            });
            toZoom = e.secondary ? group.parent : group;
          } else {
            this.foamtree.open({ all: true, open: false });
            toZoom = this.foamtree.get('dataObject');
          }
          this.foamtree.zoom(toZoom);
          this.$refs.tooltip.hide();
        },
        onGroupHover: ({ x,y,group }) => {
          if (group === null || group.attribution) {
            this.$refs.tooltip.hide();
            $(this.$refs.chart).removeClass('my-foamtree-active');
          } else {
            this.$refs.tooltip.show(group, this.translateCoords(x,y));
            $(this.$refs.chart).addClass('my-foamtree-active');
          }
        },
        onGroupMouseMove: ({ x,y,group }) => {
          if (group !== null && !group.attribution) {
            this.$refs.tooltip.move(group, this.translateCoords(x,y));
          }
        },
        onGroupClick: ({ x,y,group }) => {
          if (group !== null && !group.attribution) {
            this.$refs.tooltip.click(group, this.translateCoords(x,y));
          }
        },
        onGroupMouseWheel: (e) => {
          e.allowOriginalEventDefault();
          let { x,y,group } = e;
          if (group !== null && !group.attribution) {
            this.$refs.tooltip.move(group, this.translateCoords(x,y));
          }
        },
      });
    },
    isIntersecting() {
      if (!this.isIntersecting) {
        this.$refs.tooltip.reset();
        $(this.$refs.chart).removeClass('my-foamtree-active');
      }
    },
  },
  methods: {
    filterClass: function(filter) {
      return {
        'badge': true,
        'badge-pill': true,
        'badge-secondary': filter.type === 'chart',
        'badge-primary': filter.type === 'keepOnly',
        'badge-danger': filter.type === 'exclude',
      };
    },
    translateCoords(x,y) {
      let { left, top } = this.$refs.base.getBoundingClientRect();
      return { pageX: x + left, pageY: y + top };
    },
    removeFilter(filter) {
      this.filters.splice(this.filters.indexOf(filter), 1);
      this.foamtree.select({ groups: [filter.group], selected: false });
    },
    makeFilter(type, key) {
      let path = [];
      for (let i=0; i<key.length; ++i) {
        let value = key[i];
        if (value !== null) {
          let dimension = this.dims[i];
          if (_.isString(dimension)) {
            dimension = {
              name: dimension,
              calc: dimension,
            };
          }
          path.push({ dimension, value });
        }
      }
      let name = _.filter(key).join(', ');
      return { type, path, name };
    },
    exclude({ key }) {
      this.$refs.tooltip.hide();
      let filter = this.makeFilter('exclude', key);
      this.filters = this.filters
        .filter(({ name }) => name !== filter.name)
        .concat([filter]);
    },
    keepOnly({ key }) {
      this.$refs.tooltip.hide();
      this.foamtree.open({ all: true, open: false });
      this.foamtree.select({ all: true, selected: false });
      this.filters = [this.makeFilter('keepOnly', key)];
    },
  },
  mounted() {
    this.isMounted = true;
    this.resizeSensor = new ResizeSensor(this.$refs.base, () => {
      if (this.foamtree != null) {
        this.foamtree.resize();
      }
    });
    import('./foamtree-3.4.10/carrotsearch.foamtree')
      .then(() => {
        if (this.isMounted) {
          let config = _.clone(this.config);
          config.element = this.$refs.chart;
          config.pixelRatio = window.devicePixelRatio || 1;
          config.dataObject = this.dataObject;
          this.foamtree = new CarrotSearchFoamTree(config);
        }
      });
    if (window.IntersectionObserver !== undefined) {
      this.observer = new IntersectionObserver((entries) => {
        let [{ isVisible, isIntersecting }] = entries;
        this.isIntersecting = isIntersecting;
        this.isVisible = isVisible;
      });
      this.observer.observe(this.$refs.base);
    }
  },
  beforeDestroy() {
    this.isMounted = false;
    this.resizeSensor.detach();
    if (this.foamtree != null) {
      this.foamtree.dispose();
    }
    if (this.observer !== undefined) {
      this.observer.disconnect();
    }
  },
};
</script>
<style>
.my-foamtree {
    clear: both;
    position: relative;
    margin-top: 10px;
}
.my-foamtree * {
    touch-action: manipulation;
}
.my-foamtree > div:first-child {
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
}
.my-foamtree-active {
    cursor: pointer;
}
</style>
