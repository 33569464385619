<template>
  <div :class="`pim-tabs pim-tabs-${mode}`">
    <ul>
      <li
        v-for="name, id in tabs"
        :class="{active: tab == id}"
        @click="
          tab != id ? $emit('change', id) : allowDeselect ? $emit('change', null) : null
          $event.stopPropagation()"
      >
        <a href="javascript:void(0)">
          <span>{{name}}</span>
        </a>
      </li>
    </ul>
    <slot/>
  </div>
</template>
<script>
module.exports = {
  props: {
    mode: { type: String, default: 'horizontal' },
    tab: { type: String },
    tabs: { type: Object, default: () => ({}) },
    allowDeselect: { type: Boolean },
  },
  model: {
    prop: 'tab',
    event: 'change',
  },
};
</script>
<style>
.pim-tabs-horizontal {
    display: flex;
    flex-direction: row;
    line-height: 30px;
    border-top: 1px solid var(--dark);
    border-bottom: 1px solid var(--dark);
}
.pim-tabs-horizontal li {
    cursor: pointer;
}
.my-dark-theme .pim-tabs-horizontal {
    border-top: 1px solid var(--light);
    border-bottom: 1px solid var(--light);
}
.pim-tabs-horizontal {
    background-image: linear-gradient(to bottom, #eee, white 5%, white 60%, #eee);
}
.pim-tabs-vertical {
    background-image: linear-gradient(to right, #eee, white 5%, white 60%, #eee);
}
.my-dark-theme .pim-tabs-horizontal {
    background: #333;
}
.my-dark-theme .pim-tabs-vertical {
    background: #333;
}
.pim-tabs-horizontal > ul {
    list-style: none;
    display: flex;
    padding: 0;
    margin: 0;
    flex-grow: 1;
}
.pim-tabs-horizontal > ul > li {
    white-space: nowrap;
    border-right: 1px solid var(--dark);
}
.pim-tabs-horizontal > ul > li.active {
    background: white;
    border-top: 1px solid var(--dark);
    margin-top: -1px;
    margin-bottom: -1px;
}
.my-dark-theme .pim-tabs-horizontal > ul > li.active {
    border-top: 1px solid var(--light);
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
    background: #222;
}
.pim-tabs-horizontal > ul > li > a {
    padding: 0 15px;
    color: var(--dark)
}
.my-dark-theme .pim-tabs-horizontal > ul > li > a {
    color: var(--light)
}
.pim-tabs-horizontal > ul > li.active > a {
    color: #222;
}
.my-dark-theme .pim-tabs-horizontal > ul > li.active > a {
    color: white;
}
.pim-tabs-horizontal > ul > li > a:hover {
    text-decoration: none;
}
.pim-tabs-horizontal svg {
    width: 20px;
    margin-top: -2px;
    margin-right: 6px;
}
.pim-tabs-vertical svg {
    width: 20px;
    margin-left: 5px
}
.pim-tabs-vertical {
    width: 32px;
    border-left: 1px solid var(--dark);
    border-right: 1px solid var(--dark);
}
.my-dark-theme .pim-tabs-vertical {
    border-left: 1px solid var(--light);
    border-right: 1px solid var(--light);
}
.pim-tabs-vertical > ul {
    background-color: white;
    width: 30px;
    list-style: none;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}
.my-dark-theme .pim-tabs-vertical > ul {
    background-color: var(--dark);
}
.pim-tabs-vertical > ul > li {
    display: inline-block;
    border-bottom: 1px solid var(--dark);
}
.my-dark-theme .pim-tabs-vertical > ul > li {
    border-bottom: 1px solid var(--light);
}
.pim-tabs-vertical > ul > li > a {
    display: block;
    padding-top: 100%;
    vertical-align: top;
}
.pim-tabs-vertical > ul > li > a > span {
    color: #444;
    display: inline-block;
    height: 30px;
    line-height: 30px;
    transform-origin: 0 0;
    white-space: nowrap;
    transform: rotate(-90deg) translate(-14px, -1px);
}
.pim-hierarchies-side {
    position: relative;
}
.pim-hierarchies-side-main {
    position: absolute;
    top: 0;
    left: 32px;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
}
.pim-tabs-vertical > ul {
    background: transparent;
}
.pim-tabs-vertical > ul > li > a {
    /*background: var(--light);*/
    background: transparent;
}
.pim-tabs-vertical > ul > li > a > span {
    color: var(--dark);
}
.my-dark-theme .pim-tabs-vertical > ul > li > a > span {
    color: var(--light);
}
.pim-tabs-vertical > ul > li.active {
    background: white;
}
.my-dark-theme .pim-tabs-vertical > ul > li.active {
    background: #222;
}
.pim-tabs-vertical > ul > li.active > a > span {
    color: #222
}
.my-dark-theme .pim-tabs-vertical > ul > li.active > a > span {
    color: white;
}
.pim-tabs-vertical {
    height: 100%;
}
</style>
