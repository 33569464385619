const knobs = require('./knob/index');

module.exports = (variable, variableName, locale, currency) => {
  const createKnob = knobs[variable.type];

  if (!createKnob) {
    throw new Error(`Variable {${variableName}} doesn't exist!`);
  }

  const knob = createKnob({ locale, currency, ...variable });
  const knobKeys = Object.keys(knob);

  Object.entries(variable).forEach(([key, value]) => {
    const isArrow = /^\s*(\(([^()]*|\([^()]*\))*\))?\s*=>/mg;

    if (knobKeys.includes(key)) {
      if (isArrow.test(value)) {
        try {
          // eslint-disable-next-line no-eval
          knob[key] = eval(value);
        } catch (e) {
          // eslint-disable-next-line no-console
          console.warn(`Can't eval value for ${variableName}:${key}. Use default value.`);
        }
      } else {
        knob[key] = value;
      }
    }
  });

  return knob;
};
