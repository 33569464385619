<template>
  <span class="inline-input" :class="{focused}">
    <a href="javascript:void(0)">
      <span>
        <l10n :value="value" />
      </span>
      <input
        :value="value"
        :type="type"
        :pattern="pattern"
        class="form-control form-control-sm"
        :class="{'no-spin': noSpin}"
        @input="handleInput"
        @blur="handleBlur"
        @focus="handleFocus"
        @keydown="handleKeyDown" />
    </a>
  </span>
</template>

<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'input',
  },
  props: {
    value: String,
    type: { type: String, default: 'text' },
    pattern: { type: String, default: '' },
    noSpin: { type: Boolean, default: false },
  },
  data() {
    return {
      focused: false,
    };
  },
  methods: {
    handleInput(e) {
      this.$emit('input', e.target.value);
    },
    handleKeyDown(e) {
      if (e.key == 'Enter') {
        e.target.blur();
      }
    },
    handleBlur() {
      this.focused = false;
    },
    handleFocus() {
      this.focused = true;
    },
  },
};
</script>

<style scoped>
.inline-input a {
    display: inline-block;
    position: relative;
}
.inline-input a input {
    position: absolute;
    top: -2px;
    left: 0;
    right: 0;
    bottom: -2px;
    margin: 0;
    padding: 0;
    opacity: 0;
    padding-left: 4px;
    font-size: inherit;
    max-width: 124px;
    min-width: 15px;
    z-index: 1;
}
.inline-input.focused a input {
    opacity: 1;
    z-index: 2;
}
.inline-input:not(.focused) a input {
    overflow: hidden;
}
.inline-input:not(.focused) a input {
    cursor: pointer;
}
.inline-input a input::-webkit-clear-button,
.inline-input a input::-moz-clear-button {
    display: none;
}
/* Chrome, Safari, Edge, Opera */
.no-spin input::-webkit-outer-spin-button,
.no-spin input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.no-spin input[type=number] {
  -moz-appearance: textfield;
}
</style>
