<template>
  <my-dialog
    title="Добавить новый магазин"
    v-if="show"
    @close="closeForm"
  >
    <div class="inputblock">
      <label
        class="inputblock__label"
        for="store"
      >
        <l10n value='Store' />
      </label>
      <input
        v-model="store"
        class="inputblock__input input"
        :class="{ input_invalid: tryToSendForm && !store.length }"
        id="store"
        placeholder="Введите название магазина"
      />
    </div>
    <div class="inputblock">
      <label class="inputblock__label" for="channel">Канал</label>
      <my-multiselect
        id="channel"
        :options="channels"
        :selectedOption="channel"
        @select="onChannelSelect"
        placeholder="Выберите канал"
      />
    </div>
    <div class="inputblock">
      <label class="inputblock__label" for="brand">Бренд</label>
      <my-multiselect
        id="brand"
        :options="brands"
        :selectedOption="brand"
        @select="onBrandSelect"
        placeholder="Выберите бренд"
      />
    </div>
    <div class="inputblock">
      <label
        class="inputblock__label"
        for="city"
      >
        <l10n value='City' />
      </label>
      <input
        v-model="city"
        class="inputblock__input input"
        :class="{ input_invalid: tryToSendForm && !city.length }"
        id="city"
        placeholder="Введите название города"
      />
    </div>
    <div class="inputblock">
      <label
        class="inputblock__label"
        for="region"
      >
        <l10n value='Region' />
      </label>
      <input
        v-model="region"
        class="inputblock__input input"
        :class="{ input_invalid: tryToSendForm && !region.length }"
        id="region"
        placeholder="Введите название региона"
      />
    </div>
    <div class="inputblock">
      <label
        class="inputblock__label"
        for="openingDate"
      >
        <l10n value='Opening date' />
      </label>
      <input
        v-model="openingDate"
        class="inputblock__input input"
        id="openingDate"
        :placeholder="localizeValue('Select date')"
        type="date"
      />
    </div>
    <div class="buttons-block">
      <button
        type="button"
        class="button button_primary"
        :disabled="hasEmptyField && tryToSendForm"
        :class="{ button_disabled: hasEmptyField && tryToSendForm }"
        @click="onCreateShop"
      >
        <l10n value="Save" />
      </button>
      <button
        type="button"
        class="button button_secondary"
        @click="closeForm"
      >
        <l10n value="Cancel" />
      </button>
    </div>
  </my-dialog>
</template>

<script>
const utils = require('../my-utils');
const MyMultiSelect = require('../my-multiselect.vue').default;

module.exports = {
  components: {
    'my-multiselect': MyMultiSelect,
  },
  props: {
    isAddShopModalVisible: {
      type: Boolean,
      required: true,
      default: false,
    },
    username: {
      type: String,
      required: true,
      default: '',
    },
  },

  data() {
    return {
      show: false,
      store: '',
      channel: 'offline',
      channels: ['offline'],
      brand: 'GJ',
      brands: ['GJ'],
      city: '',
      region: '',
      openingDate: this.getTodayDate(),
      today: this.getTodayDate(),
      lastDayOfTheYear: `${new Date().getFullYear()}-12-31`,
      tryToSendForm: false,
    };
  },

  computed: {
    hasEmptyField() {
      return !this.store?.length || !this.city?.length || !this.region?.length;
    },
  },

  watch: {
    isAddShopModalVisible(newValue) {
      this.show = newValue;
    },
  },

  methods: {
    onChannelSelect(event) {
      this.channel = event.target.value;
    },

    onBrandSelect(event) {
      this.channel = event.target.value;
    },

    localizeValue(value) {
      return utils.l10n(value);
    },

    onCreateShop() {
      if (this.hasEmptyField) {
        this.tryToSendForm = true;
        this.createNotification('Form has an empty fields', 'error');
        return;
      }

      const data = {
        store: this.store,
        channel: this.channel,
        brand: this.brand,
        city: this.city,
        region: this.region,
        opening_date: this.openingDate,
      };
      const url = '/api/store-planning/sp-add-new-store';
      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      };

      fetch(url, options)
        .then((response) => {
          if (response.status === 201) {
            this.createNotification('New shop has been added successfully', 'success');
            this.closeForm();
            this.$emit('on-shop-successful-added');
          }

          return response.json();
        })
        .then((result) => {
          if (result?.detail.length) {
            this.createNotification(result.detail[0], 'error');
          }
        });
    },

    cleanForm() {
      this.store = '';
      this.channel = 'offline';
      this.brand = 'GJ';
      this.city = '';
      this.region = '';
      this.openingDate = this.getTodayDate();
      this.today = this.getTodayDate();
      this.tryToSendForm = false;
    },

    getTodayDate() {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1 < 10 ? `0${now.getMonth() + 1}` : now.getMonth() + 1;
      const date = now.getDate() < 10 ? `0${now.getDate()}` : now.getDate();

      return `${year}-${month}-${date}`;
    },

    closeForm() {
      this.cleanForm();
      this.$emit('add-shop-modal-close');
    },
  },
};
</script>

<style scoped>
.input {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 350px;
  height: 46px;
  margin: 0;
  padding: 0px 8px 0px 12px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out, background-color 0.15s ease-in-out;
}
.input:hover {
  background-color: #E9E9E9;
  cursor: pointer;
}
.input:focus {
  border-color: #5295D5;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0.2rem #5295D5;
  box-shadow: 0 0 0 0.2rem #5295D5;
}
.input_invalid {
  background-color: #FAE6EB;
}
.inputblock {
  margin-bottom: 16px;
}
.inputblock__label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
}
.buttons-block {
  display: flex;
  justify-content: space-around;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 215px;
  height: 46px;
  border: none;
  border-radius: 8px;
  transition: background-color .3s ease-in-out;
}
.button_primary {
  background-color: #5295D5;
  color: #ffffff;
}
.button_secondary {
  background-color: #F7F7F7;
  color: #000000;
}
.button_primary:not(.button_disabled):hover {
  background-color: #3C80C1;
  transition: background-color .3s ease-in-out;
}
.button_secondary:hover {
  background-color: #E9E9E9;
  transition: background-color .3s ease-in-out;
}
.button_disabled {
  background-color: #F7F7F7;
  color: #D4D4D4;
  cursor: default;
}
</style>
