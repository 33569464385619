const { DOMAIN } = require('./endpoint');
const {
  getIdNameField,
  getLeafLine,
  getLeafName,
  getLeafArticle,
} = require('../utils/tableColumnNameGetters');

// products
const COMPONENT_NAMES = {
  catalog: 'ProductsCatalog',
  product: 'ProductCard',
  category: 'ProductCategoryCard',
};
const productTabsStorageKey = 'product-tabs';
const productEntityStorageKey = 'product-cards';
const productCategoriesEntityStorageKey = 'product-categories';
const newProduct = 'new-product-card';
const newCategory = 'new-product-category';
const catalogTab = { id: 'product-catalog', title: 'Справочник товаров', componentName: COMPONENT_NAMES.catalog };

// storage keys
const storageKeys = {
  parentName: 'products',
};

// catalog
const catalogColumns = [
  { id: 'id', title: 'ID товара', getter: getIdNameField },
  { id: 'line', title: 'Линейка', getter: getLeafLine },
  { id: 'code', title: 'Код товара', getter: getLeafArticle },
  { id: 'name', title: 'Наименование товара', getter: getLeafName },
];

const hierarchyTableProps = {
  columns: catalogColumns,
  endpoint: `${DOMAIN}/products/categories/hierarchy/`,
  leafsField: 'products',
};

// endpoints
const ENDPOINTS = {
  PRODUCTS: `${DOMAIN}/products/`,
  FEATURES: `${DOMAIN}/products/features/`,
  FEATURE_NAMES: `${DOMAIN}/products/feature_names/`,
  CATEGORIES_HIERARCHY: `${DOMAIN}/products/categories/hierarchy/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  productTabsStorageKey,
  productEntityStorageKey,
  productCategoriesEntityStorageKey,
  newProduct,
  newCategory,
  catalogTab,
  storageKeys,
  hierarchyTableProps,
};
