module.exports = {
  props: {
    fields: { type: Array },
    locales: { type: Array },
    statuses: { type: Array },
    relations: { type: Array },
    function_: { type: Object },
    functions: { type: Array },
    storefronts: { type: Array },
    search: {
      type: Object,
      default: () => ({
        threshold: 0,
        minMatchCharLength: 2,
        isCaseSensitive: false,
        includeMatches: true,
        ignoreLocation: true,
        useExtendedSearch: true,
        findAllMatches: true,
        shouldSort: false,
        includeScore: true,
        keys: ['name'],
      }),
    },
  },
  computed: {
    columnsMap() {
      return {
        'alphanumeric': 'alphanumeric_data',
        'boolean': 'boolean_data',
        'date': 'date_data',
        'datetime': 'datetime_data',
        'decimal': 'decimal_data',
        'dropdown': 'dropdown_data',
        'json': 'json_data',
        'multidropdown': 'dropdown_data',
        'numeric': 'numeric_data',
        'textarea': 'textarea_data',
        'overridden': 'is_overridden',
      };
    },
    fieldsByApiName() {
      return _(this.fields).groupBy('api_name').value();
    },
    fieldsByFieldId() {
      return _(this.fields).map(field => [field.field_id, field]).fromPairs().value();
    },
  },
  methods: {
    getFieldByApiNameAndLevel(api_name, level) {
      let fields = this.fieldsByApiName[api_name];
      return fields ? fields.find(field => field.level == level) || fields[0] : null;
    },
    getWorkflowStatus(entry) {
      if (entry && this.function_) {
        let statuses = entry?.attrs?.workflow_status || [];
        for (status of statuses) {
          let functionSuffix = `-${this.function_.name}`;
          if (status.endsWith(functionSuffix)) {
            return status.slice(0, status.length - functionSuffix.length);
          }
        }
      }
      return null;
    },
    noImageURL(type) {
      switch (type) {
        case 'item':
        case 'variation':
        case 'sku':
          return '/pages/tree/No_image_available.svg?v3';
        default:
          return '';
      }
    },
    formatEntry(entry) {
      return entry ? `${entry.type} ${entry.name}` : 'null';
    },
    getEntryLevel(entry, roots) {
      let level = 1;
      while (entry && !roots.includes(entry)) {
        level += 1;
        entry = entry.parent;
      }
      return level;
    },
    getFieldName(field) {
      return field.description || field.field_name;
    },
    traverse(parentType) {
      return {
        'company': ['storefront', 'workflow', 'digital asset'],
        'storefront': ['category', 'workflow', 'item', 'variation', 'sku'],
        'workflow': ['function', 'item'],
        'function': ['status', 'collaborator'],
        'status': ['item'],
        'category': ['category', 'item', 'variation', 'sku'],
        'item': ['variation', 'sku', 'upc', 'digital asset'],
        'variation': ['sku', 'upc', 'digital asset'],
        'sku': ['upc', 'digital asset'],
      }[parentType] || [];
    },
    classify(type) {
      return this.traverse(type)[0] || null;
    },
    pluralize(type) {
      return {
        'company': 'companies',
        'workflow': 'workflows',
        'storefront': 'storefronts',
        'category': 'categories',
        'item': 'items',
        'variation': 'variations',
        'sku': 'skus',
        'upc': 'upcs',
      }[type] || (
        type.endsWith('y') ? type.replace(/y$/, 'ies') :
          type.endsWith('s') ? type.replace(/s$/, 'ses') :
            type + 's');
    },
    addArticle(type) {
      return {
        'company': 'a company',
        'storefront': 'a storefront',
        'category': 'a category',
        'item': 'an item',
        'variation': 'a variation',
        'sku': 'a sku',
        'upc': 'a upc',
      }[type] || ('aouei'.indexOf(type[0]) != -1 ? `an ${type}` : `a ${type}`);
    },
    fieldGroups(type) {
      return {
        'category': ['Category Flex Field'],
        'item': ['Item Attribute'],
        'variation': ['Variation Flex Fields'],
        'sku': ['SKU Flex Fields'],
        'digital asset': ['Images and Videos', 'Item Digital Asset Flex Fields'],
      }[type] || [];
    },
    capitalize(type) {
      return _.capitalize(type);
    },
    metrics(parentType, childrenType) {
      let metrics = [];
      if (parentType == 'status' && childrenType == 'item') {
        metrics.push({
          name: 'Number of overdue items',
          calc: entry => {
            return entry.children && entry.children.filter(child =>
              child.attrs.expected_date &&
                            new Date(child.attrs.expected_date).getTime() < _.now()).length;
          },
        });
      }
      return metrics;
    },
    childrenQuery(parents, childrenType, skip, take) {
      let ids = '[' + parents.map(parent => `'${parent.id}'`).join(',') + ']';
      let parentType = parents[0].type;
      if (parentType == 'company' && childrenType == 'storefront') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:brands {
                                    report(
                                        dims: "id"
                                        vals: "name, '', '', company_id"
                                        filter1: "company_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'company' && childrenType == 'workflow') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:workflows {
                                    report(
                                        dims: "id"
                                        vals: "name, '', '', company_id"
                                        filter1: "company_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'company' && childrenType == 'digital asset') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:digital_assets {
                                    report(
                                        dims: "id"
                                        vals: "asset_name, description, link_url, company_id"
                                        filter1: "company_id in ${ids}"
                                        sort: [2])
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'workflow') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:workflow_storefronts {
                                    report(
                                        dims: "workflow.id as id, brand_id"
                                        vals: "name, brand_id"
                                        cols: "id, name, '', '', brand_id"
                                        filter1: "brand_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'item') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:catalog_storefronts {
                                    report(
                                        dims: "catalog_id, brand_id"
                                        vals: "item.item_number as name, item.name as desc, image"
                                        cols: "catalog_id, name, desc, image, brand_id"
                                        filter1: "brand_id in ${ids} && catalog_type == 'ItemMaster'"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'variation') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:catalog_storefronts {
                                    report(
                                        dims: "catalog_id, brand_id"
                                        vals: "variation.variation_number as name, '' as desc, '' as image, variation.item_master_id as item_master_id"
                                        cols: "catalog_id, name, desc, image, brand_id, item_master_id"
                                        filter1: "brand_id in ${ids} && catalog_type == 'VariationMaster'"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'sku') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:catalog_storefronts {
                                    report(
                                        dims: "catalog_id, brand_id"
                                        vals: "sku.sku_number as name, '' as desc, '' as image, sku.item_master_id as item_master_id, sku.variation_master_id as variation_master_id"
                                        cols: "catalog_id, name, desc, image, brand_id, item_master_id, variation_master_id"
                                        filter1: "brand_id in ${ids} && catalog_type == 'SkuMaster'"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'workflow' && childrenType == 'function') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:pim_workflows {
                                    report(
                                        dims: "department_id"
                                        vals: "department.department_name, '', '', workflow_id, sequence_number, data_conditions, workflow_id, days_required_to_complete, check_data_conditions, is_auto_approve, is_restartable, show_rejection_notifyees, data_conditions"
                                        filter1: "workflow_id in ${ids}"
                                        filter2: "sequence_number != 0"
                                        sort: [6,2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'workflow' && childrenType == 'item') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:items {
                                    report(
                                        dims: "id"
                                        vals: "item_number, name, image, workflow.id, workflow_status"
                                        filter1: "is_workflow_completed == '0'"
                                        filter2: "workflow.id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'function' && childrenType == 'status') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:pim_workflow_statuses {
                                    report(
                                        dims: "id"
                                        vals: "handlebars('{{department.department_name}} – {{status}}'), '', '', department.id as department_id, name as status_name"
                                        filter2: "department.id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report',
        ];
      }

      if (parentType == 'status' && childrenType == 'item') {
        let parentIndex = _(parents)
          .map(parent => [parent.attrs.status_name, parent.id])
          .fromPairs()
          .value();

        return [`
                    query {
                        dataset {
                            streams {
                                stream:items {
                                    report(
                                        dims: "id"
                                        vals: "item_number, name, image, '', workflow_status, status, start_date, expected_date"
                                        filter1: "workflow_type_id != '0'"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report',
        rows => _(rows)
          .map(row => {
            let rows = [];
            for (let status_name of row[5] || []) {
              let parent_id = parentIndex[status_name];
              if (parent_id !== undefined) {
                row = _.clone(row);
                row[4] = parent_id;
                rows.push(row);
              }
            }
            return rows;
          })
          .flatten()
          .value(),
        ];
      }

      if (parentType == 'function' && childrenType == 'collaborator') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:workflow_collaborators {
                                    report(
                                        dims: "user_id"
                                        vals: "handlebars('{{{user.first_name}}} {{{user.last_name}}}'), '', '', department_id, record_id, record_type"
                                        filter1: "department_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'workflow') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:workflow_storefronts {
                                    report(
                                        dims: "id"
                                        vals: "name, '', '', brand_id, brand_id as storefront"
                                        filter1: "brand_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' && childrenType == 'category') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:categories {
                                    report(
                                        dims: "id"
                                        vals: "name, '', '', brand_id, sequence_number"
                                        filter1: "brand_id in ${ids} && parent_id == ''"
                                        filter2: "is_deleted == '0' && is_active == '1'"
                                        sort: [6,2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'storefront' & childrenType == 'item') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:items {
                                    report(
                                        dims: "id"
                                        vals: "item_number, name, image, brand_id, workflow_status"
                                        filter1: "brand_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'category' && childrenType == 'category') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:categories {
                                    report(
                                        dims: "id"
                                        vals: "name, '', '', parent_id, sequence_number"
                                        filter1: "parent_id in ${ids}"
                                        filter2: "is_deleted == '0' && is_active == '1'"
                                        sort: [6,2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'category' && childrenType == 'item') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:categories_items {
                                    report(
                                        dims: "item_master_id"
                                        vals: "item.item_number, item.name, image, item_hierarchy_id, sequence_no, item.workflow_status as workflow_status"
                                        filter1: "item_hierarchy_id in ${ids}"
                                        filter2: "is_active == '1'"
                                        sort: [6,2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'category' && childrenType == 'variation') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:item_hierarchies_variation_masters {
                                    report(
                                        dims: "variation_master_id"
                                        vals: "variation.variation_number, item.name, image, item_hierarchy_id, sequence_no, item.workflow_status as workflow_status"
                                        filter1: "item_hierarchy_id in ${ids}"
                                        filter2: "!is_deleted"
                                        sort: [6,2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'item' && childrenType == 'variation') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:variations {
                                    report(
                                        dims: "id"
                                        vals: "variation_number, item.name, image, item_master_id, item.workflow_status as workflow_status, item_master_id, asset_colorCode"
                                        filter1: "item_master_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'variation' && childrenType == 'sku') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:skus {
                                    report(
                                        dims: "id"
                                        vals: "sku_number, item.name, image, variation_master_id, item.workflow_status as workflow_status, item_master_id, variation_master_id, asset_colorCode"
                                        filter1: "variation_master_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'item' && childrenType == 'sku') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:skus {
                                    report(
                                        dims: "id"
                                        vals: "sku_number, item.name, image, variation_master_id, item.workflow_status as workflow_status, item_master_id, variation_master_id, asset_colorCode"
                                        filter1: "item_master_id in ${ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'item' && childrenType == 'digital asset') {
        return [`
                    query {
                        dataset {
                            streams {
                                stream:sku_images {
                                    report(
                                        dims: "digital_asset_id"
                                        vals: "digital_asset.asset_name, digital_asset.description, digital_asset.link_url, item_master_id, is_primary_image, sequence, digital_asset.asset_colorCode as asset_colorCode"
                                        filter1: "item_master_id in ${ids}"
                                        sort: [7,8]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report'];
      }

      if (parentType == 'variation' && childrenType == 'digital asset') {
        let items_ids = '[' + _(parents).map(parent => parent.attrs.item_master_id).filter().uniq().map(id => `'${id}'`).join(', ') + ']';
        return [`
                    query {
                        dataset {
                            streams {
                                stream:sku_images {
                                    report(
                                        dims: "digital_asset_id"
                                        vals: "digital_asset.asset_name, digital_asset.description, digital_asset.link_url, item_master_id, is_primary_image, sequence, digital_asset.asset_colorCode as asset_colorCode"
                                        filter1: "item_master_id in ${items_ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report',
        rows => rows.map(row => {
          let item_master_id = row[4];
          let asset_colorCode = row[7];
          let parent = parents.find(parent =>
            parent.attrs.item_master_id == item_master_id &&
                            parent.attrs.asset_colorCode == asset_colorCode);

          if (parent) {
            row[4] = parent.id;
            return row;
          }
        }).filter(row => row)];
      }

      if (parentType == 'sku' && childrenType == 'digital asset') {
        let items_ids = '[' + _(parents).map(parent => parent.attrs.item_master_id).filter().uniq().map(id => `'${id}'`).join(', ') + ']';
        return [`
                    query {
                        dataset {
                            streams {
                                stream:sku_images {
                                    report(
                                        dims: "digital_asset_id"
                                        vals: "digital_asset.asset_name, digital_asset.description, digital_asset.link_url, item_master_id, is_primary_image, sequence, digital_asset.asset_colorCode as asset_colorCode"
                                        filter1: "item_master_id in ${items_ids}"
                                        sort: [2]
                                        )
                                    {
                                        rows(skip: ${skip} take: ${take})
                                        size
                                        columns { synonym }
                                    }
                                }
                            }
                        }
                    }`,
        'dataset.streams.stream.report',
        rows => rows.map(row => {
          let item_master_id = row[4];
          let asset_colorCode = row[7];
          let parent = parents.find(parent =>
            parent.attrs.item_master_id == item_master_id &&
                            parent.attrs.asset_colorCode == asset_colorCode);

          if (parent) {
            row[4] = parent.id;
            return row;
          }
        }).filter(row => row)];
      }
    },
    async runQuery(query, path) {
      let { data, errors } = await (await utils.fetch('/graphql?__runPimQuery__', {
        method: 'POST',
        headers: { 'content-type': 'application/json' },
        body: JSON.stringify({ query }),
      })).json();
      if (errors) {
        console.warn(query);
        console.warn(errors[0].message);
        throw errors[0].message;
      }
      return _.get(data, path);
    },
    async reloadChildren(parents) {
      if (_.isPlainObject(parents)) {
        parents = [parents];
      }

      for (let parent of parents) {
        this.$set(parent, 'children', null);
        this.$delete(parent, 'error');
        this.$delete(parent, 'loading');
      }

      this.loadChildren(parents);
    },
    async loadChildren(parents, childrenType, skip = 0, take = 1000000) {
      if (_.isPlainObject(parents)) {
        parents = [parents];
      }

      if (_.isEmpty(parents)) {
        return;
      }

      let parentType = parents[0].type;
      childrenType = childrenType || parents[0].childrenType || this.classify(parentType);

      if (childrenType == null) {
        for (let parent of parents) {
          this.$set(parent, 'children', null);
          this.$delete(parent, 'error');
          this.$delete(parent, 'loading');
        }
        return;
      }

      let loading = utils.randomId();

      parents = parents.filter(parent =>
        (parent.children == null || parent.childrenType != childrenType)
                && !parent.loading);

      if (_.isEmpty(parents)) {
        return;
      }

      let parentIndex = _.fromPairs(parents.map(parent => [parent.id, parent]));
      let query = this.childrenQuery(parents, childrenType, skip, take);

      if (!query) {
        return;
      }

      for (let parent of parents) {
        this.$set(parent, 'loading', loading);
        this.$delete(parent, 'error');
        this.$delete(parent, 'children');
      }

      try {
        let { rows, columns } = await this.runQuery(query[0], query[1]);
        if (_.isFunction(query[2])) {
          rows = query[2](rows);
        }

        let childrens = {};
        for (let row of rows) {
          let [id, name, desc, image, parentId] = row;
          if (!image) {
            image = this.noImageURL(childrenType);
          }
          let attrs = _(columns.slice(5))
            .map(column => column.synonym)
            .zip(row.slice(5))
            .fromPairs()
            .value();

          let children = childrens[parentId];
          if (!children) {
            children = [];
            childrens[parentId] = children;
          }
          let parent = parentIndex[parentId];
          let key = `${parent.key || parent.id}-${id}`;
          let type = childrenType;
          let entry = {
            id,
            key,
            name,
            desc,
            type,
            image,
            attrs,
            parent,
            children: null,
          };
          children.push(entry);
        }
        let preload = [];
        for (let parent of parents) {
          if (parent.loading == loading) {
            let children = childrens[parent.id] || [];
            if (parent.opened) {
              this.$set(parent, 'children', children);
              for (let child of parent.children) {
                preload.push(child);
              }
            } else {
              children = Object.freeze(children);
              this.$set(parent, 'children', children);
            }
            this.$set(parent, 'childrenType', childrenType);
            this.$set(parent, 'loadedAt', _.now());
            this.$delete(parent, 'loading');
            this.$delete(parent, 'error');
          }
        }
        this.loadChildren(preload);
      } catch (ex) {
        for (let parent of parents) {
          if (parent.loading == loading) {
            this.$delete(parent, 'loading');
            this.$set(parent, 'error', ex.message);
          }
        }
      }
    },
  },
};
