<template>
  <span @click="translate"><slot/>{{text}}</span>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  props: {
    value: { type: String },
  },
  data() {
    return { translated_value: utils.l10n(this.value) };
  },
  mounted() {
    utils.bridge.bind('l10n', this.changed);
  },
  beforeDestroy() {
    utils.bridge.unbind('l10n', this.changed);
  },
  computed: {
    text() {
      if (!_.isEmpty(this.$slots)) {
        return null;
      }
      return this.translated_value ? this.translated_value.replaceAll(/{[a-zA-Z_0-9-]+}/g, (match) => this.$attrs[match.slice(1,-1)] || `{${match.slice(1,-1)}}`) : '<empty>';
    },
  },
  watch: {
    value() {
      this.translated_value = utils.l10n(this.value);
    },
  },
  methods: {
    translate(e) {
      if (!e.altKey) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      utils.prompt_l10n(this.value);
    },
    changed(value) {
      if (this.value == value || value == null) {
        this.translated_value = utils.l10n(this.value);
      }
    },
  },
};
</script>
