<template>
  <span>{{resolvedValue}}</span>
</template>
<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: { default: null },
    placeholder: { default: null },
  },
  data() {
    return {
      resolvedValue: null,
    };
  },
  created() {
    this.resove();
  },
  methods: {
    async resove() {
      let value = this.value;
      if (this.placeholder != null) {
        this.resolvedValue = this.placeholder;
      }
      try {
        let resolvedValue = await value;
        if (value === this.value) {
          this.resolvedValue = resolvedValue;
        }
      } catch (ex) {
        console.warn(ex);
      }
    },
  },
  watch: {
    value() {
      this.resove();
    },
  },
};
</script>
