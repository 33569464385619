<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <ao-popup
    :parent="parent"
    xPosition="left"
    :scrollableAreaSelector="scrollableAreaSelector"
    class="config-control-popup"
  >
    <template #header>
      <div class="header-row">
        <h2 class="header-row__title">
          <l10n value="Фильтры и сортировка" />
        </h2>
        <div class="header-row__buttons">
          <button
            type="button"
            class="header-row__button add-button mini-button"
            :disabled="isConfigEmpty || !endpoint"
            @click="modalVisibility.saveConfig = true"
          >
            <plus-icon />
          </button>
          <button
            type="button"
            class="header-row__button mini-button close-button"
            @click="hideElement"
          >
            <plus-icon />
          </button>
        </div>
      </div>
    </template>

    <template>
      <div class="saved-configs" v-if="configItems.length > 0">
        <h3 class="saved-configs__title">
          <l10n value="Сохранённые фильтры" />
        </h3>
        <ul class="saved-configs__list">
          <li
            v-for="item in configItems"
            :key="item.id"
            class="saved-configs__list-item"
          >
            <button
              type="button"
              class="saved-configs__item-button"
              :class="{ active: activeConfigId === item.id }"
              @click="selectConfig(item)"
            >
              <span class="check-icon">
                <check-large-icon />
              </span>
              <span class="saved-configs__item-text">{{ item.name }}</span>
              <button
                type="button"
                class="saved-configs__remove-button mini-button"
                :disabled="!endpoint"
                @click.stop="onDeleteConfig(item)"
              >
                <trash-icon />
              </button>
            </button>
          </li>
        </ul>
      </div>

      <save-modal
        v-model="modalVisibility.saveConfig"
        :tableConfigParams="tableConfigParams"
        :endpoint="endpoint"
        @onConfigSuccessfulSaved="handleSuccessfulSave"
      />

      <delete-modal
        v-model="modalVisibility.deleteConfig"
        :config="configForDelete"
        :endpoint="endpoint"
        @onConfigSuccessfulDeleted="handleSuccessfulDelete"
      />
    </template>

    <template #footer>
      <ao-button
        class="reset-button"
        :disabled="isConfigEmpty"
        @click="confirmReset"
      >
        <l10n value="Сбросить всё" />
      </ao-button>
    </template>
  </ao-popup>
</template>

<script>
const AoPopup = require('../ao-popup.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const SaveModal = require('../custom-modals/ao-table-config-save-modal.vue').default;
const DeleteModal = require('../custom-modals/ao-table-config-delete-modal.vue').default;
const PlusIcon = require('../../assets/icons/plus-icon.vue').default;
const TrashIcon = require('../../assets/icons/trash-icon.vue').default;
const CheckLargeIcon = require('../../assets/icons/check-large-icon.vue').default;

module.exports = {
  model: {
    prop: 'isVisible',
    event: 'onChangeVisibility',
  },

  components: {
    AoPopup,
    AoButton,
    SaveModal,
    DeleteModal,
    PlusIcon,
    CheckLargeIcon,
    TrashIcon,
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    parent: {
      type: [Object, Element],
      required: false,
      default: () => null,
    },
    scrollableAreaSelector: {
      type: String,
      required: false,
      default: null,
    },
    isConfigEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableConfigParams: {
      type: Object,
      required: false,
      default: null,
    },
    savedConfigId: {
      type: Number,
      required: false,
      default: null,
    },
    endpoint: {
      type: String,
      required: false,
      default: '',
    },
  },

  data() {
    return {
      modalVisibility: {
        saveConfig: false,
        deleteConfig: false,
      },
      configItems: [],
      activeConfigId: this.savedConfigId ?? null,
      configForDelete: null,
    };
  },

  watch: {
    savedConfigId(newId) {
      this.activeConfigId = newId;
    },
  },

  created() {
    this.fetchConfigs();
  },

  methods: {
    async fetchConfigs() {
      if (!this.isFormInvalid && this.endpoint) {
        try {
          const response = await fetch(this.endpoint);

          if (response.ok) {
            const data = await response.json();
            this.configItems = data?.length
              ? data.map(({ id, name, config }) => ({ id, name, config }))
              : [];
          } else {
            this.createNotification('Произошла ошибка при получении списка конфигураций', 'error');
          }
        } catch (error) {
          this.createNotification(error.message, 'error');
        }
      }
    },
    confirmReset() {
      this.$emit('onReset');
      this.activeConfig = null;
    },
    hideElement() {
      this.$emit('onChangeVisibility', false);
    },
    selectConfig(config) {
      this.activeConfigId = config.id;
      this.$emit('onSelectConfig', config);
    },
    handleSuccessfulSave(newConfig) {
      this.configItems = [...this.configItems, newConfig];
      this.activeConfigId = newConfig.id;
      this.$emit('onSelectConfig', { id: newConfig.id });
    },
    onDeleteConfig(config) {
      this.modalVisibility.deleteConfig = true;
      this.configForDelete = config;
    },
    handleSuccessfulDelete(removedId) {
      this.configItems = [...this.configItems].filter((item) => item.id !== removedId);
      this.configForDelete = null;

      if (this.activeConfigId === removedId) {
        this.confirmReset();
      }
    },
  },
};
</script>

<style scoped>
  .config-control-popup >>> .ao-popup__main {
    padding: 0;
  }

  .mini-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  .header-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding-bottom: 12px;
    border-bottom: 1px solid #E9E9E9;
  }

  .header-row__title {
    margin: 0;
    font-size: 16px;
    font-weight: 600;
  }

  .header-row__buttons {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  .header-row__button svg {
    width: 17px;
    height: 17px;
  }

  .close-button svg {
    transform: rotate(45deg);
  }

  .header-row__button.add-button {
    margin-left: auto;
  }

  .header-row__button:disabled {
    color: #D5D5D5;
    cursor: not-allowed;
  }

  .reset-button {
    min-width: 250px;
  }

  .saved-configs__title {
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    padding: 16px;
    margin: 0;
  }

  .saved-configs__list {
    width: 300px;
    max-height: 250px;
    overflow-y: auto;
    margin: 0;
    padding: 0
  }

  .saved-configs__item-text {
    overflow: hidden;
    text-wrap: wrap;
    text-overflow: ellipsis;
  }

  .saved-configs__list-item {
    list-style: none;
    margin: 0;
  }

  .saved-configs__item-button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 12px;
    padding-right: 20px;
    outline: none;
    border: none;
    font-size: 12px;
    cursor: pointer;
    background-color: transparent;
  }

  .saved-configs__item-button .check-icon {
    visibility: hidden;
  }

  .saved-configs__item-button.active .check-icon {
    visibility: visible;
  }

  .saved-configs__item-button:hover {
    background-color: #F7F7F7;
  }

  .saved-configs__remove-button {
    color: #AFAFAF;
    margin-left: auto;
  }

  .saved-configs__remove-button:hover {
    color: #F28E93;
  }

  .saved-configs__remove-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
</style>
