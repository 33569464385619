<template>
  <div class="pim">
    <div class="pim-main" key="main" :class="{'has-side': sideShow, 'has-foot': footShow}">
      <gp-news id="news"/>
      <gp-portal to="pim-functions">
        <gp-select
          :value="function_"
          :options="functions"
          placeholder="Function"
          @change="function_ = $event"
        />
      </gp-portal>

      <div class="pim-main-head" key="head">
        <portal-target name="pim-functions"/>
        <pim-tree
          mode="compact"
          id="headTree"
          ref="headTree"
          :config="config"
          :search="search"
          :fields="fields"
          :locales="locales"
          :statuses="statuses"
          :storefronts="storefronts"
          :workflow="workflow"
          :roots="headRoots"
          @focus="headFocus = $event"
          @selection="headSelection = $event"
          @navigate="headRoots = $event"
        />
      </div>
      <div class="pim-main-body" key="body">
        <pim-tree
          id="bodyTree"
          ref="bodyTree"
          :config="config"
          :search="search"
          :fields="fields"
          :locales="locales"
          :statuses="statuses"
          :storefronts="storefronts"
          :workflow="workflow"
          :roots="bodyRoots"
          @focus="bodyFocus = $event"
          @selection="bodySelection = $event"
          @context="setAsContext"
          @open-in-tab="openInTab"
          :actions="[{
            icon: 'link',
            name: 'Create a permalink',
            call: () => createPermalink(true),
          }, {
            icon: 'user',
            name: 'Switch function to ...',
            children: functions.map(f => ({
              icon: function_ && f.id == function_.id ? 'check' : 'square',
              name: f.name,
              call: () => function_ = f
            }))
          }]"
        />
      </div>
      <div class="pim-main-tabs" key="tabs">
        <pim-tabs
          v-model="footMode"
          :tabs="{
            items: 'Items',
            variations: 'Variations',
            simulation: 'Simulation',
          }"
          @click="footShow = !footShow">
          <feather-icon :name="footShow ? 'chevrons-down' : 'chevrons-up'"/>
        </pim-tabs>
      </div>
      <pim-hierarchies-foot
        key="foot"
        v-if="footShow"
        :mode="footMode"
        :show="footShow"
        :entry="bodyFocus"
        :config="config"
        :username="username"
        :search="search"
        :fields="fields"
      />
    </div>
    <div class="pim-side-tabs">
      <pim-tabs
        mode="vertical"
        v-model="sideMode"
        :tabs="sideTabs"
        @click="sideShow = !sideShow">
        <feather-icon :name="sideShow ? 'chevrons-right' : 'chevrons-left'"/>
      </pim-tabs>
    </div>
    <div class="pim-side" key="side" v-if="sideShow">
      <pim-fields
        v-if="sideMode == 'fields'"
        :locales="locales"
        :statuses="statuses"
        :storefronts="storefronts"
        :fields="fields_"
        :templates="templates"
        @change="fields = $event"
        :darkTheme="darkTheme"
      />
      <pim-attributes-ex
        v-if="
          sideMode == 'attributes' &&
            entries.length &&
            entries[0].type != 'company' &&
            entries[0].type != 'storefront'
        "
        :locales="locales"
        :statuses="statuses"
        :storefronts="storefronts"
        :functions="functions"
        :function_="function_"
        :focus="focus"
        @focus="$refs.bodyTree.focus = $event"
        :entries="entries"
        :fields="fields"
        :username="username"
        :darkTheme="darkTheme"
        :pluralize="type => ({
          'company': 'companies',
          'storefront': 'storefronts',
          'category': 'categories',
          'item': 'items',
          'variation': 'variations',
          'sku': 'skus',
          'upc': 'upcs',
        }[type] || type)"
        :romanceCopyField="romanceCopyField"
        :romanceCopyPrompt="romanceCopyPrompt"
      />
      <pim-hierarchies-side
        v-if="
          sideMode == 'filters' ||
            sideMode == 'strategies' ||
            sideMode == 'assignment'"
        :mode="sideMode"
        :entry="entry"
        :username="username"
        :search="search"
        :fields="fields"
      />
      <div class="pim-tasks" v-if="sideMode == 'tasks'">
        <h2>Tasks</h2>
        <gp-tasks
          product="pim"
          :username="username"
          :darkTheme="darkTheme"
        />
      </div>
      <pim-workflow
        v-if="sideMode == 'workflow'"
        :settings="workflow.settings"
        :nodeTemplate="workflow.nodeTemplate"
        :linkTemplate="workflow.linkTemplate"
        :nodeTemplateMap="workflow.nodeTemplateMap"
        :linkTemplateMap="workflow.linkTemplateMap"
        :diagramLayout="workflow.diagramLayout"
        :paletteLayout="workflow.paletteLayout"
        :diagramModel="workflow.diagramModel"
        :paletteModel="workflow.paletteModel"
        :autoheight="workflow.autoheight"
      />
      <pim-function
        v-if="sideMode == 'function' && bodyFocus && bodyFocus.type == 'function'"
        :key="bodyFocus.id"
        :entry="bodyFocus"
        :users="users"
        :fields="fields"
        :storefronts="storefronts"
      />
    </div>
    <pim-stream
      stream="derived_templates"
      :dims="['sku_master_field_id']"
      :vals="[
        'template_file_name',
        'template_file_data',
        'template_content_type',
        'metadata_file_name',
        'metadata_content_type',
      ]"
      filter2="is_active_version"
      @change="templates = $event"
    />
    <pim-stream
      stream="fields"
      :vals="[
        'field_name',
        'api_group_name',
        'api_name',
        'brand_id',
        'derived_expr',
        'derived_type',
        'description',
        'field_type',
        'group_name',
        'has_locales',
        'has_storefronts',
        'is_attribute',
        'is_derived',
        'is_locked',
        'is_readonly',
        'is_reverse',
        'is_searchable',
        'is_template_driven',
        'level',
        'locale_id',
        'lookup_id',
        'overridable',
        'parent_id',
        'sequence_order',
        'template_method',
      ]"
      @change="fields_ = $event"/>

    <pim-stream
      stream="locales"
      @change="locales = $event"/>

    <pim-stream
      stream="brands"
      @change="storefronts = $event"/>

    <pim-stream
      stream="departments"
      :vals="['department_name as name', 'department_code', 'department_name']"
      @change="functions = $event"/>

    <pim-stream
      stream="pim_workflow_statuses"
      :vals="[
        'name',
        'status',
        'department.id as department_id',
      ]"
      @change="statuses = $event"/>

    <pim-stream
      stream="department_field_relations"
      :vals="[
        'department_id',
        'sku_master_field_id',
        'mandatory',
        'viewable',
        'editable',
        'mass_update',
      ]"
      @change="relations = $event"/>

    <pim-stream
      stream="users"
      :vals="[
        'first_name',
        'last_name',
        'job_title',
        'department_id',
        'state',
        'active',
        'is_active',
      ]"
      filter2="state == 'active'"
      @change="users = $event"/>

    <pim-permalink
      ref="permalink"
      :anchor="$refs.bodyTree ? $refs.bodyTree.$refs.menu.$el : null"
    />

    <pim-image-over/>
  </div>
</template>
<script>
let utils = require('../my-utils');
require('../chosen.jquery.js');

module.exports = {
  props: {
    path: { type: String },
    hash: { type: String },
    username: { type: String },
    darkTheme: { type: Boolean },
    config: { type: Object },
    search: {
      type: Object,
      default: () => ({
        threshold: 0,
        minMatchCharLength: 2,
        isCaseSensitive: false,
        includeMatches: true,
        ignoreLocation: true,
        useExtendedSearch: true,
        findAllMatches: true,
        shouldSort: false,
        includeScore: true,
        keys: ['name'],
      }),
    },
    workflow: { type: Object },
    romanceCopyField: { type: String },
    romanceCopyPrompt: { type: [Function, String] },
  },
  data() {
    let headRoots = [];
    let bodyRoots = [];
    let permalink = null;
    if (this.hash?.startsWith('permalink=')) {
      permalink = this.hash.split('=')[1];
    }

    if (permalink == null) {
      headRoots = [{
        id: '1',
        type: 'company',
        name: 'Tapestry',
        opened: true,
        focused: true,
        children: null,
      }];
    }

    return {
      fields_: JSON.parse(localStorage['pim-fields'] || '[]'),
      locales: JSON.parse(localStorage['pim-locales'] || '[]'),
      statuses: JSON.parse(localStorage['pim-statuses'] || '[]'),
      relations: JSON.parse(localStorage['pim-relations'] || '[]'),
      function_: JSON.parse(localStorage['pim-function'] || 'null'),
      functions: JSON.parse(localStorage['pim-functions'] || '[]'),
      storefronts: JSON.parse(localStorage['pim-storefronts'] || '[]'),
      users: JSON.parse(localStorage['pim-users'] || '[]'),
      templates: [],
      permalink,
      headFocus: null,
      bodyFocus: null,
      footShow: localStorage['pim-foot-show'] === 'true',
      sideShow: localStorage['pim-side-show'] !== 'false',
      focusFoot: null,
      headSelection: null,
      bodySelection: null,
      headRoots,
      bodyRoots,
      footMode: localStorage['pim-foot-mode'] || 'items',
      sideMode: localStorage['pim-side-mode'] || 'attributes',
      history: [],
    };
  },
  async mounted() {
    window.pim = this;
    window.gptable = this;
    if (this.permalink) {
      this.loadPermalink(this.permalink);
    }
  },
  watch: {
    hash() {
      if (this.hash?.startsWith('permalink=')) {
        let permalink = this.hash.split('=')[1];
        this.loadPermalink(permalink);
      }
    },
    fields_() {
      localStorage['pim-fields'] = JSON.stringify(this.fields_);
    },
    locales() {
      localStorage['pim-locales'] = JSON.stringify(this.locales);
    },
    statuses() {
      localStorage['pim-statuses'] = JSON.stringify(this.statuses);
    },
    relations() {
      localStorage['pim-relations'] = JSON.stringify(this.relations);
    },
    function_() {
      localStorage['pim-function'] = JSON.stringify(this.function_);
    },
    functions() {
      if (this.function == null) {
        this.function = this.functions[0];
      }
      localStorage['pim-functions'] = JSON.stringify(this.functions);
    },
    storefronts() {
      localStorage['pim-storefronts'] = JSON.stringify(this.storefronts);
    },
    users() {
      localStorage['pim-users'] = JSON.stringify(this.users);
    },
    footMode() {
      if (this.footMode) {
        this.footShow = true;
      }
      localStorage['pim-foot-mode'] = this.footMode;
    },
    sideMode() {
      if (this.sideMode) {
        this.sideShow = true;
      }
      localStorage['pim-side-mode'] = this.sideMode;
    },
    footShow() {
      localStorage['pim-foot-show'] = this.footShow;
    },
    sideShow() {
      localStorage['pim-side-show'] = this.sideShow;
    },
    headFocus() {
      if (this.headFocus) {
        let root = this.clone(this.headFocus);
        root.shadow = { parent: this.headFocus.parent };
        root.focused = true;
        this.bodyRoots = [root];
      } else {
        this.bodyRoots = [];
      }
    },
  },
  computed: {
    fields() {
      let relations = _(this.relations)
        .filter(relation => relation.department_id == this.function_?.id)
        .map(relation => [relation.sku_master_field_id, relation])
        .fromPairs()
        .value();

      let fields = [];
      let fieldsIndex = _(this.fields_).map(field => [field.id, field]).fromPairs().value();
      for (let field of this.fields_) {
        field.name = field.description || field.name;
        field.parent = fieldsIndex[field.parent_id];
        if (field.has_storefronts && !field.storefront_id) {
          for (let storefront of this.storefronts) {
            if (field.name.endsWith(` - ${storefront.name}`)) {
              field.storefront_id = storefront.id;
            }
          }
        }
        let relation = relations[field.id];
        field = _.clone(field);
        field.relation = relation;
        fields.push(Object.freeze(field));
      }
      return Object.freeze(fields);
    },
    focus() {
      return this.bodyFocus;
    },
    entries() {
      let focus = this.focus;
      let selection = this.bodySelection;
      return selection && selection.length ? selection : (focus ? [focus] : []);
    },
    sideTabs() {
      let tabs = {};
      if (this.entries.length &&
                this.entries[0].type != 'company' &&
                this.entries[0].type != 'storefront' ||
                this.sideMode == 'attributes') {
        tabs.attributes = 'Attributes';
      }
      tabs.fields = 'Fields';
      if (this.bodyFocus?.type == 'category') {
        tabs.filters = 'Filters';
        tabs.strategies = 'Strategies';
        tabs.assignment = 'Assignment';
      }
      tabs.tasks = 'Tasks';
      if (this.bodyFocus &&
                this.bodyFocus.type == 'function') {
        tabs.function = 'Function';
      }
      return tabs;
    },
  },
  methods: {
    loadPermalink(permalink) {
      this.$refs.permalink
        .loadPermalink(permalink)
        .then(config => {
          let { headRoots, bodyRoots } = config;
          let loop = (entry, parent) => {
            if (parent) {
              entry.parent = parent;
            }
            if (entry.children) {
              entry.children.forEach(child => loop(child, entry));
              if (!entry.opened) {
                entry.children = Object.freeze(entry.children);
              }
            }
          };
          headRoots.forEach(loop);
          bodyRoots.forEach(loop);
          this.headRoots = headRoots;
          _.defer(() => this.bodyRoots = bodyRoots);
        });
    },
    createPermalink(show, state) {
      let serialize = entries => {
        return entries.map(entry => {
          entry = _.omit(_.clone(entry), [
            'parent',
            'shadow']);
          if (entry.children) {
            entry.children = serialize(entry.children);
          }
          return entry;
        });
      };
      let getPath = entry => {
        let path = [];
        while (entry) {
          path.splice(0, 0, _.pick(entry, [
            'id',
            'type',
            'name',
          ]));
          entry = entry.parent || entry.shadow?.parent;
        }
        return path;
      };

      let { headRoots, bodyRoots } = state || {
        headRoots: this.headRoots,
        bodyRoots: this.bodyRoots,
      };

      headRoots = serialize(headRoots);
      bodyRoots = serialize(bodyRoots);

      let config = { headRoots, bodyRoots };

      return show ?
        this.$refs.permalink.createAndShowPermalink(config) :
        this.$refs.permalink.createPermalink(config);
    },
    clone(entry) {
      entry = _.clone(entry);
      entry.parent = null;
      entry.opened = true;
      entry.focused = false;
      entry.selected = false;
      entry.loading = false;
      entry.children = null;
      return entry;
    },
    cloneDeep(entry) {
      entry = _.clone(entry);
      entry.parent = null;
      entry.opened = true;
      entry.focused = false;
      entry.selected = false;
      entry.loading = false;
      if (entry.children != null) {
        entry.children = entry.children.map(child => {
          child = this.cloneDeep(child);
          child.parent = entry;
        });
      }
      return entry;
    },
    async openInTab(focus) {
      let permalink = await this.createPermalink(false, {
        headRoots: this.bodyRoots,
        bodyRoots: [focus],
      });
      utils.bridge.trigger('new-tab', {
        id: utils.randomId(),
        path: permalink,
        name: focus.name,
        type: 'page',
        lazy: true,
      });
    },
    setAsContext(focus) {
      let roots = this.bodyRoots;
      let loop = entry => {
        if (entry == focus) {
          this.$set(entry, 'focused', true);
        } else if (entry.focused) {
          this.$delete(entry, 'focused');
        }
        entry.children?.forEach(loop);
      };
      roots.forEach(loop);
      this.headRoots = roots;
      // let bodyRoots = [this.cloneDeep(focus)]
      // bodyRoots[0].focused = true
      // this.bodyRoots = bodyRoots

      // this.$refs.headTree.focus = focus
      // this.$refs.bodyTree.focus = null
      // Vue.nextTick(() => {
      // this.$refs.bodyTree.focus = this.bodyRoots[0]
      // })
    },
  },
};
</script>
