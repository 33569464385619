<!-- eslint-disable vuejs-accessibility/heading-has-content -->
<template>
  <autoorder-modal
    class="config-save-modal"
    modalTitle="Сохранить фильтры"
    closable
    closeButton
    :modalVisible="isVisible"
    @onClose="handleDiscard"
  >
    <template #main>
      <h2 class="config-save-modal__input-title">
        <l10n value="Название фильтра" />
      </h2>
      <ao-input
        v-model="filterName"
        size="small"
        :maxLength="200"
        :invalid="!getTrimmedString(filterName) && tryToSend"
        placeholder="Введите название фильтра"
        class="config-save-modal__input"
      />
    </template>

    <template #footer>
      <div class="config-save-modal__buttons-row">
        <ao-button
          type="secondary"
          :disabled="isLoading"
          @click="handleDiscard"
        >
          <l10n value="Отмена" />
        </ao-button>
        <ao-button
          :disabled="isFormInvalid && tryToSend || isLoading"
          @click="submitForm"
        >
          <l10n value="Сохранить" />
        </ao-button>
      </div>
    </template>
  </autoorder-modal>
</template>

<script>
const AutoorderModal = require('../autoorder-modal.vue').default;
const AoButton = require('../shared/ao-button.vue').default;
const AoInput = require('../shared/ao-input.vue').default;
const { getTrimmedString, getSortingFilteringRequestBody } = require('../../utils/formatters');

module.exports = {
  components: {
    AutoorderModal,
    AoButton,
    AoInput,
  },

  model: {
    prop: 'isVisible',
    event: 'onVisibleChange',
  },

  props: {
    isVisible: {
      type: Boolean,
      required: false,
      default: false,
    },
    tableConfigParams: {
      type: Object,
      required: false,
      default: () => {},
    },
    endpoint: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      tryToSend: false,
      isLoading: false,
      filterName: '',
    };
  },

  computed: {
    isFormInvalid() {
      return !getTrimmedString(this.filterName);
    },
    requestBody() {
      return {
        name: getTrimmedString(this.filterName),
        config: getSortingFilteringRequestBody(this.tableConfigParams),
      };
    },
  },

  watch: {
    isVisible(newValue) {
      if (!newValue) {
        this.clearForm();
      }
    },
  },

  methods: {
    handleDiscard() {
      this.$emit('onVisibleChange', false);
    },
    async submitForm() {
      this.tryToSend = true;

      if (!this.isFormInvalid) {
        try {
          this.isLoading = true;

          const options = {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json;charset=utf-8',
            },
            body: JSON.stringify(this.requestBody),
          };

          const response = await fetch(this.endpoint, options);

          if (response.ok) {
            const data = await response.json();
            const { id, name, config } = data;
            this.$emit('onConfigSuccessfulSaved', { id, name, config });
            this.$emit('onVisibleChange', false);
          } else {
            this.createNotification('Произошла ошибка при сохранении конфигураций', 'error');
          }
        } catch (error) {
          this.createNotification(error.message, 'error');
        } finally {
          this.isLoading = false;
        }
      }
    },
    clearForm() {
      this.filterName = '';
      this.tryToSend = false;
    },
    getTrimmedString,
  },
};
</script>

<style scoped>
  .config-save-modal >>> .custom-modal {
    min-width: 420px;
    width: 30%;
  }

  .config-save-modal__input {
    width: 100%;
  }

  .config-save-modal__input-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
  }

  .config-save-modal__buttons-row {
    display: flex;
    align-items: center;
    gap: 10px;
  }
</style>
