<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <input
    v-model="localValue"
    :type="type"
    :disabled="disabled"
    class="ao-input"
    :class="[inputClasses, { invalid }]"
    @input="inputEventHandle"
  />
</template>

<script>
module.exports = {
  model: {
    prop: 'value',
    event: 'onValueChange',
  },

  props: {
    value: {
      type: [Number, String],
      required: false,
      default: '',
    },
    type: {
      type: String, // "text" | "number" | "date"
      required: false,
      default: 'text',
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalid: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String, // "large" | "small"
      required: false,
      default: 'large',
    },
    maxLength: {
      type: Number,
      required: false,
      default: null,
    },
    onlyInteger: {
      type: Boolean,
      required: false,
      default: false,
    },
    fractionalMaxLength: {
      type: Number,
      required: false,
      default: null,
    },
    integerMaxLength: {
      type: Number,
      required: false,
      default: null,
    },
  },

  data() {
    return {
      localValue: this.value || '',
    };
  },

  computed: {
    inputClasses() {
      return `ao-input_${this.size}`;
    },
  },

  watch: {
    localValue(newValue) {
      let tempValue = newValue;

      if (this.type === 'text') {
        if (this.maxLength && newValue?.length > this.maxLength) {
          tempValue = newValue.slice(0, this.maxLength);
          this.localValue = tempValue;
        }

        this.$emit('onValueChange', tempValue);
      }

      if (this.type === 'number') {
        if (this.onlyInteger) {
          const num = parseInt(newValue, 10);
          tempValue = Object.is(num, NaN) ? String(tempValue).slice(0, tempValue.length - 1) : num;
          tempValue = this.integerMaxLength
            ? String(tempValue).slice(0, this.integerMaxLength)
            : tempValue;
        } else if (tempValue.includes('.')) {
          let [start, end] = String(this.localValue).split('.');
          start = this.integerMaxLength ? String(start).slice(0, this.integerMaxLength) : start;
          end = end && this.fractionalMaxLength ? String(end).slice(0, this.fractionalMaxLength) : '';
          tempValue = `${start}.${end}`;
        } else {
          tempValue = this.integerMaxLength
            ? String(tempValue).slice(0, this.integerMaxLength)
            : tempValue;
        }

        this.localValue = tempValue;
        this.$emit('onValueChange', tempValue);
      }

      if (this.type === 'date') {
        this.$emit('onValueChange', tempValue);
      }
    },

    value(newValue) {
      this.localValue = newValue ?? '';
    },
  },

  methods: {
    inputEventHandle() {
      this.$emit('onInput');
    },
  },
};
</script>

<style scoped>
.ao-input {
  border: none;
  outline: none;
  font-size: 14px;
  line-height: 18px;
  padding: 12px;
  font-weight: 500;
  border-radius: 8px;
  background: #f7f7f7;
  transition: background-color 0.15s ease-in-out;
}

.ao-input_small {
  padding-top: 7px;
  padding-bottom: 7px;
}

.ao-input_large {
  padding-top: 14px;
  padding-bottom: 14px;
}

.ao-input:focus {
  outline: 1px solid #5295D5;
}

.ao-input.invalid,
.ao-input.invalid:hover,
.ao-input.invalid:focus {
  background-color: #FAE6EB;
}

.ao-input:not(:disabled):hover {
  background-color: #E9E9E9;
}

.ao-input::placeholder {
  color: #D4D4D4;
}

.ao-input:disabled {
  cursor: not-allowed;
}
</style>
