<template>
  <div class="my-basket">
    <a href="javascript:void(0)" @click="show=true" v-if="count>0">
      <feather-icon name="shopping-cart"/>
    </a>
    <feather-icon v-else name="shopping-cart"/>
    <span class="badge badge-pill badge-secondary">{{count}}</span>
    <my-dialog title="Корзина" v-if="show" @close="show=false">
      <table class="table table-sm table-striped table-hover">
        <thead>
          <tr>
            <th>Код</th>
            <th>Наименование</th>
            <th>Количество</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items">
            <td>{{item.row[0]}}</td>
            <td>{{item.row[1]}} {{item.row[2]}}</td>
            <td>{{item.weight}}</td>
          </tr>
        </tbody>
      </table>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary"
          :disabled="count==0"
          @click="show=false">Оформить заказ</button>
        <button
          type="button"
          class="btn btn-secondary"
          :disabled="count==0"
          @click="items={}; show=false">Очистить</button>
      </template>
    </my-dialog>
    <div ref="control" v-show="focus" class="my-basket-control">
      <div class="form-group">
        <label>Товар</label>
        <table v-if="focus">
          <tbody>
            <tr v-for="column, i in focus.columns" v-if="focus.row[i]">
              <td>{{column.name}}</td>
              <td>{{focus.row[i]}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="form-group">
        <label>Количество</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <!-- <button class="btn btn-outline-secondary" @click="weight=weight-100">-100</button> -->
            <button class="btn btn-outline-secondary" @click="weight=weight-10">-10</button>
            <button class="btn btn-outline-secondary" @click="weight=weight-1">-1</button>
          </div>
          <input class="form-control" v-model="weight" type="number"/>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" @click="weight=weight+1">+1</button>
            <button class="btn btn-outline-secondary" @click="weight=weight+10">+10</button>
            <!-- <button class="btn btn-outline-secondary">+100</button> -->
          </div>
        </div>
      </div>
      <div class="form-group">
        <label>Комментарий к заказу</label>
        <textarea class="form-control"></textarea>
      </div>
      <div class="form-group my-basket-control-buttons">
        <button class="btn btn-primary" @click="add2" :disabled="weight==0">Добавить в корзину</button>
        <button class="btn btn-secondary" @click="focus=null">Отмена</button>
      </div>
    </div>
  </div>
</template>
<script>
module.exports = {
  data() {
    return {
      items: {},
      show: false,
      focus: null,
      weight: 0,
    };
  },
  mounted() {
    window.basket = this;
  },
  watch: {
    weight() {
      if (this.weight < 0) {
        this.weight = 0;
      }
    },
  },
  computed: {
    count() {
      let count = 0;
      for (key of _.keys(this.items)) {
        count += 1;
      }
      return count;
    },
  },
  methods: {
    add(key, row, columns, e) {
      this.focus = { key, row, columns };
      this.weight = 0;
      if (key in this.items) {
        this.weight = this.items[key].weight;
      }
      this.moveToPointer(e);
    },
    add2() {
      let { key, row, columns } = this.focus;
      let weight = this.weight;
      if (this.items[key]) {
        weight += this.items[key].weight;
      }
      let items = {};
      items[key] = { row, columns, weight };
      this.items = _.assign({}, this.items, items);
      this.focus = null;
    },
    moveToPointer(e) {
      let width = window.innerWidth;
      let height = window.innerHeight;
      if (e.pageX < width - 300) {
        this.$refs.control.style.left = `${e.pageX+15}px`;
        this.$refs.control.style.right = '';
      } else {
        this.$refs.control.style.left = '';
        this.$refs.control.style.right = `${width-e.pageX+15}px`;
      }
      if (e.pageY < height - 380) {
        this.$refs.control.style.top = `${e.pageY+15}px`;
        this.$refs.control.style.bottom = '';
      } else {
        this.$refs.control.style.top = '';
        this.$refs.control.style.bottom = `${height-e.pageY+15}px`;
      }
    },
  },
};
</script>
<style>
.my-basket-control {
    position: fixed;
    z-index: 10;
    border: 1px solid gray;
    border-radius: 2px;
    padding: 15px 15px;
    background-color: white;
    color: #444;
    font-size: 13px;
}
.my-basket-control table {
    width: 100%;
}
.my-basket-control label {
    font-weight: bold;
    text-align: center;
    width: 100%;
}
.my-basket-control input {
    text-align: center;
    width: 40px!important;
}
.my-basket-control td:first-child {
    padding-right: 10px;
    text-align: right;
}
.my-basket-control input,
.my-basket-control textarea {
    border: 1px solid gray;
}
.my-basket-control .form-group:last-child {
    margin-bottom: 0;
}
.my-basket-control-buttons {
    display: flex;
    margin-right: -10px;
}
.my-basket-control-buttons > * {
    flex-grow: 1;
    margin-right: 10px;
}
</style>
