<template>
  <div class="week-select-container">
    <label for="select-target-week">
      <l10n value="Select week number" />
    </label>
    <select
      id="select-target-week"
      v-model="weekNumber"
      class="form-control form-control-sm dropdown-select week-select"
      @change="onSelectWeek"
    >
      <option
        value=""
        disabled
        selected
      >
        {{ placeholder10n }}
      </option>
      <option
        v-for="item in optionItems"
        v-bind:key="item"
        :value=item
      >
        <l10n :value=item />
      </option>
    </select>
  </div>
</template>

<script>
const graphQl = require('../api/graphQl');
const utils = require('../my-utils');

module.exports = {
  props: {
    username: {
      type: String,
      required: true,
      default: '',
    },
    streamName: {
      type: String,
      required: true,
      default: 'current_target_week',
    },
  },

  data() {
    return {
      weekNumber: '',
      optionItems: Array.from({ length: 53 }, (_, i) => String(i + 1)),
      placeholder10n: utils.l10n('Select week number'),
    };
  },

  mounted() {
    this.getSelectedWeekNumber();
  },

  methods: {
    getSelectedWeekNumber() {
      graphQl.makeGQRequest(`
          query {
            dataset {
              source(name: "${this.streamName}_last") {
                report(
                  cores: 64,
                  name: "gp-edit-keys",
                  dims: "target_week",
                  sort: [])
                {
                  rows
                }
              }
            }
          }`, '/graphql?__getSelectedTargetWeekNumber__').then((result) => {
        const { data: { dataset: { source: { report: { rows } } } } } = result;
        const data = rows[0][0];
        this.weekNumber = data;
      }).catch(() => {
        this.createNotification('Error while fetching selected week number', 'error');
      });
    },

    onSelectWeek(e) {
      const selectedWeekNumber = e.target.value;
      const stream = this.streamName;
      const createUser = this.username;
      const createTime = Date.now();
      const record = [];

      record.push(createUser);
      record.push(createTime);
      record.push(selectedWeekNumber);

      let records = [];
      records.push(record);
      records = JSON.stringify(records);

      graphQl.makeGQRequest(`
        mutation {
          appendRecords(
            stream: ${utils.quote(stream)},
            format: "json",
            records: ${utils.quote(records)})
        }`, '/graphql?__changeTargetWeek__').then((result) => {
        if (result.data) {
          this.createNotification('Week number has been successfully changed', 'success');
        } else if (result.errors) {
          result.errors.forEach((error) => {
            this.createNotification(error.message, 'error');
          });
        }
      }).catch(() => {
        this.createNotification('Error while setting week number', 'error');
      });
    },
  },
};
</script>

  <style scoped>
  .week-select-container {
    margin-bottom: 10px;
  }
  .week-select-container label {
    font-weight: bold;
  }
  </style>
