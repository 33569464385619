import { render, staticRenderFns } from "./trash-icon.vue?vue&type=template&id=2d0734e7&functional=true&"
var script = {}


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("C:\\Users\\aazat\\OneDrive\\Documents\\Repositories\\Goalprofit\\gloriajeans_frontend\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2d0734e7')) {
      api.createRecord('2d0734e7', component.options)
    } else {
      api.rerender('2d0734e7', component.options)
    }
    module.hot.accept("./trash-icon.vue?vue&type=template&id=2d0734e7&functional=true&", function () {
      api.rerender('2d0734e7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "public/autoorder/assets/icons/trash-icon.vue"
export default component.exports