const REPORT_STATUS = {
  CREATED: 'Создана',
  PROGRESS: 'В работе',
  DONE: 'Готово',
  ERROR: 'Ошибка',
};

module.exports = {
  REPORT_STATUS,
};
