/* eslint-disable no-eval, no-empty */

const component = require('../../input/number.vue').default;

module.exports = ({
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  initial = null,
  maximumFractionDigits = 1,
  locale = 'en-US',
} = {}) => {
  const valueToInput = (x) => {
    if (x <= min || x >= max) {
      return '';
    }
    return Math.round(x * 1000) / 10;
  };

  const inputToValue = (x) => {
    if (Number.isNaN(parseFloat(x))) {
      try {
        return eval(initial);
      } catch (e) {}
      return initial;
    }
    return (x / 100);
  };

  const display = (x) => {
    if (x <= min) {
      return '-∞';
    }
    if (x >= max) {
      return '+∞';
    }

    return Number(valueToInput(x) / 100).toLocaleString(locale, {
      style: 'percent',
      maximumFractionDigits,
    });
  };

  return {
    component,
    valueToInput,
    inputToValue,
    display,
    min,
    max,
  };
};
