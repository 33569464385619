<template>
  <div class="dataset-settings">
    <div class="dataset-settings__radiobuttons-container">
      <div v-for="chartType in chartTypes" :key="chartType.value">
        <label
          :for="`${chartType.value}-${currentDataset.label}`"
          class="dataset-settings__radiobutton"
        >
          <ao-radio
            :checked="currentDataset.type === chartType.value"
            :name="currentDataset.label"
            :id="`${chartType.value}-${currentDataset.label}`"
            :value="chartType.value"
            v-model="currentDataset.type"
            @onUpdate="chartTypeUpdate"
          />
          {{ chartType.title }}
        </label>
      </div>
    </div>

    <my-transition-expand>
      <div v-if="currentDataset.type === 'line'">
        <div class="dataset-settings__inputs-container">
          <div class="inputblock">
            <span class="inputblock__label">
              <l10n value="Тип точки" />
            </span>
            <my-multiselect
              :selectedOption="pointStyles
                .find((style) => style.value === currentDataset.pointStyle).title"
              :options="pointStyles.map(item => item.title)"
              label="pointType"
              placeholder="Выберите тип точки"
              @select="pointStyleSelect"
            />
          </div>
          <div class="inputblock">
            <label class="inputblock__label" for="pointRadius">
              <l10n value="Радиус точки" />
            </label>
            <ao-input
              id="pointRadius"
              v-model="currentDataset.pointRadius"
              type="number"
              min="0"
              placeholder="Введите радиус точки"
              onlyInteger
              :invalid="false"
              @onInput="pointRadiusUpdate"
            />
          </div>
          <label :for="`${currentDataset.label}-lineTension`" class="dataset-settings__rangecontainer">
            <l10n value="Степень изгиба кривой (интерполяция)" />
            <input
              type="range"
              :id="`${currentDataset.label}-lineTension`"
              :name="`${currentDataset.label}-lineTension`"
              min="0"
              max="1"
              step="0.01"
              :value="currentDataset.lineTension"
              @input="selectLineTension"
            />
          </label>
          <label :for="`${currentDataset.label}-pointHitRadius`" class="dataset-settings__rangecontainer">
            <l10n value="Радиус захвата точки курсором" />
            <input
              type="range"
              :id="`${currentDataset.label}-pointHitRadius`"
              :name="`${currentDataset.label}-pointHitRadius`"
              min="0"
              max="20"
              step="1"
              :value="currentDataset.pointHitRadius"
              @input="selectPointHitRadius"
            />
          </label>
        </div>
        <div class="dataset-settings__checkboxes-container">
          <div class="dataset-settings__checkbox-wrapper">
            <label
              :for="`${currentDataset.label}-steppedLine`"
              class="dataset-settings__checkbox"
            >
              <ao-checkbox
                :id="`${currentDataset.label}-steppedLine`"
                :checked="currentDataset.steppedLine"
                @onChange="changeSteppedLine"
              />
              <l10n value="Ступенчатый график" />
            </label>
          </div>
          <div class="dataset-settings__checkbox-wrapper">
            <label
              :for="`${currentDataset.label}-spanGaps`"
              class="dataset-settings__checkbox"
            >
              <ao-checkbox
                :id="`${currentDataset.label}-spanGaps`"
                :checked="!currentDataset.spanGaps"
                @onChange="changeSpanGaps"
              />
              <l10n value="Разрывы в графике с неполными данными" />
            </label>
          </div>
          <div>
            <label
              :for="`${currentDataset.label}-showLine`"
              class="dataset-settings__checkbox"
            >
              <ao-checkbox
                :id="`${currentDataset.label}-showLine`"
                :checked="currentDataset.showLine"
                @onChange="changeShowLine"
              />
              <l10n value="Показывать линию" />
            </label>
          </div>
        </div>
      </div>
    </my-transition-expand>

    <div class="dataset-settings__inputs-container">
      <div class="inputblock">
        <label class="inputblock__label" for="borderWidth">
          <l10n value="Ширина линии" />
        </label>
        <ao-input
          id="borderWidth"
          v-model="currentDataset.borderWidth"
          type="number"
          min="0"
          placeholder="Введите ширину линии"
          onlyInteger
          :invalid="false"
          @onInput="borderWidthUpdate"
        />
      </div>
      <div class="inputblock">
        <label class="inputblock__label" for="order">
          <l10n value="Порядок видимости" />
        </label>
        <ao-input
          id="order"
          v-model="currentDataset.order"
          type="number"
          min="0"
          placeholder="Введите порядок отображения"
          onlyInteger
          :invalid="false"
          @onInput="orderUpdate"
        />
      </div>
      <label :for="`${currentDataset.label}-background-color`" class="dataset-settings__colorinput">
        <input
          type="color"
          :id="`${currentDataset.label}-background-color`"
          :name="`${currentDataset.label}-colorpicker`"
          :value="currentDataset.backgroundColor"
          @input="selectBackgroundColor"
        />
        <l10n value="Цвет фона" />
      </label>
      <label :for="`${currentDataset.label}-border-color`" class="dataset-settings__colorinput">
        <input
          type="color"
          :id="`${currentDataset.label}-border-color`"
          :name="`${currentDataset.label}-colorpicker`"
          :value="currentDataset.borderColor"
          @input="selectBorderColor"
        />
        <l10n value="Цвет границы" />
      </label>
    </div>

    <div style="display: flex; flex-wrap: wrap;">
      <div style="padding: 5px;">
        <label
          v-if="currentDataset.type === 'line'"
          :for="`${currentDataset.label}-fill`"
          class="dataset-settings__checkbox"
        >
          <ao-checkbox
            :id="`${currentDataset.label}-fill`"
            :checked="currentDataset.fill"
            @onChange="changeFill"
          />
          <l10n value="Заливка области под графиком" />
        </label>

        <div>
          <label
            :for="`${currentDataset.label}-YAxisAppearance`"
            class="dataset-settings__checkbox"
          >
            <ao-checkbox
              :id="`${currentDataset.label}-YAxisAppearance`"
              :checked="Boolean(currentDataset.yAxisID)"
              @onChange="changeYAxisAppearance"
            />
            <l10n value="Добавить отдельную шкалу" />
          </label>
        </div>
        <my-transition-expand>
          <div v-if="currentDataset.type === 'bar'">
            <label
              :for="`${currentDataset.label}-YAxisAppearancea`"
              class="dataset-settings__checkbox"
            >
              <ao-checkbox
                :id="`${currentDataset.label}-YAxisAppearancea`"
                :checked="isBarWidthUsed"
                @onChange="changeBarWidthAvailability"
              />
              <l10n value="Использовать толщину бара" />
            </label>
          </div>
        </my-transition-expand>
      </div>

      <my-transition-expand>
        <div v-if="currentDataset.type === 'line'" style="padding: 10px;" class="dataset-settings__inputs-container">
          <div>
            <label :for="`${currentDataset.label}-borderdash__line`" class="dataset-settings__rangecontainer">
              <l10n value="Линия" />
              <input
                type="range"
                :id="`${currentDataset.label}-borderdash__line`"
                :name="`${currentDataset.label}-borderdash__line`"
                min="0"
                max="20"
                :value="currentDataset.borderDash[0]"
                @input="selectBorderDashLine"
              />
            </label>
          </div>
          <div>
            <label :for="`${currentDataset.label}-borderdash__gap`" class="dataset-settings__rangecontainer">
              <l10n value="Разрыв" />
              <input
                type="range"
                :id="`${currentDataset.label}-borderdash__gap`"
                :name="`${currentDataset.label}-borderdash__gap`"
                min="0"
                max="20"
                :value="currentDataset.borderDash[1]"
                @input="selectBorderDashGap"
              />
            </label>
          </div>
        </div>
        <div v-else-if="currentDataset.type === 'bar'" style="padding: 10px;" class="dataset-settings__inputs-container">
          <label :for="`${currentDataset.label}-barPercentage`" class="dataset-settings__rangecontainer">
            <l10n value="Ширина бара" />
            <input
              type="range"
              :id="`${currentDataset.label}-barPercentage`"
              :name="`${currentDataset.label}-barPercentage`"
              min="0"
              max="1"
              step="0.1"
              :value="currentDataset.barPercentage"
              @input="selectBarPercentage"
            />
          </label>
          <label :for="`${currentDataset.label}-categoryPercentage`" class="dataset-settings__rangecontainer">
            <l10n value="Ширина категории" />
            <input
              type="range"
              :id="`${currentDataset.label}-categoryPercentage`"
              :name="`${currentDataset.label}-categoryPercentage`"
              min="0"
              max="1"
              step="0.1"
              :value="currentDataset.categoryPercentage"
              @input="selectCategoryPercentage"
            />
          </label>
        </div>
      </my-transition-expand>
      <div v-if="isBarWidthUsed && currentDataset.type === 'bar'" class="dataset-settings__inputs-container">
        <label :for="`${currentDataset.label}-barThickness`" class="dataset-settings__rangecontainer">
          <l10n value="Толщина бара" />
          <input
            type="range"
            :id="`${currentDataset.label}-barThickness`"
            :name="`${currentDataset.label}-barThickness`"
            min="0"
            max="1000"
            step="1"
            :value="currentDataset.barThickness"
            @input="selectBarThickness"
          />
        </label>
      </div>
    </div>
  </div>
</template>

<script>
const MyMultiselect = require('../../../my-multiselect.vue').default;
const AoRadio = require('../shared/ao-radio.vue').default;
const AoInput = require('../shared/ao-input.vue').default;
const AoCheckbox = require('../shared/ao-checkbox.vue').default;
const MyTransitionExpand = require('../../../my-transition-expand.vue').default;

module.exports = {
  components: {
    AoRadio,
    AoInput,
    MyMultiselect,
    AoCheckbox,
    MyTransitionExpand,
  },

  inject: {
    providedChartData: {
      default: {
        scales: {},
        onTitleChanged: () => {},
        onScalesChanged: () => {},
        onChartUpdate: () => {},
      },
    },
  },

  props: {
    index: {
      type: Number,
      required: true,
    },
    datasets: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      chartTypes: [{ title: 'Столбчатый', value: 'bar' }, { title: 'Линейный', value: 'line' }],
      pointStyles: [
        { title: 'Круг', value: 'circle' },
        { title: 'Крест', value: 'cross' },
        { title: 'Ромб', value: 'crossRot' },
        { title: 'Тире', value: 'dash' },
        { title: 'Прямоугольник', value: 'rect' },
        { title: 'Закругленный прямоугольник', value: 'rectRounded' },
        { title: 'Повернутый прямоугольник', value: 'rectRot' },
        { title: 'Звезда', value: 'star' },
        { title: 'Треугольник', value: 'triangle' },
      ],
      commonChartSettings: this.yAxes,
      lineChartSettings: ['pointStyle', 'radius', 'backgroundColor'],
      barChartPSettings: [],
      currentDataset: this.datasets[this.index],
      currentScales: this.providedChartData.scales,
      isBarWidthUsed: false,
    };
  },

  methods: {
    chartTypeUpdate() {
      this.providedChartData.onChartUpdate();
    },
    pointStyleSelect(value) {
      this.$nextTick(() => {
        this.currentDataset.pointStyle = this.pointStyles
          .find((style) => style.title === value).value;
        this.providedChartData.onChartUpdate();
      });
    },
    borderWidthUpdate() {
      this.$nextTick(() => {
        this.providedChartData.onChartUpdate();
      });
    },
    orderUpdate() {
      this.$nextTick(() => {
        this.providedChartData.onChartUpdate();
      });
    },
    pointRadiusUpdate() {
      this.$nextTick(() => {
        this.providedChartData.onChartUpdate();
      });
    },
    changeFill(checked) {
      this.currentDataset.fill = checked;
      this.providedChartData.onChartUpdate();
    },
    changeSteppedLine(checked) {
      this.currentDataset.steppedLine = checked;
      this.providedChartData.onChartUpdate();
    },
    changeSpanGaps(checked) {
      this.currentDataset.spanGaps = !checked;
      this.providedChartData.onChartUpdate();
    },
    changeShowLine(checked) {
      this.currentDataset.showLine = checked;
      this.providedChartData.onChartUpdate();
    },
    selectBackgroundColor(event) {
      this.currentDataset.backgroundColor = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectBorderColor(event) {
      this.currentDataset.borderColor = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectBorderDashLine(event) {
      this.currentDataset.borderDash[0] = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectLineTension(event) {
      this.currentDataset.lineTension = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectPointHitRadius(event) {
      this.currentDataset.pointHitRadius = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectBorderDashGap(event) {
      this.currentDataset.borderDash[1] = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectBarPercentage(event) {
      this.currentDataset.barPercentage = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectCategoryPercentage(event) {
      this.currentDataset.categoryPercentage = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    selectBarThickness(event) {
      this.currentDataset.barThickness = event.target.value;
      this.providedChartData.onChartUpdate();
    },
    changeYAxisAppearance(checked) {
      if (checked) {
        this.currentDataset.yAxisID = this.currentDataset.label;
        this.currentScales.yAxes = [...this.currentScales.yAxes,
          {
            id: this.currentDataset.label,
            type: 'linear',
          }];
      } else {
        this.currentDataset.yAxisID = null;
        this.currentScales.yAxes = this.currentScales.yAxes
          .filter((axis) => axis.id !== this.currentDataset.label);
      }

      this.providedChartData.onScalesChanged(this.currentScales);
    },
    changeBarWidthAvailability(checked) {
      if (checked) {
        this.isBarWidthUsed = true;
        this.currentDataset.barThickness = 50;
      } else {
        this.isBarWidthUsed = false;
        this.currentDataset.barThickness = null;
      }

      this.providedChartData.onChartUpdate();
    },
  },
};
</script>

<style scoped>
.dataset-settings {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px 10px #A0A0AA33;
}
.dataset-settings__checkbox {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.dataset-settings__radiobuttons-container {
  display: flex;
  gap: 16px;
  padding: 8px 0;
  margin: 0;
}
.dataset-settings__radiobutton {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.dataset-settings__checkboxes-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px;
}
.dataset-settings__checkbox-wrapper {
  margin-right: 24px;
}
.dataset-settings__colorinput {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  width: 223px;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.dataset-settings__borderdash {
  display: inline-flex;
  align-items: center;
  gap: 6px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  user-select: none;
}
.dataset-settings__rangecontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 223px;
  gap: 6px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  font-weight: 500;
  font-size: 14px;
  color: #9B9B9B;
  cursor: pointer;
  user-select: none;
}
.inputblock {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.inputblock__label {
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #9B9B9B;
  margin: 0;
}
.inputblock__input {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 14px 8px;
  line-height: 18px;
  font-size: 14px;
  border: none;
  border-radius: 8px;
  background: #f7f7f7;
  transition: all 0.15s ease-in-out;
}
.dataset-settings__inputs-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
  margin-bottom: 12px;
}
.multiselect {
  min-width: 223px;
  min-height: 46px;
}
.multiselect__tags {
  transition: all 0.15s ease-in-out;
}
input[type='color'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    cursor: pointer;
}
input[type='color']::-webkit-color-swatch {
    border-radius: 5px;
    border: none;
}
input[type='color']::-moz-color-swatch {
    border-radius: 5px;
    border: none;
}
.fade-enter-active,
.fade-leave-active {
    transition: opacity .5s
}
.fade-enter,
.fade-leave-to {
    opacity: 0
}
</style>
