const { DOMAIN } = require('./endpoint');

// storage keys
const storageKeys = {
  parentName: 'orders',
  orderTabs: 'order-tabs',
  ordersEntity: 'orders-cards',
  register: {
    visibleColumnsKey: 'order-register-visible-cols',
    columnsSizeKey: 'order-register-columns-size',
  },
};

// orders
const COMPONENT_NAMES = {
  register: 'OrdersRegister',
  order: 'OrderCard',
};
const registerTab = { id: 'order-register', title: 'Реестр заказов', componentName: COMPONENT_NAMES.register };

// register
const registerColumns = [
  {
    id: 'id',
    title: 'Информация по заказу (ID)',
    field: 'id',
    config: {
      filterType: 'numeric',
    },
  },
  {
    id: 'supplier_name',
    title: 'Отправитель',
    field: 'supplier_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_name',
    title: 'Получатель',
    field: 'receiver_name',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'created_at',
    title: 'Текущая дата',
    field: 'created_at',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_formation',
    title: 'Дата формирования',
    field: 'date_formation',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_send',
    title: 'Дата отправки',
    field: 'date_send',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_processing',
    title: 'Дата обработки',
    field: 'date_processing',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_shipping',
    title: 'Дата отгрузки',
    field: 'date_shipping',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'status_approval',
    title: 'Статус согласования',
    field: 'status_approval',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'status_send',
    title: 'Статус отправки',
    field: 'status_send',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'time_acceptance_application',
    title: 'Время приема заявок',
    field: 'time_acceptance_application',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'limit_orders_chain_supplier',
    title: 'Период действия цепочки',
    field: 'limit_orders_chain_supplier',
    config: {
      sorting: true,
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'chain_type',
    title: 'Вид цепочки',
    field: 'chain_type',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'supplier_stock',
    title: 'Склады отгрузки',
    field: 'supplier_stock',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'min_borders',
    title: 'Минимальные границы',
    field: 'min_borders',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'max_borders',
    title: 'Максимальные границы',
    field: 'max_borders',
    config: {
      sorting: true,
      filterType: 'numeric',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'receiver_stock',
    title: 'Склад получателя',
    field: 'receiver_stock',
    config: {
      sorting: true,
      filterType: 'text',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'chain_created',
    title: 'Дата создания цепочки поставки',
    field: 'chain_created',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
  {
    id: 'date_delivery',
    title: 'Срок поставки',
    field: 'date_delivery',
    config: {
      sorting: true,
      filterType: 'dates',
      meta: {
        filterByUniqueValue: true,
      },
    },
  },
];

const registerTableProps = {
  columns: registerColumns,
  modelName: 'order',
  endpoint: `${DOMAIN}/orders/filtered/`,
  // configEndpoint: `${DOMAIN}/orders/connections/filtered/config`,
};

// order card
const ORDER_DATA_DEFAULT = {
  orderInfo: {
    id: null,
    supplier: null,
    receiver: null,
    currentDate: null,
    dateFormation: null,
    dateSend: null,
    dateProcessing: null,
    dateShipping: null,
    dateArrival: null,
    statusApproval: null,
    statusSend: null,
  },
  orderRestriction: {
    activePeriod: null,
    chainType: null,
    supplierStock: null,
    applicationAcceptanceTime: null,
    minBorder: null,
    maxBorder: null,
    receiverStock: null,
    dateDelivery: null,
    chainCreatedDate: null,
  },
};

// endpoints
const ENDPOINTS = {
  ORDERS: `${DOMAIN}/orders/`,
};

module.exports = {
  COMPONENT_NAMES,
  ENDPOINTS,
  ORDER_DATA_DEFAULT,
  registerTab,
  storageKeys,
  registerTableProps,
};
