const mathsFilterRules = [
  { id: 'gt', name: 'Больше чем' },
  { id: 'gtoe', name: 'Больше или равен' },
  { id: 'lt', name: 'Меньше чем' },
  { id: 'ltoe', name: 'Меньше или равен' },
  { id: 'equal', name: 'Равен' },
];

const textFilterRules = [
  { id: 'is_null', name: 'Пустой' },
  { id: 'is_not_null', name: 'Не пустой' },
  { id: 'text_contains', name: 'Текст содержит' },
  { id: 'text_not_contains', name: 'Текст не содержит' },
  { id: 'text_exact', name: 'Текст точно' },
];

module.exports = {
  mathsFilterRules,
  textFilterRules,
};
