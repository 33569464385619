<template>
  <div class="my-popup">
    <span class="my-popup-anchor" ref="anchor">
      <slot name="anchor"/>
    </span>
    <portal :to="portal" v-if="portal">
      <div :key="popupKey" class="my-popup-popup" :class="className" ref="popup">
        <slot/>
      </div>
    </portal>
    <div :key="popupKey" class="my-popup-popup" :class="className" ref="popup" v-else>
      <slot/>
    </div>
  </div>
</template>
<script>
let utils = require('./my-utils');
module.exports = {
  props: {
    className: { type: String },
    portal: { type: String, default: 'popup' },
    anchor: {
      type: [
        Function,
        String,
        Object,
        HTMLElement,
      ],
    },
    placement: { type: String, default: 'bottom' },
    draggable: {
      type: Boolean,
      required: false,
      default: false,
    },
    resizable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return { popupKey: utils.randomId() };
  },
  mounted() {
    window.popup = this;
    if (this.portal) {
      this.$nextTick(this.moveToAnchor);
    } else {
      this.moveToAnchor();
    }
    document.addEventListener('keydown', this.handleKeyDown);
    document.addEventListener('mousedown', this.handleMouseDown);
    if (this.draggable) {
      Vue.nextTick(() =>
        $(this.$refs.popup).draggable({
          create: ({ target }) => $(target).width($(target).width()),
        }));
    }

    if (this.resizable) {
      Vue.nextTick(() => $(this.$refs.popup).resizable({
        handles: 'e, w',
      }));
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKeyDown);
    document.removeEventListener('mousedown', this.handleMouseDown);
    if (this.draggable) {
      $(this.$refs.popup).draggable('destroy');
    }
    if (this.resizable) {
      $(this.$refs.popup).resizable('destroy');
    }
  },
  methods: {
    handleKeyDown(e) {
      if (e.key === 'Escape') {
        this.$emit('escape');
      }
    },
    handleMouseDown(e) {
      let node = e.target;
      let base = this.$refs.popup;
      while (node && node !== base) {
        node = node.parentNode;
      }
      if (!node) {
        this.$emit('clickoutside');
      }
    },
    moveToAnchor() {
      let anchor = this.anchor;
      if (_.isFunction(anchor)) {
        anchor = anchor();
      }
      if (!anchor) {
        anchor = this.$refs.anchor;
      }
      let {
        top: anchorTop,
        left: anchorLeft,
      } = _.isPlainObject(anchor) ? anchor : $(anchor).offset() || { top: 0, left: 0 };
      anchorTop -= $(window).scrollTop();
      anchorLeft -= $(window).scrollLeft();
      let anchorWidth = _.isPlainObject(anchor) ? anchor.width : $(anchor).outerWidth();
      let anchorHeight = _.isPlainObject(anchor) ? anchor.height : $(anchor).outerHeight();
      let popupWidth = $(this.$refs.popup).outerWidth();
      let popupHeight = $(this.$refs.popup).outerHeight();
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;

      let top = null;
      let left = null;
      let right = null;
      let bottom = null;

      let center = undefined;

      if (this.placement.endsWith('start')) {
        center = anchorLeft + popupWidth / 2;
      } else if (this.placement.endsWith('end')) {
        center = anchorLeft + anchorWidth - popupWidth / 2;
      } else {
        center = anchorLeft + anchorWidth / 2;
      }

      if (center < windowWidth / 2) {
        left = center - popupWidth / 2;
      } else {
        right = windowWidth - (center + popupWidth / 2);
      }

      if (this.placement.startsWith('bottom')) {
        center = anchorTop + anchorHeight + popupHeight / 2;
      } else if (this.placement.startsWith('top')) {
        center = anchorTop - popupHeight / 2;
      } else {
        center = anchorTop + anchorHeight / 2;
      }

      if (center < windowHeight / 2) {
        top = center - popupHeight / 2;
      } else {
        bottom = windowHeight - (center + popupHeight / 2);
      }

      if (this.placement.startsWith('insideTable')) {
        const modalHeight = this.$refs.popup.scrollHeight;

        if (center < windowHeight / 2) {
          top = center - popupHeight / 2;
        } else {
          bottom = windowHeight - center + modalHeight;
        }
      }

      let popup = this.$refs.popup;

      let style = {
        top: top != null ? `${Math.max(0, Math.round(top))}px` : 'revert',
        left: left != null ? `${Math.max(0, Math.round(left))}px` : 'revert',
        right: right != null ? `${Math.max(0, Math.round(right))}px` : 'revert',
        bottom: bottom != null ? `${Math.max(0, Math.round(bottom))}px` : 'revert',
        height: 'fit-content',
        minWidth: 'min-content',
        maxWidth: '476px',
        transform: 'none',
      };

      $(popup).css(style);
    },
  },
};
</script>
<style>
.my-popup {
    display: inline;
}
.my-popup-popup {
    position: fixed;
    z-index: 1000;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.my-popup-popup .popover {
    position: relative;
}
</style>
